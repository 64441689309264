import { initCell } from "modules/Core/core-modules/CoreModuleState/utils/cells";
import { CAMPAIGNS } from "../../consts";
import { call, put } from "redux-saga/effects";
import { permitProspectiveCampaign } from "../../middleware";
import { onSuccess } from "modules/Core/sub-modules/Dialog/state/cells";

const approvePerspectiveCampaign = initCell(CAMPAIGNS, {
  name: "approvePerspectiveCampaign",
  reducer: (state, payload) => {
    state.isLoading = true;
  },
  sagas: {
    latest: function* ({ payload }) {
      const res = yield call(
        permitProspectiveCampaign,
        payload?.id,
        payload?.isPermitted ?? true
      );
      const isSuccess = Boolean(res?.permitProspectiveCampaign?.id);
      if (!isSuccess) return false;
      yield put(approvePerspectiveCampaign.onSuccess(true));
      return res?.permitProspectiveCampaign;
    },
    onSuccess: function* (payload) {
      yield put(onSuccess("Prospective campaign approved successfully"));
      yield put({
        type: "prospectiveCampaigns/fetchFeed",
        payload: {
          renewStream: true,
        },
      });
    },
  },
  successCell: {
    reducer: (state, payload) => {
      state.isLoading = false;
    },
  },
});

export default approvePerspectiveCampaign;
