import { useMemo } from 'react';
import CampaignMaintainerForm from '../../CampaignMaintainerForm';
import CenteredCardScreen from 'modules/Core/components/ui-kit/Screen/CenteredCardScreen';
import strapped from 'modules/Core/higher-order-components/strapped';
import styles from "./styles.module.scss";

const MaintainerApplicationForm = ({ campaignId, back }) => (
    <CenteredCardScreen
        header={'Campaign Maintainer Application'}
        cardClassName={styles.container}
        cardHeader={'Application Form'}
        back={back}
    >
        <CampaignMaintainerForm className={styles.form} campaignId={campaignId} />
    </CenteredCardScreen>
)

export default strapped(MaintainerApplicationForm, ({ campaignId }) => ({
    back: useMemo(() => ({
        to: `/campaigns/${campaignId}/campsite`,
        text: 'Back to Campaign',
    }), [campaignId]),
}));