import Screen from "modules/Core/components/ui-kit/Screen";
import JudgementCreationForm from "../../JudgementCreationForm";

const JudgementCreationScreen = (props) => (
  <Screen>
    <JudgementCreationForm {...props} />
  </Screen>
);

export default JudgementCreationScreen;
