import { useEffect, useState } from "react";
import Screen from "modules/Core/components/ui-kit/Screen";
import DateComponent, { formatDateTime } from "modules/Core/components/ui-kit/Date";
import Title from "modules/Core/components/ui-kit/Title";
import Header from "modules/Core/components/ui-kit/layout/Header";
import Footer from "modules/Core/components/ui-kit/layout/Footer";
import Text from "modules/Core/components/ui-kit/Text";
import Container from "modules/Core/components/ui-kit/Container";
import styles from "./styles.module.scss";
import Feature from "modules/Core/components/ui-kit/Feature";

const ReceiptPage = (props) => {
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [currency, setCurrency] = useState("GBP");
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        props?.items && setItems(JSON.parse(props.items))
        props?.total && setTotal(props.total)
        props?.currency && setCurrency(props.currency)
        props?.time && setTime(new Date(props.time))
    }, [props?.items]);

    return (
        <Screen className={styles.container}>
            <Header className={styles.header} flex spaceBetween alignCenter>
                <Title>P4P Receipt</Title>
                <DateComponent date={time} formatter={formatDateTime} />
            </Header>
            <Container maxHeight flex col spaceBetween className={styles.content}>
                <Container maxWidth mt3>
                    <Container maxWidth flex >
                        <Feature flex alignCenter name='Donation Number:  ' content={<Text>{props?.transactionId}</Text>} />
                    </Container>
                    <Container maxWidth >
                        <Container className={styles.row} flex maxWidth row spaceBetween>
                            <Text fs150 bold>
                                Item Name
                            </Text>
                            <Container fs150 flex >
                                <Text bold>
                                    Quantity
                                </Text>
                                <Text fs150 bold>
                                    Price
                                </Text>
                            </Container>
                        </Container>
                        {
                            items?.map(({ itemName, quantity, amount }) => (
                                <Container className={styles.row} mt1 flex maxWidth row spaceBetween>
                                    <Text>
                                        {itemName}
                                    </Text>
                                    <Container flex >
                                        <Text>
                                            {quantity}
                                        </Text>
                                        <Text >
                                            {amount}
                                        </Text>
                                    </Container>

                                </Container>
                            ))
                        }
                    </Container>
                </Container>

                <Container mb1 flex maxWidth row spaceBetween>
                    <Text fs150>
                        Total:
                    </Text>
                    <Text>
                        {total} {currency}
                    </Text>
                </Container>
            </Container>
            <Footer className={styles.footer} flex spaceBetween alignCenter>
                <Text>Thank you for the support!</Text>
            </Footer>
        </Screen>
    )
}

export default ReceiptPage