import { PAYMENTS } from "modules/Payments/consts";
import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "modules/Payments/middleware";

const { fetchFeed, fetchPayment } = middleware.ops;

const module = new FeedModule({
    name: PAYMENTS,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchFeed
        },
        fetchEntityCell: {
            requestHandler: fetchPayment
        }
    }
});

export default module;