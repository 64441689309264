import Screen from "modules/Core/components/ui-kit/Screen";
import useFocusedId from "modules/Core/hooks/useFocusedId";
import Container from "modules/Core/components/ui-kit/Container";
import Drawer from "modules/Core/components/ui-kit/Drawer";
import { useIsOpen } from "modules/Core/hooks/useIsOpen";
import Button from "modules/Core/components/ui-kit/Button";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import BusinessPartnershipForm from "../../BusinessPartnershipForm";
import BusinessPartnershipTiers from "./BusinessPartnershipTiers";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import authorizeToken from "modules/Auth/state/cells/authorizeToken";
import PartnerIdentificationCard from "modules/Partners/components/PartnerIdentificationCard";
import Text from "modules/Core/components/ui-kit/Text";
import RedirectButton from "modules/Core/components/ui-kit/RedirectButton";
import OnLoad from "modules/Auth/components/OnLoad";
import styles from "./styles.module.scss";

const B = withShouldRender(Button)

const BusinessPartnershipsScreen = ({
    focusedTier, setFocusedTier, isOpen, open, close, partner, campaignId }) => {
    return (
        <>
        <OnLoad />
            <Screen
                className={styles.screen}
                header={"Become a business partner"}
                footerProps={{ className: styles.footer }}
                footer={<>
                    <Container flex row maxWidth flexEnd alignCenter>
                        {partner?.name ? <PartnerIdentificationCard
                        className={styles.id}
                            name={partner?.name}
                            image={partner?.info?.image}
                        /> : <RedirectButton text={"Register Business"} to="/invitation/business-partnership" />}

                    </Container>
                </>}
            >
                <Container row center alignCenter fullWidth p3>
                    <Text fs150>
                        P4P is reinvigorating the third sector, and you are a key part of our vision.
                        Ethically and sustainably support causes which your business cares about,
                        whilst helping you reach a whole new community,
                        you never knew were so loyal.
                    </Text>
                </Container>
                <BusinessPartnershipTiers
                    p3
                    className={styles.cards}
                    setFocusedTier={setFocusedTier}
                    focusedTier={focusedTier}
                />
                <Container flex row center>
                    <B shouldRender={Boolean(focusedTier)} onClick={open}>Partner Up</B>
                </Container>
                <Container row center alignCenter fullWidth p3>
                    <Text>
                        We believe that war, conflict and poor socio-moral standards are bad for the economy,
                        help us help you,
                        your business and the well being of our shared world.
                    </Text>
                </Container>
            </Screen>
            <Drawer
                isOpen={isOpen}
                close={close}
                origin="right"
                header={"Register Partnership"}
            >
                <BusinessPartnershipForm 
                    tier={focusedTier} 
                    campaignId={campaignId} 
                />
            </Drawer>
        </>
    )
};

export default strappedConnected(
    BusinessPartnershipsScreen,
    {
        partner: authorizeToken.selectors.getPartner
    },
    {
        onLoad: authorizeToken.actions
    },
    ({partner}) => {
        const { focusedId, setFocusedId } = useFocusedId();
        const { isOpen, open, close } = useIsOpen();

        return {
            focusedTier: focusedId,
            setFocusedTier: setFocusedId,
            isOpen,
            open,
            close,
        }
    }
);