import Container from "modules/Core/components/ui-kit/Container"
import Text from "modules/Core/components/ui-kit/Text";
import { PARTNER_KINDS } from "modules/Partners/consts";
import RedirectButton from "modules/Core/components/ui-kit/RedirectButton";
import List from "modules/Core/components/ui-kit/List";
import InvitationCard from "../../InvitationCard";
import microcopy from "./microcopy.js"
import styles from "./styles.module.scss";


const PartnerInvitation = ({ variables = {} }) => (
    <InvitationCard
        microcopy={{
            title: microcopy?.title,
            to: "To: " + (variables?.partner?.name ?? "Our Potential Partner"),
            // kindRegards: "The P4P Team",
            personalisedMessage: variables.message
        }}
        contentClassName={styles.content}
        prePersonalisedMessage={
            (
                <Container ml1 >
                    <Text fs150>
                        {/* You are invited to join the P4P platform where multiple available services have been built to enhance your mission, and to facilitate the coordination of a network of organisational entities poised for meaningful global change, creating a coalition for socio-moral progress and the pursuit of peace. */}
                        You've been invited to join a cause dedicated towards change, a society based on peace and equity.
                        We provide you with a plethora of free digital tools to help you on your mission,
                        as we value shared values, passion and pursuit for social change.
                    </Text>
                </Container>
            )
        }
        content={
            <>
                <Container ml1 alignEnd>
                    <Text fs150 flex alignCenter>
                        Here are
                        <Text pre m1 fs150 italic bold> abc's </Text>
                        of why you should
                        <RedirectButton
                            nature={'none'}
                            to={`/partner-onboarding/${PARTNER_KINDS.PEER.toLowerCase()}`}
                        >
                            <Text pre fs150 bold italic> join a community for change! </Text>
                        </RedirectButton>

                    </Text>
                    <Container flex>
                        <List
                            className={styles.abc}
                            items={microcopy.terms}
                            fs120
                        />
                    </Container>
                </Container>

                < Text fs150 mb1 >
                    We're not alone in this fight, join us and our allies, because we're stronger together.
                </Text >
                {/* <Text fs150 mb1>
                    Please review our terms and conditions and then sign up to join the community.
                </Text> */}
                {/* <Text fs150 mb1>
                    We're excited to have you join us!
                </Text> */}
                <Text fs150 mb1>
                    The P4P Community
                </Text>
            </>
        }
        footer={
            <RedirectButton
                to={`/partner-onboarding/${PARTNER_KINDS.PEER.toLowerCase()}`}
                text={"Register Here"}
                italic
            />
        }
    />
);


export default PartnerInvitation;