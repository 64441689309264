export default {
    fields: {
        name: {
            type: "string",
            required: true,
            disabled: true,
            label: "Name"
        },
        email: {
            type: "string",
            required: true,
            label: "Email"
        },
    }
}; 