import { gql } from "graphql-request";
import { graphqlClient } from "modules/Core/middleware";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";

const query = gql`
	query posts($campaignId: String, $policyId: String, $partnerId: String,  $feedParams: FeedParams) {
		feed: posts (campaignId: $campaignId, policyId: $policyId, feedParams: $feedParams, partnerId: $partnerId) {
			entities {
				id
				name
				text
				attachments {
					url
					type
				}
				userInformation {
					photo
					lastName
					firstName
					bio
				}
				partner {
					id 
					name
					info {
						image
					}
				}
				createdAt
			}
			${FEED_INFO_FRAGMENT}
		}
	}
`;

export const fetchPosts = async (params) => {
  return graphqlClient.request(query, params);
};
