export default {
  "title": "P4P Partnership Invitation 👋",
  "description": "We're excited to have you join us.",
  "terms": [
    "Expand your digital identity.",
    "Utilise digital infrastructure to help your pursuit for change",
    "Strengthen the connection with your community and reach new people.",
    "Enhance your financial sustainability."
    // "Broaded your digital network",
    // "Extend and further your mission, your goal, your identity.",
    // "Collaborate rather than compete with peer organisations to increase your capability and reach on commomn global issues.",
    // "Diversify your avenues for financial sustainability through direct donations, an campaign partnership-funding",
    // "Community - Strengthen the connection you've got with your existing communty and broaden your access to new members.",
    // "Don't want to comete with you, but to co-exist with you.",
    // "Each organisation is unique, dear and different , let's celebrate the diversity here, and provide you ewith a unique space to express yourselves how you are.",
    // "It'd be niaive to think we can do this alone, and so we're here to collaborate with you.",
    // "We don't charge you a dime, and aren't looking to.",
    // "You're done some great work! We want to show it off."
  ],
  "link": "r.s.v.p",
}
