import { useSelector } from "react-redux";
import ViewPost from "../ViewPost";
import useDispatchWithLoader from "modules/Core/hooks/useDispatchWithLoader";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import { fetchEntity } from "modules/Posts/state/cells";
import { useEffect } from "react";

const ViewPostPanel = ({ postId, headerChildren, className, onClose, ...props }) => {
    const [action, loading] = useDispatchWithLoader(fetchEntity.action)
    const post = useSelector(fetchEntity.selector(postId))
    useOnLoad(() => action({ id: postId }), Boolean(postId), [postId]);
    useEffect(() => {
        if (!post && !loading) onClose?.()
    }, [post?.id])
    return <ViewPost loading={loading} className={className} post={post} headerChildren={headerChildren} {...props} />
}

export default ViewPostPanel;