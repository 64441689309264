import { fetchEntity } from "modules/Campaigns/state/cells";
import RedirectButton from "modules/Core/components/ui-kit/RedirectButton";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";

export default strappedConnected(
    RedirectButton,
    {financialsAccount:(state, {campaignId}) => fetchEntity.selectField(campaignId, 'financialsAccount')(state)},
    {},
    ({campaignId, financialsAccount}) => ({
        to: `/campaigns/${campaignId}/register-financials`,
        text: 'Register Financials Account',
        shouldRender: !financialsAccount?.stamps?.approved && !financialsAccount?.stamps?.verified
    })
)