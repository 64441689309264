import Button from "modules/Core/components/ui-kit/Button"
import strappedConnected from "modules/Core/higher-order-components/strappedConnected"
import stampApplicationCell from "../../state/stampApplication"

export default strappedConnected(
    Button,
    null, 
    {
        onClick: (stamp, id) => stampApplicationCell.action({...stamp, id})
    },
    ({ onClick, stamp, applicationId }) => ({
        onClick: () => onClick(stamp, applicationId)
    })
)