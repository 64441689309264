import FeedModule from "modules/Core/core-modules/FeedModule";
import { POSTS } from "modules/Posts/consts";
import { createPost } from "modules/Posts/middleware/createPost";
import { fetchPost } from "modules/Posts/middleware/entity";
import { fetchPosts } from "modules/Posts/middleware/feed";

export default new FeedModule({ 
    name: POSTS,
    cellOptions: {
        createEntityCell: {
            requestHandler: createPost
        },
        fetchFeedCell: {
            requestHandler: fetchPosts
        }, 
        fetchEntityCell: {
            requestHandler: fetchPost
        }
    }
});