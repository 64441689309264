import cx from "classnames"
import { useIsAuthenticatedMemo } from "modules/Auth/hooks/useConnectedDispatch";
import styles from "./styles.module.scss";
import Container from "modules/Core/components/ui-kit/Container";
import Link from "modules/Core/components/ui-kit/Link";

const DisconnectedLinks = () => (
    <>
        <Link to={"/register"} text={"Register"} />
        <Link to={"/login"} text={"Login"} />
    </>
);

const ConnectedLinks = () => (
    <Link to={"/feed"} text={"View Campaigns"} />
)

const AuthLinks = ({
    Connected = ConnectedLinks,
    Disconnected = DisconnectedLinks,
    className
}) => {
    const Component = useIsAuthenticatedMemo(Connected, Disconnected);
    return (
        <Container className={cx(styles.container, className)} flex >
            <Component />
        </Container>
    )
};


export default AuthLinks;
