import { useMemo } from 'react';
import FeedItem from 'modules/Core/components/Feed/FeedItem';
import Container from 'modules/Core/components/ui-kit/Container';
import strapped from 'modules/Core/higher-order-components/strapped';
import feed from 'modules/Payments/state/feed';
import Text from 'modules/Core/components/ui-kit/Text';
import _Date, { formatDateTime } from 'modules/Core/components/ui-kit/Date';
import styles from './styles.module.scss';

const { FeedComponent } = feed;
const _FeedItem = ({ price, post, stamps, ...props }) => {
    const created = useMemo(() => stamps?.created?.[0]?.time, [stamps]);
    return (
        <FeedItem className={styles.feedItem} name={`£${price} Donated!`}>
            <Container className={styles.contentWrapper}>
                <Container maxWidth shouldRender={Boolean(post?.text)}>
                    <Text>{post?.text}</Text>
                </Container>
                <Container>
                    {/* TODO: Mend this one */}                    
                    <_Date date={created} formatter={formatDateTime} />
                </Container>
            </Container>
        </FeedItem>
    )
}

export default strapped(
    FeedComponent,
    ({ isPlatformDonation, campaignId, partnerId, stamps }) => ({
        filters: useMemo(() => ({ isPlatformDonation, campaignId, partnerId }), [isPlatformDonation, campaignId, partnerId]),
        ItemComponent: _FeedItem,

    })
)