import feed from "../feed";
import fetchActiveObjectivesFeed from "./fetchActiveObjectivesFeed";
import stampObjectives from "./stampObjective";

export const {
    createEntity,
    fetchFeed,
    fetchEntity,
    setFilters,
    setNestedFilters: setActiveObjectivesFilters
} = feed.cells;

export default {
    createEntity,
    fetchFeed,
    fetchEntity,
    setFilters,
    [fetchActiveObjectivesFeed.name]: fetchActiveObjectivesFeed,
    stampObjectives,
    [setActiveObjectivesFilters?.name]: setActiveObjectivesFilters,
}