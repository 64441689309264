import { useMemo } from "react";
import feed from "modules/Posts/sub-modules/Comments/state/feed";
import Container from "modules/Core/components/ui-kit/Container";
import CommentEntity from "../CommentEntity";
import styles from "./styles.module.scss";

const CommentsFeed = ({postId, actionId, ...props}) => {
    const filters = useMemo(() => ({postId, actionId}), [postId, actionId])
    return (postId || actionId) && (
        <Container className={styles.container} {...props}>
            <feed.FeedComponent
                ItemComponent={CommentEntity}
                filters={filters}
            />
        </Container>
    )
}

export default CommentsFeed;