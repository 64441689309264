import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import FundraisingScreen from "modules/Payments/components/FundraisingScreen"

const params = {
    title: "Support P4P",
    visionStatement: "Help support our vision so that we can launch in the forthcoming year, by helping cover a portion of our running costs!",
    target: 500,
    background:
`Whilst we’ve also been exploring grants and foundations in order to attain larger funds that allows more sustainable progress. We’ve also built into the platform avenues for increasing our capacity for earned income to support us post-launch, and have further plans to add to these methods of earned income!

In the hope to continue developing P4P we are seeking some funds to cover a portion of the running costs for the upcoming year, costs include server and software fees, external professional services (e.g. designer) and a small contingency fee for unforeseen expenses. These donations will help us continue working on the project on at least a voluntary basis.

Thus far we’ve been entirely self-funded and been progressing due to a group of passionate volunteers. There’s been quite a lot of time and care put into the project, this is because of having a long-term vision which involves making a real-impact.

Included in this vision is providing a space for the community, with tools for individuals, organisations and initiatives to help with the shared agenda of fighting social injustice. 

Over the next year we are planning to begin hosting live campaigns in our production environment, where communities can co-organise and coordinate in tandem with partner organisations through our platform, establishing a global community of users, partners and members in the process.

Making our vision a reality is likely to take time, and we can’t do it alone.

Those interested in dedicating a donation to support our cause, large or small, are greatly appreciated. We can’t do this alone and we’re glad to have your support.

If you’re not ready to make a financial donation but are interested in being involved in another way please reach out.

Thank you!


`,
}
const feedParams = {
    isPlatformDonation: true
}

export default strappedConnected(
    FundraisingScreen,
    {},
    {},
    ({ amountRaised = 20 }) => ({
        amountRaised,
        background: params.background,
        details: [{ name: 'Background', content: params.background }],
        target: params.target,
        title: params.title,
        header: "Donate to P4P",
        visionStatement: params.visionStatement,
        feedParams,
    })
);  