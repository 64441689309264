import Screen from "modules/Core/components/ui-kit/Screen";
import Invitation from "../../Invitation";
import PartnerInvitation from "../../invitations/PartnerInvitation";
import Container from "modules/Core/components/ui-kit/Container";

const PartnerOnboardingInvitation = ({id}) => (
    <Screen center  header={'Join P4P'}>
        <Container span flex center absolute>
        <Invitation 
            id={id} 
            TemplateComponent={PartnerInvitation} 
        />
        </Container>
    </Screen>
);


export default PartnerOnboardingInvitation;