import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useOnLoad } from 'modules/Core/hooks/useOnLoad';
import { fetchEntity } from 'modules/Campaigns/state/cells';
import _CreateCampaignButton from '../../CreateCampaignButton';
import DonationsWidget from 'modules/Payments/components/DonationsWidget';
import CampaignMembershipWidget from '../../widgets/CampaignMembershipWidget';
import CampaignObjectivesPanel from '../../CampaignObjectivesPanel';
import CampaignScreen from '../../CampaignScreen';
import CreateCampaignButton from '../../CreateCampaignButton';
import MaintainerApplicationButton from '../../widgets/MaintainerApplicationButton';
import CampaignActionsTab from './CampaignActionsTab';
import { CAMPAIGN_ROLES } from 'modules/Campaigns/consts';
import CampaignFoundingPartnerWidget from '../../widgets/CampaignFoundingPartnerWidget';
import CampaignMaintainersPanel from './CampaignMaintainersPanel';
import CampaignDonationsWidget from '../../widgets/CampaignDonationsWidget';

const leftWidgets = [
    {
        Component: CampaignFoundingPartnerWidget,
        gridRow: '2',
    },
    {
        Component: CampaignMembershipWidget,
        gridRow: 7
    }
];

const rightWidgets = [
    {
        Component: MaintainerApplicationButton,
        gridRow: '2',
    },
    {
        Component: CampaignDonationsWidget,
        gridRow: '3/4',
    },
    {
        Component: ({ campaignId, ...props }) => (
            <CreateCampaignButton
                {...props}
                panel
                widget
                text={"Create subcampaign"}
                parentId={campaignId}
            />
        ),
        gridRow: '8',
    },
]

const CampaignCampsite = () => {
    let { id } = useParams();
    const dispatch = useDispatch();
    useOnLoad(() => dispatch(fetchEntity.action({ id })), Boolean(id), [id]);
    const widgetProps = useMemo(() => ({ campaignId: id }), [id]);

    const back = useMemo(() => ({
        text: "To Campaign Gateway",
        to: `/campaigns/${id}/gateway`
    }));

    return (
        <CampaignScreen
            header={"Campaign Campsite"}
            tabs={tabs}
            back={back}
            forward={forward}
            id={id}
            leftWidgets={leftWidgets}
            rightWidgets={rightWidgets}
            widgetProps={widgetProps}
        />
    )
}

const tabs = [
    {
        title: 'Actions',
        Component: CampaignActionsTab
    },
    {
        title: 'Campaign Maintainers',
        Component: CampaignMaintainersPanel
    },
    {
        title: 'Roadmap',
        Component: CampaignObjectivesPanel
    }
];

const forward = {
    to: "./command-center",
    text: "To Command Center",
    requiredRoles: [CAMPAIGN_ROLES.MAINTAINER],
    restrictionMessage: "You must be a maintainer to access the campaign's command center."
};



export default CampaignCampsite