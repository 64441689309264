import { useMemo } from "react";
import CreateCampaign from "../CampaignFormScreen"
import { useParams } from "react-router-dom";
import CampaignName from "../../CampaignName";
import styles from "./styles.module.scss";
import Container from "modules/Core/components/ui-kit/Container";

const nameAdapter = (name) => name + ":";
const CreateSubcampaign = ({ }) => {
    let { id } = useParams();
    const title = useMemo(() => <Container display flex alignCenter><CampaignName id={id} className={styles.name} Wrapper='span' adapter={nameAdapter} />Create Sub-Campaign </Container>)
    const additionalInput = useMemo(() => ({ parentId: id }), [id]);
    const back = useMemo(() => ({to: `/campaigns/${id}/campsite`, text: "Back to Campaign"}), [id]);
    return (
        <CreateCampaign
            parentId={id}
            title={title}
            additionalInput={additionalInput}
            redirectRoute={`/campaigns/${id}`}
            header={title}
            back={back}
        />
    )
};

export default CreateSubcampaign;