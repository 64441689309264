import { useCallback } from "react";
import Container from "modules/Core/components/ui-kit/Container";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import StampObjectivesButton from "modules/Objectives/components/StampObjectivesButton";
import ObjectiveDrawer from "modules/Objectives/components/ViewObjectiveDrawer";
import { OBJECTIVE_STAMPS } from "modules/Objectives/consts";
import { fetchEntity } from "modules/Objectives/state/cells";
import fetchActiveObjectivesFeed from "modules/Objectives/state/cells/fetchActiveObjectivesFeed";

const ActiveObjectiveDrawer = ({ id, unFocus, isReadyToAchieve, onAchieved }) => {
    return (
        <ObjectiveDrawer
            objectiveId={id}
            unFocus={unFocus}
            footer={
                <Container maxWidth flex flexEnd>
                    <StampObjectivesButton
                        id={id}
                        stamp={OBJECTIVE_STAMPS.ACHIEVED}
                        text={"Stamp achieved!"}
                        shouldRender={Boolean(isReadyToAchieve)}
                        callback={onAchieved}
                    />
                </Container>
            }
        />
    )
}

export default strappedConnected(
    ActiveObjectiveDrawer,
    { isReadyToAchieve: (state, { id }) => fetchEntity.selectField(id, 'isReadyToAchieve')(state) },
    {
        refetchFeed: () => fetchActiveObjectivesFeed.action({ renewStream: true })
    },
    ({ refetchFeed, unFocus }) => ({
        onAchieved: useCallback(() => {
            refetchFeed()
            unFocus()
        }, [refetchFeed, unFocus]),
        unFocus
    })
);