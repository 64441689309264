import { useMemo, useState } from "react";
import cx from "classnames";
import Container from "modules/Core/components/ui-kit/Container";
import ConditionalContainer from "modules/Core/components/ui-kit/ConditionalContainer";
import Input from "modules/Core/components/ui-kit/Input";
import PartnerIdentificationCard from "modules/Partners/components/PartnerIdentificationCard";
import authorizeToken from "modules/Auth/state/cells/authorizeToken";
import Text from "modules/Core/components/ui-kit/Text";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import Link from "modules/Core/components/ui-kit/Link";
import { PARTNER_KINDS } from "modules/Partners/consts";
import PaymentCard from "../PaymentCard";
import styles from "./styles.module.scss";

const _DonationPanel = ({
     business,
    toggleAsBusiness, asBusiness,
    className,
    budgetId, 
    campaignId,
    partnerId,
    transactionKind,
    tithingOptions,
    businessId
}) => (
    <Container className={cx(styles.container, className)} flex relative>
        <PaymentCard 
            budgetId={budgetId}
            campaignId={campaignId}
            partnerId={partnerId}
            transactionKind={transactionKind}
            tithingOptions={tithingOptions}
            businessId={businessId}
        />
        <ConditionalContainer shouldRender={Boolean(business?.id && false)} flex col className={styles.partnerCard}>
            <PartnerIdentificationCard
                name={business?.name}
                image={business?.info?.image}
                className={styles.identificationCard}
            >
                <Container flex  >
                    <Text>
                        Donating as:
                    </Text>
                    <Input checkbox onChange={toggleAsBusiness} checked={asBusiness} />
                </Container>
            </PartnerIdentificationCard>
        </ConditionalContainer>
        <ConditionalContainer shouldRender={!Boolean(business?.id || true)} className={styles.businessDonationContent}>
            <Link
                to="/business-partnership-invitation"
                text="Would you like to donate from your business?"
            />
        </ConditionalContainer>
    </Container>
)

export default strappedConnected(
    _DonationPanel,
    { partner: authorizeToken.selectors.getPartner },
    {},
    (({ partner, focusedId, }) => {
        const [asBusiness, setAsBusiness] = useState(false);
        const business = useMemo(() => partner?.kind === PARTNER_KINDS.BUSINESS && partner, [partner]);
        
        return {
            business: false , //temp untill approving feature
            toggleAsBusiness: () => setAsBusiness(!asBusiness),
            budgetId: focusedId,
            businessId: asBusiness && business && business.id,
        }
    })
);
