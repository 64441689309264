import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { fetchEntity } from "modules/Judgements/state/cells";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import { withLoader } from "modules/Core/higher-order-components/withLoader";
import JudgementDetailsComponent from "./component";

const selectors = {
  judgement: (state, { id }) => fetchEntity.selector(id)(state),
};

const actions = {
  fetchEntity: fetchEntity.action,
};

export const useLogic = ({ judgement, fetchEntity, id }) => {
  useOnLoad(() => fetchEntity({ id }), true, [id]);
  return {
    name: judgement?.name,
    background: judgement?.background,
    closingTime: judgement?.closingTime,
    openingTime: judgement?.openingTime,
    policies: judgement?.policies,
  };
};

export default strappedConnected(
  withLoader(JudgementDetailsComponent, fetchEntity.selectors.getIsLoading),
  selectors,
  actions,
  useLogic
);
