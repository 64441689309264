export default {
  header: "Partner With P4P",
  fields: {
    name: {
      label: "Name",
      validations: {
        minLength: 30,
        required: true,
      },
      type: "text",
    },
    description: {
      type: "textarea",
      label: "Description",
      required: true,
    },
    policies: {
      label: "Prospective Policies",
      type: "array",
      required: true,
      fields: {
        name: {
          label: "Policy name",
          required: true,
          type: "text",
        },
        description: {
          label: "Policy description",
          type: "textarea",
          required: true,
        },
      },
    },
    openingTime: {
      type: "date",
      label: "Opening Time",
      minDate: new Date(),
      required: true,
    },
    closingTime: {
      type: "date",
      label: "Closing Time",
      minDate: new Date(),
      required: true,
    },
  },
  success: {
    text: "Judgement created to vote!",
  },
};
