import Form from "modules/Core/components/form/Form";
import schema from "./schema";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { createEntity } from "modules/Partners/state/cells";
import { PARTNER_KINDS } from "modules/Partners/consts";
import useValidatePropsOnLoad from "modules/Core/hooks/useValidatePropsOnLoad";

const parseSuccess = (result) => Boolean(result?.onboardPartner?.name)

export const parsePayload = (input) => ({
    kind: input?.kind?.toUpperCase(),
    name: input?.name,
    members: input?.members?.map(({ id, isOneself, ...member }) => ({
        ...member,
        userId: id
    })),
    info: {
        image: input?.image,
        website: input?.website,
        description: input?.description,
        contactInformation: input?.contactInformation,
        bio: input?.bio,
        links: input?.links,
        background: input?.background,
        mission: input?.mission,
        aims: input?.aims?.map((aim) => ({
            aim: aim?.name,
            description: aim?.description,
        })),
        tags: input?.tags?.map((tag) => tag?.key?.id),
        location: input?.location?.map(location => location?.key)
    },
});

export default strappedConnected(
    Form,
    {},
    {
        onSubmit: (input, callback) => createEntity.action({
            input, callback
        })
    },
    ({ onSubmit, ...props }) => {
        useValidatePropsOnLoad(props, (props) => !Object.values(PARTNER_KINDS).includes(props?.kind?.toUpperCase()), {
            error: { text: "Invalid partner kind", },
            redirect: "/",
            deps: [props.kind]
        })
        return {
            onSubmit: (input, callback) => onSubmit(parsePayload({
                ...input,
                kind: props?.kind?.toUpperCase()
            }), callback),
            parseIsSuccess: parseSuccess,
            schema,
            success: {
                ...schema.success,
                redirect: props?.kind?.toUpperCase() === PARTNER_KINDS.BUSINESS ? "/business-partnerships" : "/",
                redirectText: props?.kind?.toUpperCase() === PARTNER_KINDS.BUSINESS ? "Become a Business Partner" : "Home",
            },
        }
    }
);