import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import React, { useMemo } from "react";


const getStripePromise = () => loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)


const NotariesWrapper = ({ children, clientSecret }) => {
    const options = useMemo(() => clientSecret && ({ clientSecret }), [clientSecret]);

    const stripePromise = useMemo(() => window.navigator.onLine ? getStripePromise() : null, [window.navigator.onLine]);
    const Wrapper = useMemo(() => {
        if (Boolean(stripePromise)) return Elements;
        return React.Fragment;
    }, [window.navigator.onLine]);

    return (
        <Wrapper stripe={stripePromise} options={options} >
            {children}
        </Wrapper>
    )
}
export default NotariesWrapper;