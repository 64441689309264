import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import removePost from "modules/Posts/state/cells/removePost";
import withMaintainerOnly from "modules/Campaigns/higher-order-components/withMaintainerOnly";
import { useIsOpen } from "modules/Core/hooks/useIsOpen";
import DeleteAction from "./DeleteAction.component";

const StrappedComponent = strappedConnected(
    DeleteAction,
    null,
    { onSubmit: removePost.action },
    ({ postId, entityName = "post" }) => ({
        ...useIsOpen(),
        id: postId,
        entityName
    })
);

export default withMaintainerOnly(StrappedComponent);
