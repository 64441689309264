import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";
import { PEER_PARTNER_FINANCIALS } from "../consts";

const fetchFeed = `
	query transactedItems($partnerId: String, $feedParams: FeedParams) {
		feed: transactions(partnerId: $partnerId, feedParams: $feedParams) {
			entities {
				id
				price
				key
				name
			}
	  		${FEED_INFO_FRAGMENT}
		}
  	}
`;

const registerFinancials = `
	mutation registerPeerPartnerFinancialsAccount($partnerId: String,) {
		registerPeerPartnerFinancialsAccount(partnerId: $partnerId) {
			url
		}
	}
`


const fetchPeerFinancialsIntegration = `
	query peerFinancialsAccount($partnerId: String,) {
		peerFinancialsAccount(partnerId: $partnerId) {
			url
		}
	}
`

const syncVerification = `
	mutation syncVerification($partnerId: String) {
		syncPeerPartnerAccountVerification(partnerId: $partnerId)
	}
`;

export default new MiddlewareModule({
	name: PEER_PARTNER_FINANCIALS,
	operations: {
		fetchFeed,
		registerFinancials,
		fetchPeerFinancialsIntegration,
		syncVerification,
	},
})
