import { fetchEntity, uploadBudgetItemAttachment } from "../../state/cells"
import { useCallback, useState } from "react"
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import useOnSuccess from "modules/Core/sub-modules/Dialog/hooks/useOnSuccess";
import UploadFile from "modules/Core/components/ui-kit/Input/UploadFile";
import styles from "./styles.module.scss";

export default strappedConnected(
    UploadFile,
    {},
    {
        onUpload: (id, file, callback) => uploadBudgetItemAttachment.action({
            budgetItemId: id,
            input: {
                filetype: file.type,
                name: file.name,
                base64: file.base64
            },
            callback
        })
    },
    ({ id, onUpload, onSuccess, isOwner }) => {
        const [file, setFile] = useState(null);
        const setSuccessMessage = useOnSuccess()
        const callback = useCallback((result) => {
            setFile(null)
            if (result?.uploadBudgetItemAttachment?.name)  {
                setSuccessMessage("Attachment uploaded successfully")
                onSuccess()
            }
        }, [onSuccess, setFile]);
        return {
            onChange: (file, base64) => setFile({
                name: file?.name, base64, type: file?.type
            }),
            file,
            upload: () => onUpload(id, file, callback),
            className: styles.container
        }
    }
)
