import Feed from "modules/Judgements/components/JudgementsFeed";
import { useNavigate } from "react-router-dom";

const FeedPanel = ({}) => {
  const navigate = useNavigate();

  return (
    <Feed
      feedProps={{
        onClick: (j) => navigate(`/judgement-cases/${j.id}`),
      }}
    />
  );
};

export default FeedPanel;
