import { useMemo } from "react";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import DonationsWidget from "modules/Payments/components/DonationsWidget";
import { fetchEntity } from 'modules/Campaigns/state/cells';
import { TRANSACTION_KEYS } from "modules/Payments/consts";

export default strappedConnected(
    withShouldRender(DonationsWidget),
    {
        financialsAccount: (state, { campaignId }) => fetchEntity.selectField(campaignId, 'financialsAccount')(state),
    },
    {},
    ({ financialsAccount, campaignId, id }) => ({
        label: 'Donate to this campaign!',
        shouldRender: Boolean(financialsAccount),
        donationProps: useMemo(() => ({
            transactionKind: TRANSACTION_KEYS.CAMPAIGN_DONATION,
            campaignId,
        }), [campaignId])
    })
)