import _ from 'lodash'
import { initCell } from 'modules/Core/core-modules/CoreModuleState/utils/cells';
import { OBJECTIVES } from 'modules/Objectives/consts';
import middleware from 'modules/Objectives/middleware';
import { all, call } from 'redux-saga/effects'

const stampObjectives = initCell(
    OBJECTIVES,
    {
        name: 'stampObjectives',
        reducer: (state) => {
            state.isLoading = true;
        },
        sagas: {
            latest: function* ({ payload }) {
                const { ids, stamp } = payload;
                const results = yield all(ids.map(id => call(
                    middleware.ops.stampObjective,
                    {
                        id,
                        stamp
                    }
                )));
                return results?.map(res => res?.stampObjective)
            },
            onSuccess: "Successfully updated objective/s."
        },
        successCell: {
            reducer: (state, payload) => {
                payload?.map(res => {
                    state.isLoading = false;
                    state.entities[res?.id].stamps = _.merge(
                        state?.entities?.[res?.id].stamps,
                        res?.stamps
                    )
                })

            }
        }
    }
);

export default stampObjectives;