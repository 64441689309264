import feed from "../feed";
import removeComment from "./removeComment";

export const {
    setFilters,
    fetchFeed,
    createEntity
} = feed.cells;

export default {
    createEntity,
    fetchFeed,
    setFilters,
    removeComment
}