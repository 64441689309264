import { gql } from "graphql-request";
import { graphqlClient } from "modules/Core/middleware";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";

const query = gql`
	query campaignMaintainers($campaignId: String, $feedParams: FeedParams,) {
		feed: campaignMaintainers(campaignId: $campaignId, feedParams: $feedParams)  {
			entities {
				user
				id
				isActive
				roles
				information {
					firstName
					lastName
					bio
					photo
				}
				application {
					name
					text
					status
      			}
			}
			${FEED_INFO_FRAGMENT}
		}
	}
`;

export const fetchMaintainers = async (params) => {
	return graphqlClient.request(query, params);
};
