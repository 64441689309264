import { call, put, select } from "redux-saga/effects";
import { initCell } from "../../../Core/core-modules/CoreModuleState/utils/cells";
import { AUTH } from "../../consts";
import { setUserInformation } from "modules/Auth/middleware";
import { putSuccess } from "modules/Core/state/utils";
import authorizeToken from "./authorizeToken";

export default initCell(AUTH, {
  name: "setUserInformation",
  reducer: (state) => {
    state.isLoading = true;
  },
  sagas: {
    latest: function* ({ payload }) {
      const res = yield call(setUserInformation, payload?.input);
      const isSuccess = Boolean(res?.setUserInformation?.id);
      if (isSuccess) {
        yield putSuccess(AUTH, "setUserInformation", res);
        yield put(authorizeToken.action()); //TODO: update state from mutation result instead.
      }
      return res;
    },
    onError: "Error setting user information",
  },
  successCell: {
    reducer: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});
