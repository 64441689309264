import { gql } from "graphql-request";
import { graphqlClient } from "modules/Core/middleware";

const mutation = gql`
    mutation stampApplication($id: String, $approved: Boolean, $rejected: Boolean) {
        stampCampaignMaintainerApplication(
            approved: $approved,
            rejected: $rejected, 
            id: $id
        )
    }
`;

export const stampMaintainerApplication = (params) => graphqlClient.request(mutation, params)
