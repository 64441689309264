import React from "react";
import { UserInformationFormSuccess } from "modules/Auth/components/UserInformationForm";
import userInformationSchema from "modules/Auth/components/UserInformationForm/schema";
import Container from "modules/Core/components/ui-kit/Container";
import Text from "modules/Core/components/ui-kit/Text";
import partnerOnboardingSchema from "modules/Partners/components/PartnerOnboardingForm/schema";
import TeamMemberField from "modules/Partners/components/PartnerOnboardingForm/TeamMemberField";
import styles from "./styles.module.scss";

const PartnerSuccess = () => (
  <Container flex col className={styles.success}>
    <Text>
      Congratulations, you’ve successfully onboarded your organisation. The
      organisation is pending approval and will appear on the platform upon
      being accepted.
    </Text>
    <Text>
      In the meantime, you are welcome to complete your own profile information
      too.
    </Text>
  </Container>
);

export default [
  {
    Component: React.Fragment,
    cardHeader: "Register as partner organisation",
    onBack: "NAVIGATE_BACK",
  },
  {
    Component: React.Fragment,
    cardHeader: "Register as partner organisation",
  },
  {
    title: "Organisation Initial Details",
    subheading: `Authenticated! Enter your organisation's details to continue`,
    fields: {
      name: partnerOnboardingSchema.fields.name,
      image: partnerOnboardingSchema.fields.image,
      bio: partnerOnboardingSchema.fields.bio,
    },
    onNext: "NEXT",
    nextButtonText: "Next",
    cardHeader: "Register as partner organisation",
  },
  {
    title: "Organisation Background Details",
    fields: {
      website: partnerOnboardingSchema.fields.website,
      location: partnerOnboardingSchema.fields.location,
      tags: partnerOnboardingSchema.fields.tags,
      background: partnerOnboardingSchema.fields.background,
      mission: partnerOnboardingSchema.fields.mission,
      contactInformation: partnerOnboardingSchema.fields.contactInformation,
      links: partnerOnboardingSchema.fields.links,
    },
    onNext: "NEXT",
    nextButtonText: "Next",
    subheading: "Enter your organisation's background details",
    cardHeader: "Register as partner organisation",
    onBack: "BACK",
  },
  {
    title: "Organisation Members",
    subheading: "Add the people in your organisation",
    fields: {
      members: {
        label: "Organisation Members",
        buttonLabel: "Add Member",
        Component: TeamMemberField,
        submitButtonText: "Confirm Member Details",
      },
    },
    onNext: "SUBMIT_PARTNER_FORM",
    nextButtonText: "Next",
    cardHeader: "Register as partner organisation",
    onBack: "BACK",
  },
  {
    title: "Submission Successful!",
    Component: PartnerSuccess,
    onNext: "NEXT",
    nextButtonText: "Next",
    cardHeader: "Submission successful!",
  },
  {
    title: "Individual Profile Info",
    ...userInformationSchema,
    subheading:
      "Organisation registered successfully! Now enter some profile details (all fields are optional).",
    onNext: "SUBMIT_INDIVIDUAL_FORM",
    nextButtonText: "Submit",
    cardHeader: "Register your profile information",
    onBack: "BACK",
  },
  {
    title: "Successful Individual Registration",
    Component: UserInformationFormSuccess,
    onNext: "NEXT",
    nextButtonText: "See Campaigns",
    buttonProps: {
      to: "/campaigns",
      onClick: null,
    },
    cardHeader: "Registration successful!",
  },
];
