import { initCell } from "modules/Core/core-modules/CoreModuleState/utils/cells";
import {
  onError,
  onSuccess,
} from "modules/Core/sub-modules/Dialog/state/cells";
import { JUDGEMENTS } from "modules/Judgements/consts";
import { votePolicy as api } from "modules/Judgements/middleware";
import { call, put } from "redux-saga/effects";
import { fetchFeed } from "./index.js";

const votePolicy = initCell(JUDGEMENTS, {
  name: "votePolicy",
  sagas: {
    latest: function* ({ payload }) {
      const res = yield call(api, {
        id: payload?.id,
      });
      if (res?.vote?.id) {
        yield put(onSuccess("Voted successfully"));
      }
      return res?.vote?.id;
    },
    onError: function* ({ error }) {
      yield put(onError("Already Voted "));
    },
    onSuccess: function* ({ payload }) {
      yield put(onSuccess("Voted successfully"));
      yield put(fetchFeed.action({ renewStream: true, renewEntities: true }));
    },
  },
});

export default votePolicy;
