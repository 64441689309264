import Screen from "modules/Core/components/ui-kit/Screen";
import CreateBudgetForm from "../../CreateBudgetForm";
import Container from "modules/Core/components/ui-kit/Container";
import Text from "modules/Core/components/ui-kit/Text";
import CampaignName from "modules/Campaigns/components/CampaignName/index.withShouldFetch";

const CreateBudgetScreen = ({ campaignId }) => (
    <Screen header={
        <Container flex alignCenter>
            <CampaignName 
                id={campaignId} 
                shouldFetch 
                adapter={name => `${name}: `} 
            />
            <Text>
                {`Create Budget`}
            </Text>
        </Container>
    }>
        <CreateBudgetForm campaignId={campaignId} />
    </Screen>
);

export default CreateBudgetScreen;