import FeedModule from "modules/Core/core-modules/FeedModule";
import { createInvitation, fetchInvitation } from "modules/Invitations/middleware";
import { INVITATIONS } from "modules/Invitations/consts";

export default new FeedModule({ 
    name: INVITATIONS,  
    cellOptions: {
        createEntityCell: {
            requestHandler: createInvitation
        },
        fetchFeedCell: {
            requestHandler: fetchInvitation
        }, 
        fetchEntityCell: {
            requestHandler: fetchInvitation,
            parseRes: ({invitation}) => {
                invitation.variables = JSON.parse(invitation.variables) 
                return invitation;
            }
        }
    }
});