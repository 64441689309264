import cx from 'classnames';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'modules/Core/components/ui-kit/Container';
import Button from 'modules/Core/components/ui-kit/Button';
import strapped from 'modules/Core/higher-order-components/strapped';
import useFocusedId from 'modules/Core/hooks/useFocusedId';
import CenteredCardScreen from 'modules/Core/components/ui-kit/Screen/CenteredCardScreen';
import styles from './styles.module.scss';

const Register = ({ setDest, focusedDest, onNext }) => (
    <CenteredCardScreen
        header='Register'
        subHeader='Register as a regular user or a partner organisation.'
        onNext={onNext}
        nextText={'Next'}
        cardHeader='Register'
        cardClassName={styles.card}
        background
        back
    >
        <Container className={styles.contentContainer} span flex col center>
            <Button
                className={cx({ [styles.selected]: Boolean(focusedDest == './individual') })}
                onClick={() => setDest('./individual')}
            >
                Individual
            </Button>
            <Button
                className={cx({ [styles.selected]: Boolean(focusedDest == './partner') })}
                onClick={() => setDest('./partner')}
            >
                Organisation
            </Button>
        </Container>
    </CenteredCardScreen>
)

export default strapped(Register, () => {
    const { focusedId, focus } = useFocusedId();
    const navigate = useNavigate();
    return { setDest: focus, focusedDest: focusedId, onNext: useCallback(() => navigate(focusedId), [focusedId, navigate]) };
});