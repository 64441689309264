import { useCallback } from "react";
import Container from "modules/Core/components/ui-kit/Container";
import Button from "modules/Core/components/ui-kit/Button";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { fetchEntity } from "modules/Partners/submodules/business-partnerships/state/cells";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import Features from "modules/Core/components/ui-kit/Features";
import useOnError from "modules/Core/sub-modules/Dialog/hooks/useOnError";
import styles from "./styles.module.scss";

const BusinessPartnerSubscriptionTab = ({ tier, created, onCancel }) => (
    <Container className={styles.container}>
        <Features
            row
            features={Boolean(tier) && [
                {
                    name: "Subscription Tier",
                    content: tier
                },
                {
                    name: "Subscription Created",
                    content: created
                },
                {
                    name: "Cancel Subscription",
                    content: <Button onClick={onCancel}>Cancel</Button>
                }
            ]}
        />
    </Container>
);

export default strappedConnected(
    BusinessPartnerSubscriptionTab,
    { subscription: (state, { partnerId }) => fetchEntity.selector(partnerId)(state) },
    { onLoad: (id) => fetchEntity.action({ id }) },
    ({ partnerId, onLoad, subscription }) => {
        useOnLoad(() => onLoad(partnerId), Boolean(partnerId), [partnerId]);
        const error = useOnError()
        return {
            tier: subscription?.tier,
            created: subscription?.stamps?.created,
            onCancel: useCallback(() => error("Contact P4P support to cancel account"), [error])
        }
    }
);

