import cx from "classnames";
import Container from "modules/Core/components/ui-kit/Container";
import Title from "modules/Core/components/ui-kit/Title";
import Text from "modules/Core/components/ui-kit/Text";
import PostActions from "../PostActions";
import Footer from "modules/Core/components/ui-kit/layout/Footer";
import CommentsFeed from "modules/Posts/sub-modules/Comments/components/CommentsFeed";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import ConditionalContainer from "modules/Core/components/ui-kit/ConditionalContainer";
import Attachment from "modules/Core/components/ui-kit/Attachment";
import styles from "./styles.module.scss";

const ViewPost = ({
  post,
  headerChildren,
  className,
  textClassName,
  comments = true,
  ...props
}) => {
  return (
    <Container className={cx(styles.container, className)} {...props}>
      <Container relative>
        <Container flex spaceBetween alignCenter className={styles.header}>
          <Title>{post?.name}</Title>
          {headerChildren}
        </Container>
        <Container className={cx(styles.text, textClassName)}>
          <Text translatable linked text={post?.text} />
          <ConditionalContainer
            shouldRender={Boolean(post?.attachments?.length)}
            col
          >
            <Title Element='h3'>{"Attachments:"}</Title>
            <Container className={styles.attachmentsContainer} row>
              {post?.attachments?.map(({ type, url }, index) =>
                type.includes("image") ? (
                  <img key={index} src={url} alt="attachment" />
                ) : (
                  <Attachment key={index} file={url} />
                )
              )}
            </Container>
          </ConditionalContainer>
          <Comments postId={post?.id} shouldRender={comments} />
        </Container>
        <Footer className={styles.footer} absolute flex column alignCenter>
          <PostActions postId={post?.id} post={post} comments={comments} />
        </Footer>
      </Container>
    </Container>
  );
};

const Comments = withShouldRender(CommentsFeed);

export default ViewPost;
