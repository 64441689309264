import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "../../middleware";
import { BUDGET_TRANSACTIONS } from "../../consts";

const { fetchFeed } = middleware.ops;

export default new FeedModule({
    name: BUDGET_TRANSACTIONS,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchFeed
        }
    }
});