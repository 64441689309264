import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import auth from "modules/Auth/state/cells/authorizeToken"
import useOnError from "modules/Core/sub-modules/Dialog/hooks/useOnError";
import { useCallback, useMemo } from "react";

export const useIsAuthenticated = () => useSelector(auth.selectors.isAuthorized);

export const useIsAuthenticatedMemo = (onTrue, onFalse) => {
    const authenticated = useIsAuthenticated();
    return useMemo(() => authenticated ? onTrue : onFalse, [authenticated, onTrue, onFalse]);
}

export const useConnectedAction = (connectedAction, disconnectedAction) => {
    const isConnected = useSelector(state => auth.selectors.isAuthorized(state));
    const dispatch = useDispatch();
    return useCallback((...args) => isConnected ? dispatch(connectedAction(...args)) : dispatch(disconnectedAction(...args)), [isConnected, dispatch, connectedAction, disconnectedAction]);
}

export default (action) => {
    const isConnected = useSelector(state => auth.selectors.isAuthorized(state));
    const dispatch = useDispatch();
    const onError = useOnError();
    return useMemo(() => isConnected ? dispatch : () => onError("User must be logged in for this action"), [isConnected, dispatch, action, onError]);
}
