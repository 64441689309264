import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { PAYMENTS } from "../consts";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";

const createPaymentIntent = `
    query paymentIntent(
        $amount: Float, 
        $budgetId: String, 
        $businessPartnerId: String, 
        $campaignId: String, 
        $transactionKind: TRANSACTION_KIND,
        $tithe: Boolean
        $partnerId: String
    ) {
        paymentIntent(
            amount: $amount, 
            budgetId: $budgetId, 
            businessPartnerId: $businessPartnerId, 
            campaignId: $campaignId, 
            transactionKind: $transactionKind,
            tithe: $tithe,
            partnerId: $partnerId
        ){
            id
            clientSecret
        }
    }
`;

const fetchFeedDataGQL = `
    query transactionsFeedData($feedParams: FeedParams, $campaignId: String, $partnerId: String, $isPlatformDonation: Boolean) {
        feedData: transactionsFeedData(
            feedParams: $feedParams, 
            campaignId: $campaignId, 
            partnerId: $partnerId, 
            isPlatformDonation: $isPlatformDonation
        ) {
            total
        }
    }
`;

const fetchFeed = `
    query transactions($feedParams: FeedParams, $campaignId: String, $partnerId: String, $isPlatformDonation: Boolean) {
        feed: transactions(
            feedParams: $feedParams, 
            campaignId: $campaignId, 
            partnerId: $partnerId, 
            isPlatformDonation: $isPlatformDonation
        )  {
            entities {
                id
                price
                post {
                    text
                }
                stamps {
                    created {
                        time
                    }
                }
            }
            ${FEED_INFO_FRAGMENT}
        }
    }
`;

const fetchPayment = `
    query transaction($id:String, $paymentIntentId: String) {
        entity: transaction(id: $id, paymentIntentId: $paymentIntentId) {
            id 
            terms {
                amount
            }
            stamps {
                created {
                    time
                    user    
                }
            }
        }
    }
`;

const operations = {
  createPaymentIntent,
  fetchFeed,
  fetchPayment,
  fetchFeedData: fetchFeedDataGQL,
};

const middleware = new MiddlewareModule({
  name: PAYMENTS,
  operations,
  operationsConfig: {
    fetchPayment: {
      paramsParser: (id) => ({ paymentIntentId: id }),
    },
  },
});

export const { createPaymentIntent: cpi, fetchFeedData } = middleware.ops;

export default middleware;
