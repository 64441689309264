import { useMemo } from "react";
import Container from "modules/Core/components/ui-kit/Container"
import PostsFeed from "modules/Posts/components/PostsFeed"

const PartnerPostsTab = ({ partnerId }) => {
    const filters = useMemo(() => ({
        partnerId
    }), [partnerId]);
    return (
        <Container>
            <PostsFeed filters={filters} />
        </Container>
    )
};

export default PartnerPostsTab;