import cx from 'classnames';
import emblem from "../../assets/logo.jpg";
import comprehensive from "../../assets/comprehensive-logo.svg";
import decoratedName from '../../assets/decorated-name.png';
import styles from './styles.module.scss';

const natures = {
    emblem,
    comprehensive,
    decoratedName
}

const P4PEmblem = ({ className, nature = 'emblem', containerClassName, onClick }) => (
    <span className={containerClassName} onClick={onClick}>
        <img src={natures[nature]} alt="P4P" className={cx(styles.emblem, className)} />
    </span>
);

export default P4PEmblem;