import Screen from "modules/Core/components/ui-kit/Screen";
import PartnerOnboardingForm from "../../PartnerOnboardingForm";
import withStripQueryParams from "modules/Core/higher-order-components/withStripQueryParams";
import styles from "./styles.module.scss";

const PartnerOnboarding = (props) => (
    <Screen
        className={styles.screen}
        header={'Partner Onboarding'}
        auth
    >
        <PartnerOnboardingForm {...props} />
    </Screen>
);

export default withStripQueryParams(PartnerOnboarding);