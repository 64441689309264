import CoreModule from "../Core/core-modules/CoreModule";
import OnLoad from "./components/OnLoad";
import { AUTH } from "./consts";
import routes from "./routes";
import cells from "./state/cells";

const Auth = new CoreModule({
    routes,
    name: AUTH,
    initialState: {
        isLoading: false,
        isAuthorized: false,
        form: {},
    },
    cells,
    rootRender: () => <OnLoad />
});

export default Auth;
