import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Screen from "modules/Core/components/ui-kit/Screen";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import Invitation from "modules/Invitations/components/Invitation";
import CampaignPartnershipInvitation from "modules/Invitations/components/invitations/CampaignPartnershipInvitation";
import { createEntity } from "../../../state/cells";
import useOnSuccess from "modules/Core/sub-modules/Dialog/hooks/useOnSuccess";
import useOnError from "modules/Core/sub-modules/Dialog/hooks/useOnError";
import Container from "modules/Core/components/ui-kit/Container";
import { BackNavigation } from "modules/Core/components/ui-kit/Screen/ScreenSidePanel/ScreenNavigation";
import styles from "./styles.module.scss";

const back = {
    text: 'Back',
    navigate: -2,
    to: true
}

const CampaignPartnershipInvitationScreen = ({ invitationId, rsvp }) => (
    <Screen flex header='Campaign-Organisation Partnership Invitation'>
        <Container absolute span flex center>
        <BackNavigation back={back} className={styles.back}/>
            <Invitation
                id={invitationId}
                TemplateComponent={CampaignPartnershipInvitation}
                rsvp={rsvp}
            />
        </Container>
    </Screen>
);

export default strappedConnected(
    CampaignPartnershipInvitationScreen,
    null,
    {
        rsvp: (invitationId, accepted, callback) => createEntity.action({
            invitationId,
            accepted,
            callback
        })
    },
    ({ rsvp, invitationId }) => {
        const onSuccess = useOnSuccess();
        const onError = useOnError();
        const navigate = useNavigate();
        const callback = useCallback((result, accepted) => {
            // COULDO: prettier
            const isAccepted = Boolean(result?.campaignPartnershipRSVP?.id)
            if (isAccepted) {
                const campaignId = result?.campaignPartnershipRSVP?.campaignId
                onSuccess("You've successsfully partnered with this campaign!")
                return navigate("/campaigns/" + campaignId)
            }
            if (accepted && !isAccepted) return onError("Failed to partner with this campaign.")
            return onError("You've chosen not to partner with this campaign")
        });
        return {
            rsvp: (accepted) => rsvp(invitationId, accepted, (result) => callback(result, accepted))
        }
    }
)