import Screen from 'modules/Core/components/ui-kit/Screen';
import CampaignDonationsFeed from '../../CampaignDonationsFeed';

const CampaignDonations = ({campaignId}) => {
    return (
        <Screen header={"Campaign Donations"}>
            <CampaignDonationsFeed campaignId={campaignId} />
        </Screen>

    )
};

export default CampaignDonations;