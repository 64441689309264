import Tag from "modules/Core/components/ui-kit/Tag";
import Container from "modules/Core/components/ui-kit/Container";
import ConditionalContainer from "modules/Core/components/ui-kit/ConditionalContainer";
import Button from "modules/Core/components/ui-kit/Button";
import styles from './styles.module.scss';

const CampaignFeedItemDetails = ({ id, campaign, details, vision, ...props }) => {
    return (
        <Container className={styles.container} flex col maxHeight spaceBetween relative span>
            <Container>
                {vision}
            </Container>
            <Container flexEnd flex>
                {details?.tags?.map(tag => <Tag text={tag?.name} />)}
            </Container>
            <ConditionalContainer shouldRender={Boolean(props?.displayViewCampaign)} className={styles.footer}>
                <Button to={`/campaigns/${id}`} >
                    View
                    <span className='chevron-right-container' />
                </Button>
            </ConditionalContainer>
        </Container>
    )
};

export default CampaignFeedItemDetails