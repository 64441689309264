import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "../../middleware";
import { BUDGET_ITEMS } from "../../consts";

const { fetchFeed, fetchEntity } = middleware.ops;

export default new FeedModule({
    name: BUDGET_ITEMS,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchFeed,
        },
        fetchEntityCell: {
            requestHandler: fetchEntity,
        }
    }
});