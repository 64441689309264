import Container from 'modules/Core/components/ui-kit/Container';
import CampaignName from '../CampaignName';
import ParentCampaignCard from '../ParentCampaignCard';
import DetailsSection from '../../../Core/components/ui-kit/DetailsSection';
import styles from './styles.module.scss';

const CampaignDetailsComponent = ({ id, className, headerChildren, nameAppendage, name, description, aims, parent, children }) => name && (
    <Container className={className} flex column span center>
        <Container maxWidth lightShadow className={styles.contentContainer}>
            <Container>
                <Container>
                    <Container span spaceBetween flex alignCenter>
                        <CampaignName.Component name={name} Wrapper="h1" />
                        {nameAppendage}
                    </Container>
                    {headerChildren}
                </Container>
                <DetailsSection sectionTitle={"Parent Campaign"} shouldRender={Boolean(parent?.name)}>
                    <ParentCampaignCard.Component name={parent?.name} id={parent?.id} />
                </DetailsSection>
                {children}
            </Container>
        </Container>
    </Container>
);

export default CampaignDetailsComponent