import Screen from "modules/Core/components/ui-kit/Screen";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { syncVerificationCell } from "../../../state/cells";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import { useCallback } from "react";
import useOnError from "modules/Core/sub-modules/Dialog/hooks/useOnError";
import useOnSuccess from "modules/Core/sub-modules/Dialog/hooks/useOnSuccess";
import { useNavigate } from "react-router-dom";
import Loader from "modules/Core/components/ui-kit/Loader";


const RegisterPartnerFinancialsAccountSuccess = () => {
    return (
        <Screen>
                <Loader loading={true} />
        </Screen>
    )
};

export default strappedConnected(
    RegisterPartnerFinancialsAccountSuccess,
    {},
    { onLoad: (partnerId, callback) => syncVerificationCell.action({ partnerId, callback }) },
    ({ partnerId, onLoad, }) => {
        const onError = useOnError();
        const onSuccess = useOnSuccess();
        const navigate = useNavigate();

        const callback = useCallback((result) => {
            if (!result?.syncPeerPartnerAccountVerification) return onError("Failed to successfully register connected account");
            onSuccess("Successfully registered connected account");
            navigate('/partner/profile/' + partnerId)
        }, [partnerId])
        useOnLoad(() => onLoad(partnerId, callback), Boolean(partnerId), [partnerId, callback])
    }
);