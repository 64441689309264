import React, { useMemo } from 'react';
import CreateCampaignButton from '../../CreateCampaignButton';
import FeedPanel from './panels/FeedPanel';
import useFocusedId from 'modules/Core/hooks/useFocusedId';
import CampaignsFilterWidget from '../../widgets/CampaignsFilterWidget';
import DonationsWidget from 'modules/Payments/components/DonationsWidget';
import Screen from 'modules/Core/components/ui-kit/Screen';
import Button from 'modules/Core/components/ui-kit/Button';
import withShouldRender from 'modules/Core/higher-order-components/withShouldRender';
import SubcampaignsWidget from '../../widgets/SubcampaignsWidget';
import CampaignPostsWidget from '../../widgets/CampaignPostsWidget';
import styles from './styles.module.scss'


const leftWidgets = [
    {
        Component: withShouldRender(CampaignPostsWidget, 'campaignId'), gridRow: '1/2'
    },
    { Component: CampaignsFilterWidget, gridRow: '2/3' },
    {
        Component: withShouldRender(SubcampaignsWidget, 'campaignId'), gridRow: '4/5'
    },
];

const rightWidgets = [
    {
        Component: DonationsWidget,
        gridRow: '2/3'
    },
    {
        Component: CreateCampaignButton,
        gridRow: '8',
        props: { panel: true, widget: true }
    },
    {
        Component: ({ campaignId }) => <Button text={'View Campaign'} to={'/campaigns/' + campaignId} shouldRender={Boolean(campaignId)} panel widget />,
        gridRow: '1',
    }
];


const CampaignsFeed = () => {
    const { focusedId, focus, unFocus } = useFocusedId();
    const leftProps = useMemo(() => ({
        widgets: leftWidgets,
        className: styles.sidePanel,
        widgetProps: { campaignId: focusedId ?? false }
    }), [focusedId]);

    const rightProps = useMemo(() => ({
        focusedId,
        className: styles.sidePanel,
        widgets: rightWidgets,
        widgetProps: { campaignId: focusedId ?? false }
    }), [focusedId]);

    const mainProps = useMemo(() => ({
        onClick: focus,
        className: styles.mainContent,
        focusedId,
        unFocus,
    }), [focus, focusedId, unFocus]);

    return (
        <Screen
            headerProps={{ className: styles.header }}
            MainContentComponent={FeedPanel}
            mainProps={mainProps}
            rightProps={rightProps}
            leftProps={leftProps}
            header={'Campaigns Feed'}
            tripanel
        />
    )
};

export default CampaignsFeed