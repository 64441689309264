import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import { createEntity } from '../../state/cells';
import Component from './Component.index';
import useComponent from "./useComponent.index";
import setCampaignMembershipInformation from 'modules/Campaigns/state/cells/setCampaignMembershipInformation';
import { fetchEntity } from 'modules/Campaigns/state/cells';
import authorizeToken from 'modules/Auth/state/cells/authorizeToken';

export default strappedConnected(
    Component,
    {
        memberInfo: (state, { campaignId }) => fetchEntity.selectNestedField(campaignId, 'membership.information')(state),
        userInfo: (state) => authorizeToken.selectors.getUserInformation(state)
    },
    {
        onCreate: createEntity.action,
        setCampaignMembershipInfo: (campaignId, input, callback) => setCampaignMembershipInformation.action({ campaignId, input, callback })
    },
    useComponent
)