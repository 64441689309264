import CampaignsPosts from 'modules/Campaigns/components/CampaignPosts';
import CampaignsFeed from '../../CampaignsFeed';
import CampaignDetails from 'modules/Campaigns/components/CampaignDetails';
import { fetchEntity } from 'modules/Campaigns/state/cells';
import withPanelNavigator from 'modules/Core/higher-order-components/withPanelNavigator';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

export const SubcampaignsFeed = ({ id, ...props }) => {
    const navigate = useNavigate();
    const feedProps = useMemo(() => ({
        onClick: ({id}) => navigate(`/campaigns/${id}/gateway`)
    }), [navigate])
    return (
        <CampaignsFeed 
            alignCenter flex column 
            feedItemClassName={styles.nestedFeedItem} 
            {...props} 
            parentId={id} 
            feedProps={feedProps}
        />

    )
}



const PostsPanel = withPanelNavigator(
    CampaignsPosts,
    'right',
    {
        getRestrictionSelector: (props) => fetchEntity?.selectField(props?.id, 'isMember'),
        to: "./camp",
        onRestrictionMessage: "You must be a member to enter the campaign campsite."
    }
)


export default [

    {
        Component: SubcampaignsFeed,
        props: { className: styles.campaignsFeed },
        title: "Sub-Campaigns",
    },
    {
        Component: CampaignDetails,
        props: { className: styles.campaignDetails },
        title: "Campaign Details",
        className: styles.campaignDetailsPanel,

    },
    {
        Component: PostsPanel,
        props: { className: styles.campaignsPosts },
        title: "Campaign Posts",
    },
]