import React from "react";
import SplitScreen from "modules/Core/components/ui-kit/SplitScreen";
import RedirectButton from "modules/Core/components/ui-kit/RedirectButton";
import Container from "modules/Core/components/ui-kit/Container";
import panels from "./panels";
import styles from "./styles.module.scss";

const JudgementsFeed = () => (
  <SplitScreen
    panels={panels}
    headerProps={{ className: styles.header }}
    footer={
      <Container className={styles.footerContent} flex span flexEnd alignCenter>
        <RedirectButton
          to={`/create-judgement-case`}
          text={"Create Judgement"}
          className={styles.btn}
        />
      </Container>
    }
  />
);

export default JudgementsFeed;
