import { useMemo } from "react";
import Form from "modules/Core/components/form/Form";
import Container from "modules/Core/components/ui-kit/Container";
import Text from "modules/Core/components/ui-kit/Text";
import schema from "./schema";
import RedirectButton from "modules/Core/components/ui-kit/RedirectButton";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import styles from "./styles.module.scss";

const UserInformationForm = ({ ...props }) => (
    <Form {...props} />
);

export const UserInformationFormSuccess = () => (
    <Container className={styles.container} col flex flexStart >
        <Text>
            Congratulations, you’ve successfully registered and joined P4P.
        </Text>
        <Text>
            You may now participate participate in <RedirectButton to={'/campaigns'}>campaigns</RedirectButton>, create and share posts and more.
        </Text>
        <Text>
            To learn more, see our user guide
            <RedirectButton to={'/user-guide'}>
                here.
            </RedirectButton>
        </Text>
    </Container>
)

export const parseUserInformationInput = ({ name, ...input }) => ({
    ...name,
    ...input
})

export default strappedConnected(UserInformationForm,
    {},
    {},
    ({
        additionalFields = {},
        onSubmit,
    }) => {

        return ({
            schema: useMemo(() => ({
                fields: {
                    ...schema.fields,
                    ...additionalFields
                }
            }), [additionalFields]),
            onSubmit: ({ name, ...fields }, callback) =>  onSubmit(parseUserInformationInput({ name, ...fields }), callback),
        })
    })