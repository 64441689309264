import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import DonateScreen from "modules/Payments/components/DonateScreen";
import { TRANSACTION_KEYS } from "modules/Payments/consts";

export default strappedConnected(
    DonateScreen,
    {},
    {},
    ({  }) => ({
        cardHeader: 'Donate to P4P',
        transactionKind: TRANSACTION_KEYS.PLATFORM_DONATION
    })
);