import Screen from "modules/Core/components/ui-kit/Screen";
import ProspectiveBudgetsFeed from "../../ProspectiveBudgetsFeed";


const ProspectiveBudgets = () => {
    return <Screen>
        <ProspectiveBudgetsFeed />
    </Screen>
};

export default ProspectiveBudgets;