import query from "./middleware";
import { PARTNER_KINDS } from "modules/Partners/consts";
import PartnerIdentificationCard from "modules/Partners/components/PartnerIdentificationCard";
import styles from "./styles.module.scss";

export default {
    fields: {
        partner: {
            label: "Send to:",
            type: 'async-select',
            placeholder: "Select a partner",
            variables: {kind: PARTNER_KINDS.PEER },
            query, 
            parseResult: r => r?.partners?.entities?.map((partner) => ({
                readable: (
                    <PartnerIdentificationCard 
                        name={partner?.name} 
                        image={partner?.info?.image} 
                        className={styles.selectItem} 
                    />
                ),
                key: partner.id
            })),
            required: true
        },
        message: {
            label: "Custom Message:",
            type: "textarea",
        },
    },
}