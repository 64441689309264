import { OBJECTIVES } from "../consts";
import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import createObjectiveGql from "./create-objective.gql";
import fetchObjectivesFeedGql from "./fetch-objectives-feed.gql";
import fetchObjectiveEntityGql from "./fetch-objective-entity.gql";
import fetchActiveObjectivesFeedGql from "./fetch-active-objectives-feed.gql";
import stampObjectiveGql from "./stamp-objective.gql";

const operations = {
    create: createObjectiveGql,
    fetchFeed: fetchObjectivesFeedGql,
    fetchEntity: fetchObjectiveEntityGql,
    fetchActiveObjectivesFeed: fetchActiveObjectivesFeedGql,
    stampObjective: stampObjectiveGql
}

export default new MiddlewareModule({
    name: OBJECTIVES,
    operations,
    operationsConfig: {
        fetchEntity: {
            paramsParser: (id) => ({ id }),
            postParser: ({ entity }) => ({
                entity: {

                    ...entity,
                    isReadyToAchieve: entity?.subObjectives?.length === 0 || entity?.subObjectives.every(({ stamps }) => Boolean(stamps?.custom?.achieved))
                }
            }),
        }
    }
});

