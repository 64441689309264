import {  useState } from "react";
import cx from "classnames";
import { formatDate } from "modules/Core/utils/date";
import Container from "modules/Core/components/ui-kit/Container";
import ExpandableDetails from "modules/Campaigns/components/ExpandableDetailsSet";
import useDispatchWithLoader from "modules/Core/hooks/useDispatchWithLoader";
import votePolicy from "modules/Judgements/state/cells/votePolicy";
import DetailsSection from "../../../Core/components/ui-kit/DetailsSection";
import Text from "modules/Core/components/ui-kit/Text";
import Title from "modules/Core/components/ui-kit/Title";
import Button from "modules/Core/components/ui-kit/Button";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import PostPolicy from "./postPolicyComponent";
import PostsFeed from "modules/Posts/components/PostsFeed";
import ViewPostPanel from "modules/Posts/components/ViewPostPanel";
import { useIsOpen } from "modules/Core/hooks/useIsOpen";
import styles from "./styles.module.scss";

const PostPolicies = withShouldRender(PostPolicy);
const JudgementDetailsComponent = ({
  className,
  name,
  openingTime,
  closingTime,
  background,
  policies,
}) => {
  const [action] = useDispatchWithLoader(votePolicy.action);
  const [fullEntity, setFullEntity] = useState([]);
  const { isOpen, open, close } = useIsOpen();

  return (
    name && (
      <Container className={className} flex column span center>
        <Container className={styles.contentContainer}>
          <Container>
            <Text
              text={new Date().getTime() > closingTime ? "Close" : "Open"}
              className={cx(styles.availibilityLabel, {
                [styles.open]: new Date().getTime() < closingTime,
              })}
            />
            <Text text={`Open Time: ${formatDate(openingTime)}`} />
            <Text text={`Close Time: ${formatDate(closingTime)}`} />
            <Title text={name} />
            <DetailsSection
              sectionTitle={"Judgement Background"}
              className={styles.descriptionSectionContainer}
            >
              <Text
                text={background}
                className={cx(styles.description, className)}
                linked
                translatable
              />
            </DetailsSection>
            <ViewPostPanel
              postId={fullEntity?.id}
              className={cx(styles.viewPostPanel, {
                [styles.open]: !!fullEntity?.id,
              })}
              headerChildren={
                <span
                  className={cx("chevron-right-container", styles.chevron)}
                  onClick={() => setFullEntity(null)}
                />
              }
              onClose={() => setFullEntity(null)}
            />
            
            <DetailsSection
              sectionTitle={"Prospective Policies"}
              bordered={false}
            >
              <ExpandableDetails
                size={"lg"}
                entities={policies}
                renderChildren={({ id, isVoted }) => (
                  <Container className={styles.container}>
                    <Button
                      disabled={isVoted}
                      onClick={() => {
                        action({ id: id });
                      }}
                    >
                      {isVoted ? "Voted" : "Vote"}
                    </Button>
                    <Button onClick={open}> Post </Button>
                    {isOpen && <PostPolicies policyId={id} isOpen={isOpen} close={close} />}
                    <Container mb3 maxWidth>
                      <PostsFeed
                        filters={{ policyId: id }}
                        onClick={(post) => setFullEntity(post)}
                      />
                    </Container>
                  </Container>
                )}
              />
            </DetailsSection>
          </Container>
        </Container>
      </Container>
    )
  );
};

export default JudgementDetailsComponent;
