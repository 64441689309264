import feed from "../feed";
import middleware from "../../middleware";
import { initCell } from "modules/Core/core-modules/CoreModuleState/utils/cells";
import { PEER_PARTNER_FINANCIALS } from "../../consts";
import { call } from "redux-saga/effects";
export const {
    setFilters,
    fetchFeed,
    fetchEntity,
    createEntity,
} = feed.cells;

export const syncVerificationCell = initCell(
    PEER_PARTNER_FINANCIALS,
    {
        name: "syncVerification",
        sagas: {
            latest: function* ({ payload }) {
                const result = yield call(
                    middleware.ops.syncVerification,
                    { partnerId: payload.partnerId }
                );
                return result;
            }
        }
    }
)



export default {
    fetchFeed,
    setFilters,
    createEntity,
    fetchEntity,
    syncVerification: syncVerificationCell
}