import CoreModule from "modules/Core/core-modules/CoreModule";
import { ALLIES } from "./consts";
import cells from "./state/cells";
import routes from "./routes";
import feed from "./state/feed";


const Partners = new CoreModule({
    name: ALLIES,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default Partners;