import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";    
import cells from "./state/cells";
import {P4P_VALUES } from "./consts";
import routes from "./routes";

const Values = new CoreModule({
    name: P4P_VALUES,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default Values;