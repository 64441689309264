import withParams from 'modules/Core/higher-order-components/withParams';
import Container from 'modules/Core/components/ui-kit/Container';

import Screen from 'modules/Core/components/ui-kit/Screen';
import styles from "./styles.module.scss";
import MaintainersFeed from '../../MaintainersFeed';

const MaintainersFeedScreen = ({campaignId, ...props }) => {
    return (
        <Screen header="Campaign Maintainers">
            <Container className={styles.container} >
                <MaintainersFeed campaignId={campaignId} {...props} />
            </Container>
        </Screen>
    )
}

export default withParams(MaintainersFeedScreen);