import Button from "modules/Core/components/ui-kit/Button";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { useIsOpen } from "modules/Core/hooks/useIsOpen";
import CreateAction from "../CreateAction";
import { createEntity } from "modules/Actions/state/cells";
import { useCallback } from "react";

const Component = ({ isOpen, open, close, additionalFields = {}, ...props }) => {
    return (
        <>
            <Button onClick={open} text={"Create Action"} />
            <CreateAction
                isOpen={isOpen}
                close={close}
                {...props}
            />
        </>
    )
}

export default strappedConnected(
    Component,
    null,
    { onCreate: (data, callback) => createEntity.action({ input: data, callback }) },
    ({ onCreate, additionalFields }) => {
        const { isOpen, open, close } = useIsOpen();
        const handleSubmit = useCallback((data) => {
            onCreate({
                ...data,
                ...additionalFields
            }, (...args) => {
                close();
            })
        }, [additionalFields])
        return ({
            isOpen,
            open,
            close,
            onSubmit: handleSubmit
        })
    }
);