export default `
    mutation stampObjective($id: String!, $stamp: String!) {
        stampObjective(id: $id, stamp: $stamp){
            id
            stamps {
                custom {
                    achieved {
                        time
                    }
                    commenced {
                        time
                    }
                }
            }
        }
    }
`;