import { fetchEntity } from "modules/Campaigns/state/cells";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import DonateScreen from "modules/Payments/components/DonateScreen";
import { TRANSACTION_KEYS } from "modules/Payments/consts";

export default strappedConnected(
    DonateScreen,
    {
        campaign: (state, { id }) => fetchEntity.selector(id)(state)
    },
    {
        fetchEntity: (id) => fetchEntity.action({ id })
    },
    ({ fetchEntity, campaign, id, }) => {
        useOnLoad(
            () => fetchEntity(id),
            !Boolean(campaign?.details),
            [id]
        )
        return ({
            campaignId: id,
            cardHeader: 'Support ' + campaign?.name,
            transactionKind: TRANSACTION_KEYS.CAMPAIGN_DONATION,
        })
    }
);