import withParams from "modules/Core/higher-order-components/withParams";
import DonationSuccessScreen from "../components/screens/DonationSuccessScreen";
import FinancialDonationPage from "../components/screens/FinancialDonationPage";
import ReceiptPage from "../components/screens/ReceiptPage";
import withQueryParams from "modules/Core/higher-order-components/withQueryParams";

export default {
    "donations": FinancialDonationPage,
    "**/receipt": withQueryParams(ReceiptPage),
    "donation-success/:transactionId": withParams(DonationSuccessScreen),
}