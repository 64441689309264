import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";

export default `
query fetchObjectives($feedParams: FeedParams, $campaignId: String, $prospective: Boolean ) {
    feed: objectives(feedParams: $feedParams, campaignId: $campaignId, prospective: $prospective) {
        entities {
            id
            name
            stamps {
                custom {
                    achieved {
                        time
                    }
                    commenced {
                        time
                    }
                }
            }
        }
        ${FEED_INFO_FRAGMENT}
    }
}
`;
