import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "../../middleware";
import { TEAM_MEMBERS } from "../../consts";

const { fetchFeed, registerTeamMember } = middleware.ops;

export default new FeedModule({
    name: TEAM_MEMBERS,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchFeed
        },
        createEntityCell: {
            requestHandler: registerTeamMember
        }
    }
});