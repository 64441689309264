import cx from "classnames";
import { useDispatch } from "react-redux";
import { createEntity, fetchFeed } from "modules/Posts/state/cells";
import { ExpandableCardComponent } from "modules/Core/components/ui-kit/Card/ExpandableCard";
import PostForm from "modules/Posts/components/forms/PostForm";
import Title from "modules/Core/components/ui-kit/Title";
import Container from "modules/Core/components/ui-kit/Container";
import styles from "./styles.module.scss";

const PostPolicy = ({ policyId, isOpen, close }) => {
  const dispatch = useDispatch();

  const handleSubmit = (data) =>
    dispatch(
      createEntity.action({
        policyId: policyId,
        ...data,
        callback: () => {
          close();
          dispatch(fetchFeed.action({ policyId }));
        },
      })
    );

  return (
    <>
      <ExpandableCardComponent
        isOpen={isOpen}
        openClassName={styles.openPosts}
        className={styles.posts}
        contentClassName={styles.content}
      >
        <PostForm
          header={
            <Container className={styles.formHeader}>
              <Title Element="h4">Create Post</Title>
              <span
                className={cx("chevron-down-container", styles.chevron)}
                onClick={close}
              />
            </Container>
          }
          className={styles.formContainer}
          handleSubmit={handleSubmit}
        />
      </ExpandableCardComponent>
    </>
  );
};

export default PostPolicy;
