import { EditNote } from "modules/Core/components/ui-kit/indicators";
import Action from "../Action";
import { useIsOpen } from "modules/Core/hooks/useIsOpen";
import strapped from "modules/Core/higher-order-components/strapped";
import EditPostModal from "../../EditPost/EditPostModal";

const EditPostAction = ({ postId, isOpen, open, close }) => (
    <>
        <EditPostModal isOpen={isOpen} close={close} postId={postId} />
        <Action
            Indicator={EditNote}
            isActive={isOpen}
            action={open}
        />
    </>
)

export default strapped(
    EditPostAction,
    () => useIsOpen()
);