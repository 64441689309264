import { useCallback, useState } from "react";
import Success from "modules/Core/components/form/Form/Success";
import Button from "modules/Core/components/ui-kit/Button";
import Loader from "modules/Core/components/ui-kit/Loader";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import useOnError from "modules/Core/sub-modules/Dialog/hooks/useOnError";
import { fetchEntity } from "modules/Payments/state/cells";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import config from "modules/Core/config";
import { useNavigate } from "react-router-dom";
import { compactObject } from "modules/Core/utils/obj";


export const DownloadPaymentReceiptButton = withShouldRender(({ transactionId, text = 'Download Receipt', ...props }) => (
    <Button
        {...props}
        download={config?.backendUrl + "financials/receipt/" + transactionId}
        text={text}
    />
));
const PaymentSuccess = ({ transactionId, loading, hideReceipt }) => {
    return (
        <Loader loading={loading}>
            <Success text="Payment Successful" />
            <DownloadPaymentReceiptButton transactionId={transactionId} shouldRender={!hideReceipt} />
        </Loader>
    )
};

export default strappedConnected(
    PaymentSuccess,
    { paymentIntent: (state, { paymentIntentId }) => fetchEntity.selector(paymentIntentId)(state) },
    { onLoad: (id, callback) => fetchEntity.action({ id, callback }) },
    ({ paymentIntentId, onLoad, paymentIntent, shouldRedirect, campaignId, partnerId }) => {
        const [delay, setDelay] = useState(5000);
        const [loading, setLoading] = useState(true);
        const [remainingRetries, setRemainingRetries] = useState(5);
        const [transactionId, setTransactionId] = useState(null);

        const navigate = useNavigate();
        const onError = useOnError();

        const callback = useCallback((result) => {
            if (!Boolean(result)) return setRemainingRetries(remainingRetries - 1);
            setLoading(false);
            setTransactionId(result.id);
            if (shouldRedirect) {
                const params = new URLSearchParams(compactObject({
                    campaignId,
                    partnerId,
                  }));
                navigate('/donation-success/' + result.id + '?' + params.toString());
            }
                

        }, [setTransactionId, shouldRedirect, campaignId, partnerId]);

        const delayedFetch = useCallback(() => {
            if (remainingRetries < 1) return onError("Failed to fetch transaction details");
            setTimeout(() => onLoad(paymentIntentId, callback), delay);
        }, [paymentIntentId, callback, delay]);

        useOnLoad(delayedFetch, Boolean(paymentIntentId), [paymentIntentId, remainingRetries]);

        return {
            transactionId,
            loading
        }
    }
)