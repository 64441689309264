import withPlatformMaintainerOnly from 'modules/Auth/higher-order-components/withPlatformMaintainerOnly';
import { removeEntity } from 'modules/Campaigns/state/cells';
import Button from 'modules/Core/components/ui-kit/Button';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import useOnSuccess from 'modules/Core/sub-modules/Dialog/hooks/useOnSuccess';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const copy = {
    title: 'Are you sure you want to delete this campaign?',

};

const StrappedComponent = strappedConnected(Button,
    {},
    {
        removeCampaign: (id, callback) => removeEntity.action({
            id, callback
        })
    },
    ({ campaignId, removeCampaign }) => {
        const onSuccess = useOnSuccess();
        const navigate = useNavigate();
        const callback = useCallback(() => {
            onSuccess('Campaign has been removed!');
            navigate('/campaigns/feed');
        }, [onSuccess, navigate])
        return {
            text: 'Delete Campaign',
            widget: true,
            modal: true,
            onConfirm: () => removeCampaign(campaignId, callback),
            copy
        }
    })

export default withPlatformMaintainerOnly(StrappedComponent)