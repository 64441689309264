import Screen from "modules/Core/components/ui-kit/Screen";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { createEntity } from "../../../state/cells";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import useOnError from "modules/Core/sub-modules/Dialog/hooks/useOnError";
import { useCallback } from "react";
import LoadingScreen from "modules/Core/components/ui-kit/Screen/LoadingScreen";

const RegisterPartnerFinancialsAccount = () => {
    return <LoadingScreen />
};

export default strappedConnected(
    RegisterPartnerFinancialsAccount,
    {},
    { onLoad: (partnerId, callback) => createEntity.action({ partnerId, callback }) },
    ({ partnerId, onLoad, }) => {
        const onError = useOnError();
        const callback = useCallback((result) => {
            const url = result?.registerPeerPartnerFinancialsAccount?.url;
            if(!url) return onError("Failed to create partner financials account");
            window.location.href = url;
        }, [])
        useOnLoad(() => onLoad(partnerId, callback), Boolean(partnerId), [partnerId])
    }
);