import { useMemo } from 'react';
import CenteredCardScreen from 'modules/Core/components/ui-kit/Screen/CenteredCardScreen';
import PaymentCard from '../PaymentCard';
import strapped from 'modules/Core/higher-order-components/strapped';
import styles from './styles.module.scss';

const paymentCardAmountProps = {
    nextButtonText: 'Enter Card Details',
    label: 'How much would you like to donate?',
    className: styles.amountContainer,
    placeholder: "Enter Amount"
};

const paymentContentProps = {
    className: styles.paymentContent,
}

export default strapped(
    CenteredCardScreen,
    ({
        campaignId,
        partnerId,
        transactionKind,
        tithinOptions,
        header = 'Donate',
        ...props
    }) => {
        const children = useMemo(() => <PaymentCard
            campaignId={campaignId}
            partnerId={partnerId}
            transactionKind={transactionKind}
            tithinOptions={tithinOptions}
            className={styles.paymentCard}
            paymentCardAmountProps={paymentCardAmountProps}
            paymentContentProps={paymentContentProps}
            shouldRedirectOnSuccess
            headerless

        />, [
            campaignId,
            partnerId,
            transactionKind,
            tithinOptions,
            paymentCardAmountProps,
            paymentContentProps
        ])
        return {
            children,
            header,
        }
    }
);