import Card from "modules/Core/components/ui-kit/Card";
import ActiveObjectivesFeed from "modules/Objectives/components/ObjectivesFeed/ActiveObjectivesFeed";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import fetchActiveObjectivesFeed from "modules/Objectives/state/cells/fetchActiveObjectivesFeed";
import ActiveObjectiveDrawer from "../ActiveObjectiveDrawer";
import FeedItem from "modules/Core/components/Feed/FeedItem";
import useFocusedId from "modules/Core/hooks/useFocusedId";
import styles from "./styles.module.scss";

const ItemComponent = ({ name, text, ...props }) => <FeedItem name={name} {...props} text={null} />
const CurrentObjectivesCard = ({
    className,
    campaignId,
    focus,
    focusedId,
    unFocus,
    isEmpty,
    Container = Card,
    ...props

}) => (
    <Container
        header={"Current Objectives"}
        className={className}
        {...props}
    >
        <ActiveObjectivesFeed
            campaignId={campaignId}
            ItemComponent={ItemComponent}
            onClick={focus}
            feedItemClassName={styles.item}
        />
        <ActiveObjectiveDrawer
            id={focusedId}
            unFocus={unFocus}
        />
    </Container>
);

export default strappedConnected(
    CurrentObjectivesCard,
    { activeCount: fetchActiveObjectivesFeed.selectors.getFeedCount },
    {},
    ({ activeCount }) => {
        const { focusedId, focus, unFocus } = useFocusedId();
        return {
            focus,
            focusedId,
            unFocus,
            isEmpty: !Boolean(activeCount),
        }
    }
);