import { useMemo, useState } from "react";
import cx from "classnames";
import Card from "modules/Core/components/ui-kit/Card";
import { TRANSACTION_KEYS } from "modules/Payments/consts";
import PaymentCardAmountInput from "./PaymentCardAmountInput";
import PaymentContent from "./PaymentContent";
import useTabs from "modules/Core/hooks/useTabs";
import { compactObject } from "modules/Core/utils/obj";
import { useSelector } from "react-redux";
import { fetchEntity } from "modules/Budgets/state/cells";
import PaymentSuccess from "./PaymentSuccess";
import strapped from "modules/Core/higher-order-components/strapped";
import styles from "./styles.module.scss";


const tabs = [
    { Component: ({ paymentCardAmountProps, ...props }) => <PaymentCardAmountInput {...props} {...paymentCardAmountProps} /> },
    {
        Component: ({ next, onSuccess, setSuccessfulPaymentIntentId, paymentContentProps, ...props }) => (
            <PaymentContent
                {...props}
                {...paymentContentProps}
                onSuccess={(paymentIntent) => {
                    next();
                    onSuccess?.(paymentIntent);
                    setSuccessfulPaymentIntentId(paymentIntent.id);
                }}
            />)
    },
    {
        Component: ({ successfulPaymentIntentId, shouldRedirect, args }) => <PaymentSuccess partnerId={args?.partnerId} campaignId={args?.campaignId} shouldRedirect={shouldRedirect} paymentIntentId={successfulPaymentIntentId} hideReceipt={Boolean(args?.partnerId)} />
    }
];

export default strapped(
    Card,
    ({
        budgetId,
        campaignId,
        partnerId,
        transactionKind,
        tithingOptions,
        businessId,
        shouldRedirectOnSuccess,
        className,
        headerless,
        paymentCardAmountProps,
        paymentContentProps,
    }) => {
        const [amount, setAmount] = useState(null);
        const [tithe, setTithe] = useState(false);
        const [successfulPaymentIntentId, setSuccessfulPaymentIntentId] = useState(null);
        const budgetName = useSelector(fetchEntity.selectField(budgetId, 'name'));
        const args = useMemo(() => compactObject({
            budgetId,
            businessPartnerId: businessId,
            campaignId,
            transactionKind: transactionKind ?? (campaignId ? TRANSACTION_KEYS.CAMPAIGN_DONATION : TRANSACTION_KEYS.BUDGET_DONATION),
            partnerId,
            tithe,
        }), [budgetId, businessId, campaignId, transactionKind, partnerId, tithe]);

        const props = useMemo(() => ({
            amount,
            setAmount,
            args,
            businessId,
            tithe,
            setTithe,
            tithingOptions,
            setSuccessfulPaymentIntentId,
            successfulPaymentIntentId,
            shouldRedirect: shouldRedirectOnSuccess,
            paymentCardAmountProps,
            paymentContentProps
        }), [amount, setAmount, paymentContentProps, paymentCardAmountProps, shouldRedirectOnSuccess, successfulPaymentIntentId, setSuccessfulPaymentIntentId, args, businessId, tithe, setTithe, tithingOptions])


        const { content, next } = useTabs(tabs, props, { header: false });
        const header = useMemo(() => !headerless && (`${budgetId && budgetName ? `'${budgetName}' ` : "P4P"} Donation`), [budgetName, headerless])
        return {
            children: content,
            shadow: true,
            className: cx(styles.container, className),
            header
        }
    }
);