import middleware from "modules/Budgets/middleware";
import FeedModule from "modules/Core/core-modules/FeedModule";
import { P4P } from "modules/P4P/consts";

const {
    create, 
    fetchFeed,
    fetchEntity
} = middleware.ops;

export default new FeedModule({ 
    name: P4P,  
    cellOptions: {
        createEntityCell: {
            requestHandler: create
        },
        fetchFeedCell: {
            requestHandler: fetchFeed
        }, 
        fetchEntityCell: {
            requestHandler: fetchEntity
        }
    }
});