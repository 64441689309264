import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  LOCATIONS_QUERY,
  parseLocationsResult,
} from "modules/Core/components/form/schema/locations";
import {
  parseTagsResult,
  TAGS_QUERY,
} from "modules/Core/components/form/schema/tags";
import Link from "modules/Core/components/ui-kit/Link";
import TeamMemberField from "./TeamMemberField";
import authorizeToken from "modules/Auth/state/cells/authorizeToken";
import withPreloadValue from "modules/Core/higher-order-components/withPreloadValue";

const useLogic = () => {
  const userInformation = useSelector(
    authorizeToken.selectors.getUserInformation
  );
  const userId = useSelector(authorizeToken.selectors.getUserId);
  return useMemo(
    () =>
      userId && {
        userId,
        firstName: userInformation?.firstName,
        lastName: userInformation?.lastName,
        bio: userInformation?.bio,
        photo: userInformation?.photo,
        isOneself: true,
      },
    [userId]
  );
};

export default {
  fields: {
    name: {
      label: "Organisation Name",
      validations: {
        minLength: 3,
        required: true,
      },
    },
    bio: {
      type: "textarea",
      label: "Organisation Bio",
      validations: {
        required: true,
        validator: (value) =>
          value.length <= 324 &&
          `Minimum character length is 324 for Campaign Description (${value.length}/324).`,
        readableKey: "Bio",
      },
    },
    image: {
      type: "image",
      label: "Organisation's Image",
    },
    aims: {
      type: "array",
      label: "Organisation's Aims",
      submitButtonText: "Add Aim",
      fields: {
        name: {
          type: "string",
          label: "Aim",
        },
        description: {
          type: "textarea",
          label: "Aim Description",
        },
      },
    },
    background: {
      type: "textarea",
      label: "Organisation's Background",
      rows: 18,
    },
    mission: {
      type: "textarea",
      label: "Mission Statement",
      rows: 7,
    },
    location: {
      type: "async-select",
      label: "Location",
      searchable: true,
      query: LOCATIONS_QUERY,
      parseResult: parseLocationsResult,
    },
    tags: {
      type: "async-select",
      label: "Tags",
      searchable: true,
      query: TAGS_QUERY,
      parseResult: parseTagsResult,
    },
    contactInformation: {
      type: "object",
      label: "Contact Information",
      fields: {
        fullName: {
          type: "string",
          label: "Contact Name",
        },
        email: {
          type: "string",
          label: "Email",
        },
        phoneNumber: {
          type: "phone-number",
          label: "Phone",
        },
      },
    },
    website: {
      label: "Website",
    },
    links: {
      label: "Additional Links",
      type: "array",
      required: true,
      submitButtonText: "Add Link",
      fields: {
        name: {
          label: "Link Name",
          type: "text",
          required: true,
          emptyValue: "",
          cardless: true,
        },
        url: {
          label: "Link URL",
          type: "text",
          required: true,
          emptyValue: "",
          cardless: true,
        },
      },
      renderValueItem: (item, index) => (
        <Link small card key={index} link={item} />
      ),
    },
    members: {
      label: "Organisation Members",
      buttonLabel: "Add Member",
      Component: withPreloadValue(
        TeamMemberField,
        useLogic,
        (val, users = []) => [val, ...(users ?? [])]
      ),
      submitButtonText: "Add Member",
    },
  },
  success: {
    text: "Your application is pending P4P approval!",
  },
};
