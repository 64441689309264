import Drawer from "modules/Core/components/ui-kit/Drawer";
import ViewObjectiveDetails from "../ViewObjectiveDetails";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { fetchEntity } from "modules/Objectives/state/cells";
import ObjectiveDrawerHeader from "./ObjectiveDrawerHeader";


const ObjectiveDrawer = ({ objectiveId, unFocus, header, stamps, ...props }) => (
    <Drawer
        isOpen={Boolean(objectiveId)}
        close={unFocus}
        headerChildren={
            <ObjectiveDrawerHeader header={header} stamps={stamps}/>
        }
        {...props}
    >
        <ViewObjectiveDetails id={objectiveId} shouldRenderHeader={false} />
    </Drawer>
)

export default strappedConnected(
    ObjectiveDrawer,
    {
        header: (state, props) => fetchEntity.selectField(props?.objectiveId, 'name')(state),
        stamps: (state, props) => fetchEntity.selectField(props?.objectiveId, 'stamps')(state),
    },
    {},
);