import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import withMaintainerOnly from "modules/Campaigns/higher-order-components/withMaintainerOnly";
import { useIsOpen } from "modules/Core/hooks/useIsOpen";
import DeleteAction from "modules/Posts/components/PostActions/DeleteAction/DeleteAction.component";
import removeComment from "../../../state/cells/removeComment";

const StrappedComponent = strappedConnected(
    DeleteAction,
    null,
    { onSubmit: removeComment.action },
    ({ entityName = "comment" }) => ({
        ...useIsOpen(),
        entityName
    })
);

export default withMaintainerOnly(StrappedComponent);