import cx from 'classnames';
import Widget from 'modules/Core/components/ui-kit/Widget';
import DonationPanel from '../DonationPanel';
import styles from './styles.module.scss';

const DonationsWidget = ({ className, donationProps, ...props }) => (
    <Widget
        className={cx(styles.container, className)}
        label={"Donate to P4P"}
        // userGuideKey={'FUNDRAISING.PLATFORM_DONATION'}
        {...props}
    >
        <DonationPanel className={styles.donationsPanel} {...donationProps} />
    </Widget>
);

export default DonationsWidget;
