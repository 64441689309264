import { gql } from "graphql-request";
import { graphqlClient } from "modules/Core/middleware";

const setJudgementMutation = gql`
  mutation CreateJudgement($input: JudgementInput!) {
    entity: createJudgement(input: $input) {
      id
      name
      background
      policies {
        id
        name
        description
      }
      openingTime
      closingTime
    }
  }
`;

const fetchJudgementsQuery = gql`
  query Judgements($feedParams: FeedParams) {
    feed: judgements(feedParams: $feedParams) {
      entities {
        id
        name
        background
        openingTime
        closingTime
      }
    }
  }
`;

const fetchEntityQuery = gql`
  query getJudgement($id: String) {
    entity: judgement(id: $id) {
      name
      id
      openingTime
      closingTime
      background
      policies {
        id
        name
        description
        isVoted
      }
    }
  }
`;
const votePolicyMutation = gql`
  mutation votePolicy($id: String!) {
    vote(policyId: $id) {
      policy
      id
    }
  }
`;

export const setJudgement = async ({ input }) =>
  graphqlClient.request(setJudgementMutation, { input });

export const fetchJudgements = async () =>
  graphqlClient.request(fetchJudgementsQuery);

export const fetchJudgement = (id) =>
  graphqlClient.request(fetchEntityQuery, { id });

export const votePolicy = async ({ id }) => {
  return graphqlClient.request(votePolicyMutation, {
    id,
  });
};
