import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Container from "modules/Core/components/ui-kit/Container";
import feed from "modules/Partners/submodules/campaign-partnership-invitations/state/feed"
import strapped from "modules/Core/higher-order-components/strapped";
import FeedItem from "modules/Core/components/Feed/FeedItem";
import Stamp from "modules/Core/components/ui-kit/Stamp";
import { CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS } from "../../consts";
import styles from "./styles.module.scss";

const { FeedComponent } = feed;

const FeedItemComponent = strapped(
    FeedItem,
    ({ viewingAs = CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS.PARTNER, variables: _variables, ...item }) => {
        const navigate = useNavigate()
        const stamp = useMemo(() => {
            const variables = JSON.parse(_variables);
            let label;
            if (viewingAs === CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS.PARTNER) {
                label = variables?.sentBy === CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS.PARTNER ? 'Sent' : 'Received';
            }
            if (viewingAs === CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS.CAMPAIGN) {
                label = variables?.sentBy === CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS.PARTNER ? 'Received' : 'Sent';
            }
            return <Stamp className={styles.stamp} stamp={label} />
        }, [_variables]);
        return ({
            name: useMemo(() => viewingAs == CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS.PARTNER ? item?.campaign?.name : item?.partner?.name, [item?.campaign?.name, item?.partner?.name]),
            onClick: () => navigate(`/invitations/campaign-partnership/${item?.id}`),
            children: (
                <Container flex flexEnd>
                    {stamp}
                </Container>
            )
        })
    }
)

const CampaignPartnershipInvitationsFeed = (props) => (
    <Container>
        <FeedComponent
            {...props}
            ItemComponent={FeedItemComponent}
            feedItemClassName={styles.feedItem}
        />
    </Container>
);

export default strapped(
    CampaignPartnershipInvitationsFeed,
    ({ partnerId, campaignId }) => ({
        filters: useMemo(() => ({
            partnerId,
            campaignId
        }), [partnerId, campaignId]),
    })
);