import {fetchEntity} from "modules/Campaigns/state/cells"
import Container from "modules/Core/components/ui-kit/Container"
import RedirectButton from "modules/Core/components/ui-kit/RedirectButton"
import { useSelector } from "react-redux"
import CampaignName from "../CampaignName"
import styles from "./styles.module.scss"

const ParentCampaignCard = ({ id, }) => {
    const parent = useSelector(fetchEntity.selectField(id, "parent"))
    return parent && <ParentCampaignCard name={parent.name} id={parent.id} />
}

ParentCampaignCard.Component = ({ name, id }) => (
    <Container flex spaceBetween pHalf>
        <CampaignName.Component className={styles.name} name={name} Wrapper="h3" />
        <RedirectButton to={`/campaigns/${id}`}  >
            <span className="chevron-right-container"/>
        </RedirectButton>
    </Container>
)

export default ParentCampaignCard