import cx from "classnames";
import Container from "modules/Core/components/ui-kit/Container";
import Card from "modules/Core/components/ui-kit/Card";
import Text from "modules/Core/components/ui-kit/Text";
import Title from "modules/Core/components/ui-kit/Title"
import Header from "modules/Core/components/ui-kit/layout/Header";
import Footer from "modules/Core/components/ui-kit/layout/Footer";
import ConditionalContainer from "modules/Core/components/ui-kit/ConditionalContainer";
import styles from "./styles.module.scss";

const InvitationCard = ({ className, contentClassName, content, microcopy, footer, prePersonalisedMessage }) => (
    <Container className={cx(className, styles.container)}>
        <Header
            className={styles.header}
            title={microcopy.title}
            absolute
            lightShadow
        />
        <Title Element={"h3"} fs150 text={microcopy?.to} />
        <Container className={cx(styles.content, contentClassName)} maxHeight scroll>
            {prePersonalisedMessage}
            <Card
                className={styles.personalisedMessage}
                shouldRender={Boolean(microcopy.personalisedMessage)}
            >
                <Text italic >
                    "{microcopy.personalisedMessage}"
                </Text>
            </Card>
            {content}
            <ConditionalContainer shouldRender={Boolean(microcopy.kindRegards)} >
                <Text mb1 fs150>
                    Kind Regards,
                </Text>
                <Text mb3 fs150 italic bold>
                    {microcopy.kindRegards}
                </Text>
            </ConditionalContainer>
        </Container>
        <Footer className={styles.footer} relative>
            {footer}
        </Footer>
    </Container>
)
export default InvitationCard;