import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";    
import cells from "./state/cells";
import {BUDGET_ITEMS } from "./consts";
import routes from "./routes";

const BudgetItems = new CoreModule({
    name: BUDGET_ITEMS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default BudgetItems;