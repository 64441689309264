import CoreModule from "modules/Core/core-modules/CoreModule";
import { P4P as P4PConst } from "./consts";
import cells from "./state/cells";
import routes from "./routes";
import feed from "./state/feed";

const P4P = new CoreModule({
    name: P4PConst,
    initialState: { ...feed?.getInitialFeedState() },
    cells,
    routes,
});

export default P4P;