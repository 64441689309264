import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";    
import cells from "./state/cells";
import routes from "./routes";
import { CAMPAIGN_PARTNERSHIP_INVITATIONS } from "./consts";

const CampaignPartnershipInvitations = new CoreModule({
    name: CAMPAIGN_PARTNERSHIP_INVITATIONS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default CampaignPartnershipInvitations;