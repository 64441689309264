import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "../../middleware";
import { CAMPAIGN_PARTNERSHIP_INVITATIONS } from "../../consts";

const { fetchFeed, rsvp } = middleware.ops

export default new FeedModule({
    name: CAMPAIGN_PARTNERSHIP_INVITATIONS,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchFeed
        },
        createEntityCell: {
            requestHandler: rsvp
        }
    }
});