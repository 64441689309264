import ExternalScreen from "modules/Core/components/ui-kit/Screen/ExternalScreen"
import Title from "modules/Core/components/ui-kit/Title"
import Container from "modules/Core/components/ui-kit/Container"
import NodeNetwork from "modules/Core/components/screens/LandingPage/NodeNetwork"
import P4PMembersFeed from "modules/P4P/sub-modules/Team/components/P4PMembersFeed"
import styles from "./styles.module.scss"

const OurTeam = ({ }) => {
    return (
        <ExternalScreen className={styles.container}>
            <NodeNetwork background nodes={[]} />
            <Container className={styles.content}>
                <Title>P4P's Team</Title>
                <P4PMembersFeed />
            </Container>
        </ExternalScreen>
    )
}

export default OurTeam