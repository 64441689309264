import feed from "../feed";

export const {
    setFilters,
    fetchFeed,
    fetchEntity,
    createEntity,
} = feed.cells;

export default {
    fetchFeed,
    setFilters,
    createEntity,
    fetchEntity,
}