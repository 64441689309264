import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Button from 'modules/Core/components/ui-kit/Button';
import Input from 'modules/Core/components/ui-kit/Input';
import Container from 'modules/Core/components/ui-kit/Container';
import Title from 'modules/Core/components/ui-kit/Title';
import login from 'modules/Auth/state/cells/login';
import loginValidation from 'modules/Auth/state/cells/loginValidation';
import PhoneInput from 'modules/Core/components/form/PhoneInput';
import { validators } from 'modules/Core/utils/validators';
import { getExactLengthValidator, getRegexValidator } from 'modules/Core/utils/validators/string';
import { validatePhoneNumber } from 'modules/Core/utils/validators/phoneNumber';
import useOnError from 'modules/Core/sub-modules/Dialog/hooks/useOnError';
import styles from "./styles.module.scss";


export const useAuthForm = ({ onSuccess, onCodeSent }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isPhoneEntered, setIsPhoneEntered] = useState(false);

    const dispatch = useDispatch();
    const onError = useOnError();

    const handlePhoneSubmit = useCallback(() => {
        if (!validatePhoneNumber(phoneNumber)) return onError("Invalid phone number")
        if (phoneNumber) dispatch(login.action({
            phoneNumber,
            callback: (isSuccess) => isSuccess && setIsPhoneEntered(isSuccess),
        }))
    }, [phoneNumber, dispatch, onError]);

    useEffect(() => {
        if (isPhoneEntered) onCodeSent(phoneNumber);
    }, [isPhoneEntered, onCodeSent]);

    const handleVerificationSubmit = useCallback(() => {
        const isValid = validateVerificationCode(verificationCode);
        if (!isValid) return onError("Invalid verification code");
        dispatch(loginValidation.action({
            phoneNumber,
            code: verificationCode,
            callback: (isSuccess) => isSuccess && onSuccess()
        }))
    }, [verificationCode, phoneNumber, onSuccess, dispatch, onError]);

    const phoneField = useMemo(() => (
        <Container flex column >
            <PhoneInput
                className={styles.input}
                placeholder={"Phone Number"}
                value={phoneNumber}
                onChange={setPhoneNumber}
            />
        </Container>
    ), [phoneNumber, setPhoneNumber, handlePhoneSubmit]);

    const verificationField = useMemo(() => (
        <Container flex col>
            <Input
                className={styles.input}
                maxLength={7}
                value={verificationCode}
                onChange={setVerificationCode}
                placeholder={"Verification Code"}
            />
        </Container>
    ), [verificationCode, setVerificationCode]);

    return {
        phoneField,
        verificationField,
        isPhoneEntered,
        handlePhoneSubmit,
        handleVerificationSubmit,
        phoneNumber,
    }
}

const validateVerificationCode = validators(getExactLengthValidator(7), getRegexValidator(/^[0-9]*$/));
const AuthForm = ({ className, redirect = true, onCodeSent, ButtonComponent = Button, onSuccess: _onSuccess }) => {

    const navigate = useNavigate();
    const onSuccess = useCallback(() => (_onSuccess?.() ?? (redirect && navigate("/campaigns"))), [navigate, redirect]);
    const { phoneField, verificationField, isPhoneEntered, handlePhoneSubmit, handleVerificationSubmit } = useAuthForm({ onSuccess, onCodeSent });



    return (
        <>
            <Container className={(styles.container, className)} centeredCol>
                {!isPhoneEntered ? (
                    <Container flex column>
                        <Title text={"Phone Number"} className={styles.subtitle} />
                        {phoneField}
                        <ButtonComponent className={styles.button} onClick={handlePhoneSubmit}>Send Code</ButtonComponent>

                    </Container>
                ) : (
                    <Container flex column>
                        <Title text={"Verification Code"} className={styles.subtitle} />
                        {verificationField}
                        <ButtonComponent className={styles.button} onClick={handleVerificationSubmit}>Verify Code</ButtonComponent>
                    </Container>
                )}
            </Container>
        </>
    );

};

export default AuthForm;
