import ActionsFeed from "modules/Actions/components/ActionsFeed"
import Container from "modules/Core/components/ui-kit/Container";
import { useMemo } from "react"

const CampaignActionsFeed = ({ id, feedItemClassName }) => {
    const filters = useMemo(() => ({ campaignId: id }), [id])
    return (
        <Container>
            <ActionsFeed 
                feedItemClassName={feedItemClassName} 
                filters={filters} 
            />
        </Container>
    )
};

export default CampaignActionsFeed;