import Container from 'modules/Core/components/ui-kit/Container';
import Drawer from 'modules/Core/components/ui-kit/Drawer';
import WidgetButton from 'modules/Core/components/ui-kit/Widget/WidgetButton';
import withShouldRender from 'modules/Core/higher-order-components/withShouldRender';
import { useIsOpen } from 'modules/Core/hooks/useIsOpen';
import SendCampaignInvitationForm from 'modules/Partners/submodules/campaign-partnership-invitations/components/CampaignOrganisationPartnershipInvitation/SendCampaignInvitationForm';

const InvitationButton = ({ partnerId, gridRow, ...props }) => {
    const { isOpen, open, close } = useIsOpen();
    return (
        <Container style={{ gridRow }}>
            <WidgetButton {...props} onClick={open} text={'Send Campaign Partnership Invitation'} />
            <Drawer
                isOpen={isOpen}
                close={close}
                origin='right'
                header='Send Partnership Invitation To Campaign'
            >
                <SendCampaignInvitationForm
                    partnerId={partnerId}
                    onSuccess={close}
                />
            </Drawer>
        </Container>
    )
};



export default withShouldRender(InvitationButton, 'associated')