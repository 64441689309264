import { useMemo } from "react";
import useStripeNotary from "./useStripeNotary";
import { NOTARIES } from "modules/Payments/consts";

export default (notary, amount, options) => {
    const useNotaryHook = useMemo(() => {
        if (notary === NOTARIES.STRIPE) {
            return useStripeNotary
        }
    }, [notary, amount, options])
    return useNotaryHook(amount, options);
}