import { useCallback, useMemo } from "react";
import Button from "modules/Core/components/ui-kit/Button";
import ObjectivesFeed from "modules/Objectives/components/ObjectivesFeed";
import ConditionalContainer from "modules/Core/components/ui-kit/ConditionalContainer";
import ObjectiveItem from "../CurrentObjectivesCard/ObjectiveItem";
import useFeedSelection from "modules/Core/hooks/useFeedSelection";
import useStampObjectives from "modules/Objectives/hooks/useStampObjectives";
import { OBJECTIVE_STAMPS } from "modules/Objectives/consts";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import fetchActiveObjectivesFeed from "modules/Objectives/state/cells/fetchActiveObjectivesFeed";
import Container from "modules/Core/components/ui-kit/Container";
import { fetchFeed } from "modules/Objectives/state/cells";

const FeedItem = ({ stamps, ...props }) => <ObjectiveItem disabled={Boolean(stamps?.achieved ?? stamps?.commenced)} {...props} />
const ProspectiveObjectivesFeed = ({
    isOpen,
    close,
    campaignId,
    refetchActiveObjectives,
    refetchObjectives
}) => {
    const { FeedItemComponent, selected } = useFeedSelection(FeedItem);
    const stamp = useStampObjectives();

    const onActivate = useCallback(() => stamp(
        Object.keys(selected),
        OBJECTIVE_STAMPS.COMMENCED,
        () => {
            refetchActiveObjectives()
            refetchObjectives()
            close()
        }
    ), [selected, stamp, close, refetchActiveObjectives, refetchObjectives]);

    const hasSelected = useMemo(() => Object.keys(selected).length > 0, [selected]);

    return (
        <Container
            isOpen={isOpen}
            close={close}
            nature={"right"}
            header={"Activate Objectives"}
            maxHeight
            flex
            col
            spaceBetween
        >
            <ConditionalContainer shouldRender={isOpen && campaignId}>
                <ObjectivesFeed
                    campaignId={campaignId}
                    ItemComponent={FeedItemComponent}
                    prospective
                />
            </ConditionalContainer>
            <ConditionalContainer shouldRender={Boolean(hasSelected)} flex center alignCenter maxWidth>
                <Button onClick={onActivate}> Activate Selected </Button>
            </ConditionalContainer>
        </Container>
    )
};

export default strappedConnected(
    ProspectiveObjectivesFeed,
    null,
    {
        refetchActiveObjectives: () => fetchActiveObjectivesFeed.action({ renewStream: true }),
        refetchObjectives: () => fetchFeed.action({ renewStream: true })
    }
);