import { call, put } from 'redux-saga/effects'
import { loginUser } from 'modules/Auth/middleware';
import { initCell } from 'modules/Core/core-modules/CoreModuleState/utils/cells';
import { AUTH } from '../../consts'

const login = initCell(AUTH, {
    name: "login",
    selectors: {
        getIsLoading: (state) => state.isLoading
    },
    reducer: (state, payload) => {
        state.isLoading = true;
    },
    sagas: {
        latest: function* ({payload}) {
            const res = yield call(loginUser, payload?.phoneNumber)
            const isSuccess = Boolean(res?.login)
            if (isSuccess) yield put(login?.onSuccess(res))
            return isSuccess
        },
        onError: "Error sending validation code"
    },
    successCell: {
        reducer: (state, payload) => {
            state.isLoading = false;
        },
    }
});

export default login;
