import DemoScreen from "../components/screens/DemoScreen";
import MembershipScreen from "../components/screens/MembershipScreen";
import OurCommitmentScreen from "../components/screens/OurCommitment";
import OurTeam from "../components/screens/OurTeam";
import OurValuesScreen from "../components/screens/OurValues";
import OurWorkScreen from "../components/screens/OurWorkScreen";
import P4PDonateScreen from "../components/screens/P4PDonateScreen";
import P4PFundraising from "../components/screens/P4PFundraisingScreen";
import PartnershipScreen from "../components/screens/PartnershipScreen";

export default {
    "P4P/values": OurValuesScreen,
    "P4P/mission-statement": OurCommitmentScreen,
    "P4P/work": OurWorkScreen,
    "P4P/membership": MembershipScreen,
    "P4P/partnerships": PartnershipScreen,
    "P4P/team": OurTeam,
    "P4P/demo-session": DemoScreen,
    "P4P/fundraising": P4PFundraising,
    'P4P/fundraising/donate': P4PDonateScreen
};