import { parseInput } from 'modules/Campaigns/components/SetCampaignMembershipInfoModal';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';


export default ({ campaignId, onCreate, setCampaignMembershipInfo, userInfo, memberInfo }) => {
    const nav = useNavigate();
    const handleSubmit = useCallback(({ text, ...memberInfo }) => {
        onCreate({
            campaignId,
            text,
            callback: ({ id }) => {
                if (!id) return null;
                nav(`/campaigns/${campaignId}`)
            }
        })
        setCampaignMembershipInfo(campaignId, parseInput(memberInfo));

    }, [campaignId, onCreate, setCampaignMembershipInfo, nav]);
    return ({
        onSubmit: handleSubmit,
        initialState: useMemo(() => ({
            name: {
                firstName: memberInfo?.firstName ?? userInfo?.firstName,
                lastName: memberInfo?.lastName ?? userInfo?.lastName,
                anonymous: Boolean(!memberInfo?.firstName && !memberInfo?.lastName)
            },
            photo: memberInfo?.photo ?? userInfo?.photo,
            bio: memberInfo?.bio,
            text: '',
        }), [memberInfo?.firstName, userInfo?.firstName])
    })
}