import { useCallback } from "react";
import { useDispatch } from "react-redux";
import stampObjectives from "../state/cells/stampObjective";

export default () => {
    const dispatch = useDispatch();
    const stamp = useCallback((ids, stamp, callback) => dispatch(
        stampObjectives.action({
            ids,
            stamp,
            callback,
        }))
    )
    return stamp
}
