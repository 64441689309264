import withFormValidation from "modules/Core/higher-order-components/withFormValidation";
import EntityForm from "../../../../Core/components/form/EntityForm";
import { useCallback, useMemo, useState } from "react";
import Container from "modules/Core/components/ui-kit/Container";
import FileInput from "modules/Core/components/ui-kit/Input/FileInput";
import styles from "./styles.module.scss";
import ConditionalContainer from "modules/Core/components/ui-kit/ConditionalContainer";
import Attachment from "modules/Core/components/ui-kit/Attachment";

const validationSchema = {
    name: {
        required: true,
        minLength: 2,
        readableKey: "Title"
    },
    text: {
        required: true,
        minLength: 10,
        readableKey: "Text"
    }
};

const labelProps = {
    titleFieldProps: {
        placeholder: "Post Title",
    },
    textFieldProps: {
        placeholder: "Post Text",
        rows: 4
    },
};

const PostForm = ({ handleSubmit: _handleSubmit,  ...props }) => {
    const [attachments, setAttachments] = useState([]);

    const onAddAttachment = useCallback(({ type, name, filename }, base64) => {
        setAttachments([
            ...attachments,
            { type, file: base64, name }
        ])
    }, [attachments]);

    const attachmentsJSX = useMemo(() => {
        return attachments.map(({ type, file, name }, index) => type.includes("image") ? (<img key={index} src={file} alt="attachment" />) : (<Attachment key={index} file={file} name={name} />))
    }, [attachments]);

    const handleSubmit = useCallback((values) => {
        _handleSubmit({ ...values, attachments: attachments.map(({ file, type }) => ({ file, type })) })
    }, [_handleSubmit, attachments]);


    return (
        <EntityForm {...props} handleSubmit={handleSubmit} {...labelProps} >
            <ConditionalContainer maxWidth flex spaceBetween alignCenter>
                <Container className={styles.attachmentsValueContainer} flex row >
                    {attachmentsJSX}
                </Container>
                <FileInput
                    className={styles.attachment}
                    onChange={onAddAttachment}
                />
            </ConditionalContainer>
        </EntityForm>
    )
};

export default withFormValidation(PostForm, validationSchema)