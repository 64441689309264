import EditPost from "../index";
import EntityFormModal from "modules/Core/components/form/EntityFormModal";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";

const EditPostModal = ({ isOpen, close, postId }) => (
    <EntityFormModal 
        isOpen={isOpen} 
        close={close} 
        postId={postId}
        FormComponent={Form}
    />
)

const Form = withShouldRender(EditPost);

export default EditPostModal