import { call } from 'redux-saga/effects'
import { initCell } from '../../../Core/core-modules/CoreModuleState/utils/cells'
import { AUTH } from '../../consts'
import { setUserSettings } from 'modules/Auth/middleware';

export default initCell(AUTH, {
    name: "setUserSettings",
    reducer: (state) => {
        state.isLoading = true;
    },
    selectors: {
        settings: state => state.settings
    },
    sagas: {
        latest: function* ({ payload }) {
            const res = yield call(setUserSettings, payload?.input)
            return Boolean(res?.setUserSettings?.id)
        },
        onError: "Error Setting User Settings"
    },
});

