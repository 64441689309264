import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";    
import cells from "./state/cells";
import { CAMPAIGN_MAINTAINERS } from "./consts";
import routes from "./routes";

const CampaignMaintainers = new CoreModule({
    name: CAMPAIGN_MAINTAINERS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default CampaignMaintainers;