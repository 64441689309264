import login from "./login";
import authorizeToken from "./authorizeToken";
import loginValidation from "./loginValidation";
import { loading } from "./loading";
import setUserSettings from "./setUserSettings";
import setUserInformation from "./setUserInformation";
import setFormField from "./setFormField";
import clearForm from "./clearForm";

export default {
    login,
    authorizeToken,
    loginValidation,
    loading,
    setFormField,
    setUserSettings,
    setUserInformation,
    clearForm
}