import { useCallback } from 'react';
import useOnSuccess from 'modules/Core/sub-modules/Dialog/hooks/useOnSuccess';
import { useDispatch } from 'react-redux';
import { CAMPAIGN_PARTNERSHIP_INVITATIONS } from '../../consts';

export default ({ onSubmit, onSuccess, }) => {
    const success = useOnSuccess();
    const dispatch = useDispatch();
    return {
        onSubmit: useCallback(({ partnerId, campaignId, message, sentBy, }) => {
            onSubmit({
                campaignId,
                partnerId,
                message,
                sentBy,
                callback: () => {
                    success("Invitation Sent!")
                    onSuccess()
                    dispatch({
                        type: CAMPAIGN_PARTNERSHIP_INVITATIONS + "/fetchFeed",
                        payload: {
                            renewStream: true
                        }
                    })
                }
            })
        }, [onSubmit])
    }
}