import { useNavigate } from "react-router-dom";
import strapped from "modules/Core/higher-order-components/strapped";
import FeedItem from "modules/Core/components/Feed/FeedItem";
import PartnerIdentificationCard from "modules/Partners/components/PartnerIdentificationCard";
import styles from "./styles.module.scss";


export default strapped(
    (props) => (
        <FeedItem header={false} {...props}>
            <PartnerIdentificationCard
                image={props?.partner?.info?.image}
                name={props?.partner?.name}
                className={styles.partner}
            />
        </FeedItem>
    ),
    (item) => {
        const navigate = useNavigate()
        return ({
            onClick: () => navigate(`/partner/profile/${item?.partner?.id}`)
        })
    }
)