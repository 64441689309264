import Container from "modules/Core/components/ui-kit/Container";
import BudgetsFeed from "modules/Budgets/components/BudgetsFeed";
import BudgetDetails from "modules/Budgets/components/BudgetDetails";
import Drawer from "modules/Core/components/ui-kit/Drawer";
import styles from "./styles.module.scss";

const PlatformBudgetsFeed = ({
    focus,
    unFocus,
    focusedId,
    campaignId = null,
    ...props
}) => {
    
    return (
        <Container maxWidth relative p1>
            <BudgetsFeed
                {...props}
                onItemClick={focus}
                campaignId={campaignId}
            />
            <Drawer
                isOpen={Boolean(focusedId)}
                close={unFocus}
                backdrop={false}
                origin={'left'}
                className={styles.drawer}
                headerClassName={styles.header}
                headerChildren={"Budget Details"}
            >
                <BudgetDetails id={focusedId} />
            </Drawer>
        </Container>
    )
}

export default PlatformBudgetsFeed;