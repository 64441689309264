import cx from 'classnames';
import { compact } from "lodash";
import { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Container from "modules/Core/components/ui-kit/Container";
import strapped from "modules/Core/higher-order-components/strapped";
import { PARTNER_KINDS } from "modules/Partners/consts";
import feed from "modules/Partners/state/feed";
import IdentityCard from "modules/Core/components/ui-kit/IdentityCard";
import Text from "modules/Core/components/ui-kit/Text";
import Tag from "modules/Core/components/ui-kit/Tag";
import { Org } from 'modules/Core/components/ui-kit/indicators';
import styles from './styles.module.scss';

const FeedComponent = feed.FeedComponent;

const FeedItemComponent = ({ name, info, id, ...props }) => {
    const navigate = useNavigate();

    const onClick = useCallback(() => navigate(`/partner/profile/${id}?activeTab=info`), [navigate, id]);
    const locationTags = useMemo(() => Boolean(info?.location?.length) && compact([
        { label: info?.location?.[0]?.city, key: info?.location?.[0]?.countryCode },
        info?.location?.length > 1 && { label: `+${info?.location?.length - 1}`, key: 'more', className: styles.moreTag }
    ]), [info?.location]);

    const categoryTags = useMemo(() => Boolean(info?.tags?.length) && compact([
        { label: info?.tags?.[0]?.name, key: info?.tags?.[0]?.id },
        info?.tags?.length > 1 && { label: `+${info?.tags?.length - 1}`, key: 'more', className: styles.moreTag }
    ]), [info?.tags]);
    return (
        <IdentityCard onClick={onClick} className={styles.cardContainer} name={name} image={info?.image} DefaultImage={Org} >
            <Container className={styles.children} flex spaceBetween col maxHeight>
                <Text className={styles.vision}>{info?.bio}</Text>
                <Container className={styles.tagsContainer} flex flexEnd >
                    <Container flex alignCenter>
                        {Boolean(categoryTags) && categoryTags?.map(({ label, key, className }) => <Tag className={cx(styles.tag, className)} key={key}>{label}</Tag>)}
                    </Container>
                    <Container flex alignCenter >
                        {Boolean(locationTags) && locationTags?.map(({ label, key, className }) => <Tag className={cx(styles.tag, styles.locationTag, className)} grey key={key}>{label}</Tag>)}
                    </Container>
                </Container>
            </Container>
        </IdentityCard>
    )
}



const PartnersFeed = ({ filters, }) => (
    <Container>
        <Container className={styles.container}>
            <FeedComponent
                filters={filters}
                ItemComponent={FeedItemComponent}
                loadMoreClassName={styles.loadMore}
            />
        </Container>
    </Container>
);

const filters = { kind: PARTNER_KINDS.PEER }
export default strapped(PartnersFeed,
    ({ }) => ({
        filters
    })
);