import React, { useCallback, useMemo } from "react"
import UserInformationForm from "../../UserInformationForm";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import setUserInformation from "modules/Auth/state/cells/setUserInformation";
import NextButton from "modules/Core/components/ui-kit/Button/NextButton";
import authorizeToken from "modules/Auth/state/cells/authorizeToken";
import setUserSettings from "modules/Auth/state/cells/setUserSettings";
import useOnSuccess from "modules/Core/sub-modules/Dialog/hooks/useOnSuccess";
import CenteredCardScreen from "modules/Core/components/ui-kit/Screen/CenteredCardScreen";
import styles from "./styles.module.scss"
import Loader from "modules/Core/components/ui-kit/Loader";

const subheading = "Update your profile information here and your user settings"
const additionalFields = {
    displayUserGuide: {
        type: 'switch',
        label: 'Display User Guide',
        defaultValue: true,
        switch: true,
        className: styles.switchField
    }
}
const back = {
    text: 'Back',
    navigate: -2
}
const UserSettingsScreen = ({ onSubmit, initialState, isLoading }) => (
    <CenteredCardScreen
        cardHeader='User Settings'
        nextText='Next'
        className={styles.container}
        subHeader={subheading}
        header={'User Profile Settings'}
        back={back}
    >
        <Loader loading={isLoading}>
            <UserInformationForm
                className={styles.form}
                SubmitButton={NextButton}
                fullScreen={false}
                additionalFields={additionalFields}
                asyncInitialState={true}
                onSubmit={onSubmit}
                initialState={initialState}
                SuccessComponent={false}
                clearStateOnSUserSettingsScreenubmit={false}
            />
        </Loader>
    </CenteredCardScreen>
)


export default strappedConnected(
    UserSettingsScreen,
    {
        userInformation: authorizeToken?.selectors.getUserInformation,
        userSettings: setUserSettings?.selectors?.settings,
        isLoading: authorizeToken.selectors.getIsLoading
    },
    {
        setUserInformation: (form, callback) => setUserInformation.action({ input: form, callback }),
        setUserSettings: (form, callback) => setUserSettings.action({ input: form, callback })

    },
    ({ setUserInformation, setUserSettings, userInformation, userSettings }) => {
        const success = useOnSuccess()
        const settingsCallback = useCallback(() => success('Saved!'), [success])
        const onSubmit = useCallback(({ displayUserGuide, ...userInformation }, callback) => {
            setUserSettings({ displayUserGuide }, settingsCallback);
            setUserInformation(userInformation, callback);
        }, [setUserInformation, setUserSettings])
        return {
            onSubmit,
            initialState: useMemo(() => ({
                name: {
                    firstName: userInformation?.firstName,
                    lastName: userInformation?.lastName,
                },
                photo: userInformation?.photo,
                bio: userInformation?.bio,
                displayUserGuide: userSettings?.displayUserGuide,
            }), [userInformation, userSettings])
        }
    }
);