import { gql } from "graphql-request";
import { graphqlClient } from "modules/Core/middleware";

const fetchEntityQuery = gql`
    query invitation($id: String){
        invitation(id: $id){
            template
            variables
            id
            stamps {
                custom {
                    accepted {
                        time
                    }
                    declined {
                        time
                    }
                }
            }
        }
    }
`;

const fetchFeedQuery = gql`
    query invitations($feedParams: FeedParams){
        feed: invitations(feedParams: $feedParams){
            entities {
                id
                template
                variables
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`;

const createEntityMutation = gql`
    mutation sendInvitation($template: String, $variables: String){
        setInvitation(template: $template, variables: $variables)
    }
`

export const fetchInvitation = (id) => graphqlClient.request(fetchEntityQuery, { id })

export const fetchInvitations = (feedParams) => graphqlClient.request(fetchFeedQuery, feedParams);

export const createInvitation = (variables) => graphqlClient.request(createEntityMutation, variables);