import FeedItem from "modules/Core/components/Feed/FeedItem";
import feed from "../../state/feed/index"

const { FeedComponent } = feed;

const CampaignDonationsFeed = ({ campaignId }) => (
    <FeedComponent 
        filters={{ campaignId }}
        ItemComponent={(item) => <FeedItem {...item} name={`Donation (${item?.price} GBP) `} />}
    />
);

export default CampaignDonationsFeed;