import strapped from "modules/Core/higher-order-components/strapped";
import useFocusedId from "modules/Core/hooks/useFocusedId";
import SplitScreen from "modules/Core/components/ui-kit/SplitScreen";
import panels from "./panels";
import styles from "./styles.module.scss";
import BusinessPartnershipsBanner from "modules/Partners/submodules/business-partnerships/components/BusinessPartnershipsBanner";

const FinancialDonationPage = ({ commonPanelProps, header }) => (
    // Rename to platform donations page or something...
    <SplitScreen
        className={styles.container}
        panels={panels}
        commonPanelProps={commonPanelProps}
        hideHeader
        footer={
            <BusinessPartnershipsBanner />
        }
        footerClassName={styles.footer} 
    />
);

export default strapped(
    FinancialDonationPage,
    ({ campaignId , header}) => {
        const { focusedId, focus, unFocus } = useFocusedId();
        return {
            commonPanelProps: {
                focus,
                unFocus,
                focusedId,
                campaignId
            },
        }
    }
);
