import { useMemo } from "react";
import { useSelector } from "react-redux";
import c from 'classnames'
import Text from "modules/Core/components/ui-kit/Text";
import {fetchEntity} from "modules/Campaigns/state/cells";
import styles from "./styles.module.scss";

const CampaignVision = ({ id, adapter, ...props }) => {
    const name = useSelector(fetchEntity?.selectField(id, "vision"));
    const jsx = useMemo(() => adapter?.(name) ?? name, [name, adapter]);
    return name && <CampaignVision.Component name={jsx} {...props} />
}

CampaignVision.Component = ({ Wrapper = Text, name, className }) => (
    <Wrapper className={c(styles.vision, className)}>
        {name}
    </Wrapper>
)


export default CampaignVision;
