import React, { useEffect } from 'react';
import Loader from 'modules/Core/components/ui-kit/Loader';
import Container from 'modules/Core/components/ui-kit/Container';
import styles from './styles.module.scss';
import { deleteToken } from 'modules/Core/utils/auth';


const Logout = ({ }) => {
    useEffect(() => {
        deleteToken();
        window.location.replace('/');
    })
    return (
        <Container  
            className={styles.container}
            maxWidth flex col alignCenter justifyCenter
        >
            <Loader loading />
        </Container>
    )
}

export default Logout

