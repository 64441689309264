import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";    
import cells from "./state/cells";
import routes from "./routes";
import { CAMPAIGN_FINANCIALS } from "./consts";

const CampaignFinancials = new CoreModule({
    name: CAMPAIGN_FINANCIALS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default CampaignFinancials;
