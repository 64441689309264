import { useMemo } from 'react';
import Container from 'modules/Core/components/ui-kit/Container';
import StampCampaignApplicationButton from '../StampCampaignApplicationButton';
import Features from 'modules/Core/components/ui-kit/Features';
import { ExpandableIDFeedItem } from 'modules/Campaigns/sub-modules/campaign-maintainers/components/MaintainersFeedItem';
import styles from './styles.module.scss';

const MaintainerApplicationsFeedItem = ({ id, name, membership, text, ...props }) => {
    const _name = useMemo(() => Boolean(membership?.information?.firstName) ? `${membership?.information?.firstName} ${membership?.information?.lastName}` : name ?? "Anonymous User", [membership, name]);

    return (
        <ExpandableIDFeedItem
            name={_name}
            photo={membership?.information?.photo}
            {...props}
        >
            <Container p1>
                <Features
                    row
                    className={styles.features}
                    features={[
                        {
                            name: 'Member Bio',
                            content: membership?.information?.bio
                        },
                        {
                            name: 'Additional Application Notes',
                            content: text
                        }
                    ]}
                />
            </Container>
            <Container flex flexEnd mt3>
                <StampCampaignApplicationButton
                    applicationId={id}
                    stamp={{ approved: true }}
                    text={"Approve"}
                />
                <StampCampaignApplicationButton
                    applicationId={id}
                    stamp={{ rejected: true }}
                    text={"Reject"}
                />
            </Container>
        </ExpandableIDFeedItem>
    )
};

export default MaintainerApplicationsFeedItem