import { useCallback, useMemo, useState } from "react";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import { cpi } from "modules/Payments/middleware";
import NotariesWrapper from "modules/Payments/components/NotariesWrapper";
import StripePayment from "modules/Payments/components/notary-components/StripePayment";

export default (amount, options) => {
    const [clientSecret, setClientSecret] = useState(null);

    const onLoad = useCallback(async () => {
        if (options?.clientSecret) return setClientSecret(options.clientSecret);
        if (!amount) return;
        const params = { amount: parseFloat(amount) };
        if (Boolean(options?.args)) Object.assign(params, options.args);
        const result = await cpi(params);
        setClientSecret(result.paymentIntent.clientSecret);
    }, [amount, options?.args, options?.clientSecret]);

    useOnLoad(onLoad);


    const children = useMemo(() => clientSecret && (
        <NotariesWrapper clientSecret={clientSecret}>
            <StripePayment
                clientSecret={clientSecret}
                options={options}
                amount={amount}
            />
        </NotariesWrapper>
    ), [amount, options, clientSecret]);

    return {
        jsx: children
    }
}
