import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";
import { ACTIONS } from "./consts";
import cells from "./state/cells";

const Actions = new CoreModule({
    name: ACTIONS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
});

export default Actions;