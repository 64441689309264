import { initCell } from "modules/Core/core-modules/CoreModuleState/utils/cells"
import { CAMPAIGN_MAINTAINER_APPLICATIONS } from "../consts";
import { call, put } from "redux-saga/effects";
import { onError, onSuccess } from "modules/Core/sub-modules/Dialog/state/cells";
import { stampMaintainerApplication } from "../middleware/stamp";

const stampApplicationCell = initCell(
    CAMPAIGN_MAINTAINER_APPLICATIONS,
    {
        name: 'stampEntity',
        sagas: {
            latest: function* ({ payload }) {
                const result = yield call(
                    stampMaintainerApplication,
                    {
                        id: payload.id, 
                        approved: payload?.approved, 
                        rejected: payload?.rejected
                    }
                )
                if (result?.stampCampaignMaintainerApplication) {
                    const handler = payload?.approved ? onSuccess : onError;
                    yield put(handler("Application " + (payload?.approved ? 'Approved' : 'Rejected')))
                    yield put({
                        type: CAMPAIGN_MAINTAINER_APPLICATIONS + '/fetchFeed',
                        payload: {
                            renewStream: true
                        }
                    })
                } else return false
                return { payload }
            }
        }
    }
);

export default stampApplicationCell