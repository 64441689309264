import Container from 'modules/Core/components/ui-kit/Container';
import Link from 'modules/Core/components/ui-kit/Link';
import styles from './styles.module.scss';


const UnauthenticatedOptions = () => {
    return (
        <Container maxHeight flex center alignCenter className={styles.unauthenticatedContainer}>
            <Link to={"/register"} text={"Register"} />
            <Container maxHeight bl1 />
            <Link to={"/login"} text={"Login"} />
        </ Container>
    )
}

export default UnauthenticatedOptions;