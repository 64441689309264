export const schemaFields = {
    name: {
        required: true,
        minLength: 2,
        label: "Title"
    },
    text: {
        required: true,
        minLength: 72,
        label: "Text",
        type: "textarea",
    },
    photos: {
        type: "images",
        label: "Photos",
    }
}

export const schema = {
    fields: schemaFields
};


export const labelProps = {
    titleFieldProps: {
        placeholder: "Action Name",
    },
    textFieldProps: {
        placeholder: "Action Description",
        rows: 4,
    },
}