import IdentityCard from 'modules/Core/components/ui-kit/IdentityCard';
import styles from './styles.module.scss';

const PartnerCard = ({ name, image, ...props}) => (
    <IdentityCard 
        name={name} 
        image={image} 
        className={styles.card} 
        {...props}
    />
)

export default PartnerCard;