import ExpandableFeedItem from "modules/Core/components/Feed/ExpandableFeedItem";
import feed from "../../state/feed";
import strapped from "modules/Core/higher-order-components/strapped";
import Container from "modules/Core/components/ui-kit/Container";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import styles from "./styles.module.scss";
import withMaintainerOnly from "modules/Campaigns/higher-order-components/withMaintainerOnly";
import EditActionButton from "../EditActionButton";
import CommentAction from "modules/Posts/components/PostActions/CommentAction";
import CommentsFeed from "modules/Posts/sub-modules/Comments/components/CommentsFeed";

const Actions = withMaintainerOnly(EditActionButton);
const CommentActions = withMaintainerOnly(CommentAction);
const Comments = withShouldRender(CommentsFeed);

export default strapped(feed.FeedComponent, null, {
  ItemComponent: (props) => {
    return (
      <ExpandableFeedItem contentClassName={styles.content} {...props} size="xlg" hideButtonOnOpen={false}>
        {/* COULD DO: recursively render photos as chilren with expandable photoitem */}
        <Container maxWidth mt1>
          {props?.photos?.map((src, i) => (
            <img className={styles.photo} src={src} key={i} />
          ))}
          <Container mb3 row maxWidth>
            <Comments actionId={props?.id} shouldRender={true} />
          </Container>

          <Container className={styles.actions} flex mb3 maxWidth>
            <CommentActions
              actionId={props?.id}
              shouldRender={true}
              {...props}
            />
            <Actions {...props} />
          </Container>
        </Container>
      </ExpandableFeedItem>
    );
  },
});
