import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { BUDGET_ITEMS } from "../consts";
import { gql } from "graphql-request";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";

const fetchFeed = gql`
    query fetchBudgetItems($feedParams: FeedParams, $budgetId: String) {
        feed: budgetItems(feedParams: $feedParams, budgetId: $budgetId) {
            entities {
                name
                id
                amount
                description
            }
            ${FEED_INFO_FRAGMENT}
        }
    }
`

const fetchEntity = `
    query budgetItem($id: String) {
        entity: budgetItem(id: $id) {
            id
            name
            description
            amount
            attachments {
                name
                attachment
                stamps {
                    created
                }
            }
        }
    }
`;

const uploadBudgetItemAttachment = `
    mutation uploadBudgetItemAttachment($budgetItemId: String, $input: BudgetItemAttachmentInput) {
        uploadBudgetItemAttachment(budgetItemId: $budgetItemId, input: $input) {
            name
        }
    }
`;

const operations = {
    fetchFeed,
    fetchEntity,
    uploadAttachment: uploadBudgetItemAttachment
}

export default new MiddlewareModule({
    name: BUDGET_ITEMS,
    operations,
    operationsConfig: {
        fetchEntity: {
            paramsParser: (id) => ({ id }),
        }
    }
});

