import Container from 'modules/Core/components/ui-kit/Container';
import CampaignActionsFeed from 'modules/Campaigns/components/CampaignActionsFeed';
import CreateActionButton from 'modules/Actions/components/CreateActionButton';
import withMaintainerOnly from 'modules/Campaigns/higher-order-components/withMaintainerOnly';
import styles from './styles.module.scss';

const CreateButton = withMaintainerOnly(CreateActionButton)

const CampaignActionsTab = ({ id, ...props }) => (
    <Container flex spaceBetween col maxHeight>
        <Container>
            <CampaignActionsFeed
                alignCenter
                flex
                column
                feedItemClassName={styles.feedItem}
                className={styles.feed}
                id={id}
                {...props}
            />
        </Container>
        <Container flex center maxWidth>
            <CreateButton additionalFields={{ campaign: id }} />
        </Container>
    </Container>
)

export default CampaignActionsTab;