import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";    
import cells from "./state/cells";
import {CAMPAIGN_MAINTAINER_APPLICATIONS } from "./consts";
import routes from "./routes";

const CampaignMaintainerApplications = new CoreModule({
    name: CAMPAIGN_MAINTAINER_APPLICATIONS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default CampaignMaintainerApplications;