import { useMemo } from 'react';
import feed from '../../state/feed';
import strapped from 'modules/Core/higher-order-components/strapped';
import MaintainersFeedItem from '../MaintainersFeedItem';

export default strapped(
    feed.FeedComponent,
    ({ campaignId }) => ({
        filters: useMemo(() => ({ campaignId }), [campaignId]),
        ItemComponent: MaintainersFeedItem
    })
);