import feed from "../feed";
import fetchFeedData from "./fetchFeedData";

export const {
    createEntity,
    fetchFeed,
    fetchEntity,
    setFilters
} = feed.cells;

export default {
    createEntity,
    fetchFeed,
    fetchEntity,
    fetchFeedData,
    setFilters
}