import { useCallback } from "react";
import PostForm from "../forms/PostForm";
import { createEntity, fetchEntity, fetchFeed } from "modules/Posts/state/cells";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";

const selectors = { post: (state, { postId }) => fetchEntity.selector(postId)(state) }
const actions = {
    edit: (id, post, callback) => createEntity.action({ id, callback, ...post }),
    refetchFeed: (campaignId) => fetchFeed.action({ campaignId })
};

const useLogic = ({ postId, post, edit, refetchFeed, onSuccess }) => {
    const handleSubmit = useCallback((data) => {
        edit(postId,
            {
                ...data,
            },
            ({ id }) => {
                if (!id) return null;
                refetchFeed(post?.campaign);
                onSuccess();
            })

    }, [post, postId, edit, refetchFeed, onSuccess])
    return {
        entity: post,
        handleSubmit
    }
}

export default strappedConnected(
    PostForm,
    selectors,
    actions,
    useLogic
)