import { useCallback, useMemo } from 'react';
import { set } from 'modules/Core/utils/obj';
import Container from 'modules/Core/components/ui-kit/Container';
import Form from 'modules/Core/components/form/Form';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import _schema from "./schema"
import useLogic from '../useLogic';
import actions from '../actions';
import { CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS } from '../../../consts';
import styles from "./styles.module.scss";

const _SendCampaignInvitationForm = ({ onSubmit: _onSubmit, partnerId, onSuccess, ...props }) => {

    const schema = useMemo(() => {
        if (!partnerId) return false;
        set(_schema, 'fields.campaign.variables.partnerId', partnerId)
        return _schema
    }, [partnerId]);

    const onSubmit = useCallback(({ campaign, message, ...args }) => _onSubmit({
        campaignId: campaign,
        partnerId,
        message,
        sentBy: CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS.PARTNER,
    }), [partnerId, _onSubmit]);


    return schema && (
        <Container {...props}>
            <Form
                schema={schema}
                onSubmit={onSubmit}
                className={styles.form}
            />
        </Container>
    )
};

export default strappedConnected(
    _SendCampaignInvitationForm,
    null,
    actions,
    useLogic,
);