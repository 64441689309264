import { useMemo } from "react";
import CampaignsFeed from "modules/Campaigns/components/CampaignsFeed";
import Container from "modules/Core/components/ui-kit/Container";
import strapped from "modules/Core/higher-order-components/strapped";
import { ItemComponent } from "modules/Campaigns/components/screens/CampaignsFeed/panels/FeedPanel";
import useFocusedId from "modules/Core/hooks/useFocusedId";
import styles from "./styles.module.scss"

const PartnerCampaignsFeed = strapped(CampaignsFeed,
    ({ partnerId }) => {
        const { focus, unFocus, focusedId } = useFocusedId();
        
        const feedProps = useMemo(() => ({
            onClick: focus,
            ItemComponent,
            itemProps: { focusedId, unFocus, displayViewCampaign: true },
        }), [focusedId]);

        return ({
            filters: useMemo(() => ({ partnerId }), [partnerId]),
            feedItemClassName: styles.feedItem,
            feedProps
        })
    }
);


const PartnerCampaignsTab = ({ partnerId }) => (
    <Container className={styles.container}>
        <PartnerCampaignsFeed partnerId={partnerId} />
    </Container>
);

export default PartnerCampaignsTab;

