import feed from "../feed";

export const {
    fetchFeed,
    setFilters
} = feed.cells;

export default {
    fetchFeed,
    setFilters,
}