import { fetchEntity } from 'modules/Campaigns/state/cells';
import Widget from 'modules/Core/components/ui-kit/Widget';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import PartnerIdentificationCard from 'modules/Partners/components/PartnerIdentificationCard';
import Text from 'modules/Core/components/ui-kit/Text';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const CampaignFoundingPartnerWidget = ({ partner, onClick, ...props }) => partner && (
    <Widget className={styles.container} card {...props}>
        <PartnerIdentificationCard
            className={styles.card}
            name={partner?.name}
            image={partner?.info?.image}
            onClick={onClick}
        >
            <Text>Campaign Organiser</Text>
        </PartnerIdentificationCard>
    </Widget>
);

export default strappedConnected(
    CampaignFoundingPartnerWidget,
    {
        partner: (state, { campaignId }) => fetchEntity.selectField(campaignId, 'foundingPartner')(state)
    },
    {},
    ({ partner }) => {
        const navigate = useNavigate();

        return {
            onClick: useCallback(() => navigate(`/partner/profile/${partner?.id}`), [navigate, partner?.id])
        }
    }
)