import styles from './styles.module.scss';

export default {
    fields: {
        name: {
            type: 'object',
            className: styles.nameField,
            fields: {
                firstName: {
                    label: 'First Name',
                    type: 'string',
                },
                lastName: {
                    label: 'Last Name',
                    type: 'string',
                },
            }
        },
        photo: {
            label: 'Profile Picture',
            type: 'image',
        },
        bio: {
            label: 'Bio',
            type: 'textarea',
            rows: 6,
        }
    }
}