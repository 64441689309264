import Button from 'modules/Core/components/ui-kit/Button';
import Container from 'modules/Core/components/ui-kit/Container';
import Drawer from 'modules/Core/components/ui-kit/Drawer';
import { useIsOpen } from 'modules/Core/hooks/useIsOpen';
import CreateObjective from 'modules/Objectives/components/CreateObjective';
import styles from './styles.module.scss';


const ObjectivesActions = ({campaignId}) => {
    const { isOpen, open, close } = useIsOpen();
    return (
        <>
            <Button onClick={open}>
                Create Objective
            </Button>
            <Drawer
                header={"Create Objective"}
                close={close}
                isOpen={isOpen}
            >
                <Container className={styles.wrapper}>
                    <CreateObjective 
                        campaignId={campaignId} 
                        onSuccess={close} 
                        formContainerClassName={styles.formContainer}
                        className={styles.form}
                    />
                </Container>
            </Drawer>
        </>
    )
}
export default ObjectivesActions;