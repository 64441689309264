import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";
import cells from "./state/cells";
import routes from "./routes";
import { BUSINESS_PARTNERSHIPS } from "./consts";

const BusinessPartnerships = new CoreModule({
    name: BUSINESS_PARTNERSHIPS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default BusinessPartnerships;
