import cx from "classnames";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { fetchBalanceCell } from "modules/Partners/state/cells";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import Features from "modules/Core/components/ui-kit/Features";
import Container from "modules/Core/components/ui-kit/Container";
import Card from "modules/Core/components/ui-kit/Card";
import styles from "./styles.module.scss";

const PartnerBalanceCard = ({  balance, className,}) => (
    <Card mb1 mt1 borderless className={cx(styles.container, className)}>
        <Container>
            <Features
                features={[
                    { name: "Pending Payout", content: `£${balance?.amount ?? 0}` },
                    { name: "Pending Processing", content: `£${balance?.pendingAmount ?? 0}` },
                ]}
                row
            />
        </Container>
    </Card>
);

export default strappedConnected(
    PartnerBalanceCard,
    {
        balance: (state) => fetchBalanceCell.selector(state)
    },
    {
        onLoad: (partnerId) => fetchBalanceCell.action({ partnerId })
    },
    ({ partnerId, onLoad, balance, withdraw, isSuccess }) => {
        useOnLoad(() => onLoad(partnerId), Boolean(partnerId), [partnerId]);
        return {
            balance,
        };
    }
);
