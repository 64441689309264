import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { gql } from "graphql-request";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";
import { P4P_TEAM } from "../consts";


const fetchFeed = gql`
    query fetchP4PMember($feedParams: FeedParams) {
        feed: P4PMembers(feedParams: $feedParams) {
            entities {
                userInformation {
                    photo
                    firstName
                    lastName
                }
                bio
                id
            }
            ${FEED_INFO_FRAGMENT}
        }
    }
`;

const setP4PTeamMember = gql`
    mutation setP4PMember($id: String, $input:  P4PMemberInput) {
        setP4PMember(input: $input, id:$id) {
            id
        }
    }
`


const operations = {
    fetchFeed,
    setP4PTeamMember
}


export default new MiddlewareModule({
    name: P4P_TEAM,
    operations,
    operationsConfig: {
        fetchEntity: {
            paramsParser: (id) => ({ id }),
        }
    }
});

