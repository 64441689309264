
import Widget from "modules/Core/components/ui-kit/Widget"
import PartnerBalanceCard from "../../PartnerBalanceCard"
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";

const PartnerFinancialsBudget = ({partnerId, className, ...props}) => (
    <Widget label={'Pending Balance'} {...props}>
        <PartnerBalanceCard 
            partnerId={partnerId} 
            className={className} 
        />
    </Widget>
)

export default withShouldRender(PartnerFinancialsBudget, 'associated');