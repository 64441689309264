import { useMemo } from 'react';
import ExpandableCard from 'modules/Core/components/ui-kit/Card/ExpandableCard';
import Container from 'modules/Core/components/ui-kit/Container';
import Text from 'modules/Core/components/ui-kit/Text';
import IdentityCard from 'modules/Core/components/ui-kit/IdentityCard';
import styles from './styles.module.scss';

export const ExpandableIDFeedItem = ({ name, photo, children, className, ...props }) => (
    <ExpandableCard
        className={styles.container}
        label={
            <IdentityCard
                name={name}
                image={photo}
                className={styles.identityCard}
            />
        }
        openClassName={styles.open}
        {...props}
        size={"lg"}
    >
        {children}
    </ExpandableCard>
)

const MaintainersFeedItem = ({ text, name, id, information, ...props }) => {
    const label = useMemo(() => Boolean(information?.firstName) ? `${information?.firstName} ${information?.lastName}` : name ?? "Anonymous User", [information, name]);
    const content = useMemo(() => information?.bio ?? text, [text, information?.bio]);

    return (
        <ExpandableIDFeedItem
            name={label}
            photo={information?.photo}
            {...props}>
            <Container>
                <Text p1 text={content} />
            </Container>

        </ExpandableIDFeedItem>
    )
}

export default MaintainersFeedItem