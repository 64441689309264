import approvePerspectiveCampaign from "modules/Campaigns/state/cells/approvePerspectiveCampaign";
import PanelButton from "modules/Core/components/ui-kit/PanelButton";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";

export default strappedConnected(
    PanelButton,
    null,
    { onClick: approvePerspectiveCampaign.action },
    ({ onClick, id, callback }) => ({
        onClick: () => onClick({ id, callback, isPermitted: false }),
        text: "Don't Approve Campaign"
    })
)