import React from "react";
import Container from "modules/Core/components/ui-kit/Container";
import feed from "modules/Judgements/state/feed";

const FeedComponent = feed.FeedComponent;

const JudgementsFeed = ({ feedItemClassName, feedProps, ...props }) => {

  return (
    <Container relative {...props}>
      <FeedComponent
        redirectOnClickByIdRootUrl={"/judgement-cases/"}
        feedItemClassName={feedItemClassName}
        {...feedProps}
      />
    </Container>
  );
};

export default JudgementsFeed;
