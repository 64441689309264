import DetailsSection from "modules/Core/components/ui-kit/DetailsSection"
import ExpandableDetailsSet from "modules/Campaigns/components/ExpandableDetailsSet"
import Container from "modules/Core/components/ui-kit/Container"
import Title from "modules/Core/components/ui-kit/Title"
import ExpandableSubObjective from "./ExpandableSubObjective";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import { fetchEntity } from "modules/Objectives/state/cells";

const T = withShouldRender(Title);

const Component = ({ name, subObjectives, text, id, shouldRenderHeader }) => (
    <Container p1>
        <T shouldRender={shouldRenderHeader} title={name} />
        <DetailsSection title="Sub-Objectives" shouldRender={Boolean(subObjectives?.length)} bordered={false}>
            <ExpandableDetailsSet
                entities={subObjectives}
                size={"lg"}
                ExpandableComponent={ExpandableSubObjective}
            />
        </DetailsSection>
        <DetailsSection title={"Objective's Background"} bordered={false}>
            {text}
        </DetailsSection>
    </Container>
);

export default strappedConnected(
    Component,
    {
        entity: (state, { id }) => fetchEntity.selector(id)(state),
    },
    {
        fetchEntity: fetchEntity.action,
    },
    ({ entity, fetchEntity, id }) => {
        useOnLoad(() => fetchEntity({ id }), !entity?.text, [id]);
        return {
            name: entity?.name,
            subObjectives: entity?.subObjectives,
            text: entity?.text,
        }
    }
);