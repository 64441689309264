import Login from "../components/screens/Login";
import Logout from "../components/screens/Logout";
import Register from "../components/screens/Register";
import RegisterIndividualScreen from "../components/screens/Register/RegisterIndividual";
import RegisterPartnerScreen from "../components/screens/Register/RegisterPartner";
import UserSettingsScreen from "../components/screens/UserSettingsScreen";

export default {
    "login": Login,
    "register": Register, 
    "register/partner": RegisterPartnerScreen,
    'register/individual': RegisterIndividualScreen,
    "logout": Logout,
    "my-settings": UserSettingsScreen
}