import { gql } from "graphql-request";
import { graphqlClient } from "modules/Core/middleware";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";

const query = gql`
	query campaignMaintainerApplications($campaignId: String, $feedParams: FeedParams, $status: String, $prospective: Boolean) {
		feed: campaignMaintainerApplications(campaignId: $campaignId, feedParams: $feedParams, status: $status, prospective: $prospective)  {
			entities {
				id
				name
				text
				status
				membership {
					information {
						firstName
						lastName
						bio
						photo
					}
				}
			}
			${FEED_INFO_FRAGMENT}
		}
	}
`;

const createMaintainerApplication = gql`
  mutation createCampaignMaintainerApplication(
    $input: CampaignMaintainerApplicationInput!
  ) {
    entity: createCampaignMaintainerApplication(input: $input) {
      id
      name
    }
  }
`;

const setCampaignMaintainerApplicationStatus = gql`
  mutation setCampaignMaintainerApplicationStatus(
    $id: String!
    $status: String!
  ) {
    setCampaignMaintainerApplicationStatus(id: $id, status: $status) {
      name
      status
      id
    }
  }
`;

export const createCampaignMaintainerApplication = async (input) => {
  return graphqlClient.request(createMaintainerApplication, { input });
};

export const fetchMaintainerApplications = async (params) => {
  return graphqlClient.request(query, {
    prospective: true,
    ...params,
  });
};

export const setMaintainerApplicationStatus = async (id, status) => {
  return graphqlClient.request(setCampaignMaintainerApplicationStatus, {
    id,
    status,
  });
};
