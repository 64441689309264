import cx from "classnames"
import { useMemo } from "react";
import Container from "modules/Core/components/ui-kit/Container";
import { ExpandableCardComponent } from "modules/Core/components/ui-kit/Card/ExpandableCard";
import styles from "./styles.module.scss";

const ObjectiveItem = ({
    name,
    id,
    onClick: _onClick,
    children,
    className,
    selected,
    disabled
}) => {
    const onClick = useMemo(() => !disabled && (() => _onClick({ id })), [id, _onClick, disabled])
    return (
        <Container
            className={cx(
                styles.container,
                className,
                {
                    [styles.selected]: selected,
                    [styles.disabled]: disabled
                }
            )}
            onClick={onClick}
            flex column
        >
            <ExpandableCardComponent
                title={name}
                className={styles.expandableCard}
            >
                {children}
            </ExpandableCardComponent>
        </Container>
    )
};

export default ObjectiveItem;