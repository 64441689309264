import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { syncVerificationCell } from "../../../state/cells";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import { useCallback } from "react";
import useOnError from "modules/Core/sub-modules/Dialog/hooks/useOnError";
import useOnSuccess from "modules/Core/sub-modules/Dialog/hooks/useOnSuccess";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "modules/Core/components/ui-kit/Screen/LoadingScreen";

export default strappedConnected(
    LoadingScreen,
    {},
    { onLoad: (campaignId, callback) => syncVerificationCell.action({ campaignId, callback }) },
    ({ campaignId, onLoad, }) => {
        const onError = useOnError();
        const onSuccess = useOnSuccess();
        const navigate = useNavigate();

        const callback = useCallback((result) => {
            if (!result?.syncCampaignAccountVerification) return onError("Failed to successfully register connected account");
            onSuccess("Successfully registered connected account");
            navigate('/campaigns/' + campaignId)
        }, [campaignId])
        useOnLoad(() => onLoad(campaignId, callback), Boolean(campaignId), [campaignId, callback]);

        return {
            loading: true
        }
    }
);