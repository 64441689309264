import { useCallback, useState } from "react";
import useSchemaFormFields from "modules/Core/components/form/Form/hooks/useSchemaFormFields";
import PhoneNumberField from "modules/Core/components/form/PhoneNumberField";
import Button from "modules/Core/components/ui-kit/Button";
import { ExpandableCardComponent } from "modules/Core/components/ui-kit/Card/ExpandableCard";
import Container from "modules/Core/components/ui-kit/Container";
import { InputLabel } from "modules/Core/components/ui-kit/Input";
import useOnError from "modules/Core/sub-modules/Dialog/hooks/useOnError";
import { PartnerTeamMemberCard } from "../../PartnerMembers";
import styles from './styles.module.scss';

const schemaFields = {
    firstName: {
        type: "string",
        label: "First Name",
    },
    lastName: {
        type: "string",
        label: "Last Name",
    },
    photo: {
        type: "image",
        label: "Photo",
    },
    bio: {
        type: "textarea",
        label: "Bio",
    },
};

const TeamMemberField = ({ onChange, value }) => {
    const [openIndex, setOpenIndex] = useState(null);
    const { fields, state, setState } = useSchemaFormFields({ fields: schemaFields }, {}, { cardless: true })
    const onError = useOnError();
    const onPhoneNumberAdded = useCallback((value) => {
        if (!value?.id) return onError("User not found");
        setState(state => ({
            ...state,
            id: value?.id,
            firstName: value?.information?.firstName,
            lastName: value?.information?.lastName,
            photo: value?.information?.photo,
            bio: value?.information?.bio,
        }))
        toggle()
    }, [onError, setState]);


    const toggle = useCallback((index) => {
        const newIndex = openIndex === index ? null : index;
        setOpenIndex(newIndex);
    }, [openIndex, setOpenIndex]);

    const handleAddUser = useCallback(() => {
        onChange([...(value ?? []), state]);
        setState({});
    }, [onChange, value, state, setState]);

    return (
        <Container >
            <InputLabel label={"Members"} />

            <ExpandableCardComponent
                isOpen={openIndex === 0}
                toggle={() => toggle(0)}
                label={"Add P4P User via Phone-Number"}
                size={"lg"}
            >
                <PhoneNumberField onChange={(val) => onPhoneNumberAdded(val)} buttonLabel={'Add User'} manual />,

            </ExpandableCardComponent>
            <Container>
                {fields}
                <Container maxWidth flex flexEnd>
                    <Button onClick={handleAddUser}>Confirm Details</Button>
                </Container>
            </Container>
            <Container className={styles.valueContainer} flex alignCenter p1>
                {
                    value?.map((member, index) =>< PartnerTeamMemberCard key={index} {...member} /> )
                }
            </Container>
        </Container>
    );
}


export default TeamMemberField;