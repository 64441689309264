import Container from "modules/Core/components/ui-kit/Container";
import PartnerInfoFeatures from "modules/Partners/components/PartnerInfoFeatures";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import styles from "./styles.module.scss";

const PartnerInfoTab = ({ partnerId, ...props }) => (
    <Container className={styles.container} flex row alignCenter spaceBetween maxWidth>
        <PartnerInfoFeatures
            partnerId={partnerId}
        />
    </Container>
);

export default strappedConnected(PartnerInfoTab);

