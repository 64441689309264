import Container from "modules/Core/components/ui-kit/Container";
import ProgressBar from "modules/Core/components/ui-kit/ProgressBar";
import styles from "./styles.module.scss";
import Feature from "modules/Core/components/ui-kit/Feature";

const FundraisingProgressionGraphic = ({ amountRaised, target }) => (
    <Container relative >
        <ProgressBar
            size={target ? '10rem' : '12rem'}
            circle
            min={target && amountRaised}
            max={target}
        />
        <Feature
            content={Boolean(!target) ? 'Amount Raised' : 'Target'}
            name={'£' + (target ? target : `${amountRaised ?? 0}! `) }
            className={styles.feature}
            absolute flex center
            reverse
            col
        />
    </Container>
)

export default FundraisingProgressionGraphic;