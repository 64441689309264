import { useMemo } from 'react';
import PostsFeed from 'modules/Posts/components/PostsFeed';
import CampaignName from '../../CampaignName';
import Widget from 'modules/Core/components/ui-kit/Widget';
import styles from './styles.module.scss';

const adapter = name => `${name} posts`;
const CampaignPostsWidget = ({ campaignId, campaignName = 'For Women Rights', ...props }) => {
    const filters = useMemo(() => ({ campaignId }), [campaignId]);
    const title = useMemo(() => <CampaignName id={campaignId} adapter={adapter} />, [campaignName, campaignId]);
    return (
        <Widget title={title} defaultOpen={false} className={styles.container} {...props} >
            <PostsFeed 
                filters={filters} 
                feedItemClassName={styles.feedItem} 
                itemProps={{displayAttachments: false}}
                />
        </Widget>
    )
}

export default CampaignPostsWidget;