import Title from 'modules/Core/components/ui-kit/Title';
import CampaignPhoto from '../../CampaignPhoto';
import Container from 'modules/Core/components/ui-kit/Container';
import CampaignName from '../../CampaignName';
import CampaignVision from '../../CampaignVision';
import styles from './styles.module.scss';


const CampaignScreenMainContentHeader = ({ id }) => (
    <Container className={styles.header} maxWidth maxHeight alignCenter flex>
        <CampaignPhoto id={id} shouldFetch={true} className={styles.photo} />
        <Container>
            <CampaignName id={id} Wrapper={Title} shouldFetch={true} />
            <CampaignVision id={id} shouldFetch={true} />
        </Container>
    </Container>
);

export default CampaignScreenMainContentHeader;