import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";    
import cells from "./state/cells";
import { PROSPECTIVE_CAMPAIGNS } from "./consts";
import routes from "./routes";

const ProspectiveCampaigns = new CoreModule({
    name: PROSPECTIVE_CAMPAIGNS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default ProspectiveCampaigns;