import FeedModule from "modules/Core/core-modules/FeedModule";
import { COMMENTS } from "../../consts";
import { createComment, fetchComments } from "../../middleware";

export default new FeedModule({
    name: COMMENTS,
    cellOptions: {
        createEntityCell: {
            requestHandler: createComment,
            onErrorMessage: 'Failed to create comment'
        },
        fetchFeedCell: {
            requestHandler: fetchComments
        },
    }
});