import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";
import { CAMPAIGN_PARTNERSHIPS } from "../consts";

const fetchFeed = `
	query campaignPartnerships($campaignId: String, $feedParams: FeedParams) {
		feed: campaignPartnerships(campaignId: $campaignId, feedParams: $feedParams)  {
			entities {
				id
				partner {
					id
					name
					info {
						image
					}
				}
			}
			${FEED_INFO_FRAGMENT}
		}
	}
	
`;

export default new MiddlewareModule({
	name: CAMPAIGN_PARTNERSHIPS,
	operations: {
		fetchFeed,
	},
})
