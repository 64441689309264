import WidgetButton from 'modules/Core/components/ui-kit/Widget/WidgetButton';
import { useIsOpen } from 'modules/Core/hooks/useIsOpen';
import Drawer from 'modules/Core/components/ui-kit/Drawer';
import SendOrganisationInvitationForm from 'modules/Partners/submodules/campaign-partnership-invitations/components/CampaignOrganisationPartnershipInvitation/SendOrganisationInvitationForm';
import Container from 'modules/Core/components/ui-kit/Container';


const SendPartnershipInvitation = ({ id, campaignId, gridRow }) => {
    const { isOpen, open, close } = useIsOpen();
    return (
        <Container style={{ gridRow }}>
            <WidgetButton
                text={'Send Partnership Invitation'}
                onClick={open}
            />
            <Drawer
                isOpen={isOpen}
                close={close}
                origin='right'
                header='Send Partnership Invitation To Organisation'
            >
                <SendOrganisationInvitationForm
                    campaignId={campaignId}
                    onSuccess={close}
                />
            </Drawer>
        </Container>
    )
};

export default SendPartnershipInvitation;