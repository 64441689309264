import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { BUDGET_TRANSACTIONS } from "../consts";
import { gql } from "graphql-request";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";

const fetchFeed = gql`
    query budgetDonations($budgetId: String!, $feedParams: FeedParams) {
        feed: budgetPayments(budgetId: $budgetId, feedParams: $feedParams) {
            entities {
                name
                price
                id
                stamps {
                    created
                }
            }
            ${FEED_INFO_FRAGMENT}
        }
    }
`

const operations = {
    fetchFeed,
}

export default new MiddlewareModule({
    name: BUDGET_TRANSACTIONS,
    operations,
});

