import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";    
import cells from "./state/cells";
import {P4P_TEAM } from "./consts";
import routes from "./routes";

const P4PTeam = new CoreModule({
    name: P4P_TEAM,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default P4PTeam;