import CoreModule from "modules/Core/core-modules/CoreModule";
import { COMMON } from "./consts";
import cells from "./state/cells";
import routes from "./routes";
import feed from "./state/feed";

const Common = new CoreModule({
  name: COMMON,
  initialState: {},
  cells,
  routes,
});

export default Common;
