import ExternalScreen from "modules/Core/components/ui-kit/Screen/ExternalScreen"
import Card from "modules/Core/components/ui-kit/Card";
import Title from "modules/Core/components/ui-kit/Title";
import Text from "modules/Core/components/ui-kit/Text";
import Container from "modules/Core/components/ui-kit/Container";
import styles from "./styles.module.scss"
import copy from "./microcopy.json";
import NodeNetwork from "modules/Core/components/screens/LandingPage/NodeNetwork";
import Link from "modules/Core/components/ui-kit/Link";

const PartnershipScreen = ({ }) => {
    return (
        <ExternalScreen className={styles.container}>
            <NodeNetwork background nodes={[]} />
            <Card className={styles.card} contentClassName={styles.content}>
                <Title>Partner With P4P</Title>
                <Container>
                    <Text>
                        {copy?.a}
                    </Text>
                    <Link
                        className={styles.link}
                        external
                        to={copy?.link}>
                        Partner with us
                    </Link>
                </Container>
                <Container>
                    <Text>
                        {copy?.b}
                    </Text>
                    <Link className={styles.link} to={"/business-partnerships"}>Partner your business with us</Link>
                </Container>
            </Card>
        </ExternalScreen>
    )
}

export default PartnershipScreen;