import { gql } from "graphql-request";

export default gql`
  query campaigns($feedParams: FeedParams, $partnerId: String) {
    campaigns: prospectivePartnerCampaigns(feedParams: $feedParams, partnerId: $partnerId) {
      entities {
        id
        name
        photo
        vision
      }
    }
  }
`;
