import { initCell } from 'modules/Core/core-modules/CoreModuleState/utils/cells';
import { onSuccess } from 'modules/Core/sub-modules/Dialog/state/cells';
import { removePost as api } from 'modules/Posts/middleware/entity';
import { call, put } from 'redux-saga/effects';
import { fetchFeed } from './index.js';
import { COMMENTS } from '../../consts/index.js';

const removeComment = initCell(COMMENTS, {
    name: 'removeComment',
    sagas: {
        latest: function* ({ payload }) {
            const res = yield call(api, { id: payload?.id, justification: payload?.justification })
            return res?.success
        },
        onSuccess: function* ({ payload }) {
            yield put(onSuccess("Comment successfully removed"));
            yield put(fetchFeed.action({ renewStream: true, renewEntities: true }))
        }
    },
})

export default removeComment;