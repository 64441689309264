import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";
import { CAMPAIGN_PARTNERSHIP_INVITATIONS } from "../consts";

const fetchFeed = `
	query partnershipCampaignInvitations($partnerId: String, $campaignId: String, $feedParams: FeedParams,) {
		feed: partnershipCampaignInvitations(partnerId: $partnerId, campaignId: $campaignId, feedParams: $feedParams)  {
			entities {
				id
				template
				campaign {
					name
					id
				}
				partner {
					name
					id
				}
				variables
			}
			${FEED_INFO_FRAGMENT}
		}
	}
`;

const rsvp = `
	mutation campaignPartnershipInvitationRsvp($invitationId: String, $accepted: Boolean){
		campaignPartnershipRSVP(invitationId: $invitationId, accepted: $accepted){
			id
			campaignId: campaign
		}
	}
`;

export default new MiddlewareModule({
  name: CAMPAIGN_PARTNERSHIP_INVITATIONS,
  operations: {
    fetchFeed,
    rsvp,
  },
});
