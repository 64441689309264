import { CAMPAIGNS } from 'modules/Campaigns/consts';
import { joinCampaign } from 'modules/Campaigns/middleware';
import { initCell } from 'modules/Core/core-modules/CoreModuleState/utils/cells';
import { onSuccess } from 'modules/Core/sub-modules/Dialog/state/cells';
import { call, put } from 'redux-saga/effects';

const joinCampaignCell = initCell(CAMPAIGNS, {
    name: "joinCampaign",
    sagas: {
        latest: function* ({ payload }) {
            const { result } = yield call(joinCampaign, { campaignId: payload?.id, activate: payload.activate }) ?? {};
            const isSuccess = Boolean(result?.user)
            if (isSuccess) {
                yield put(onSuccess(`Successfully ${result?.isActive ? "joined" : "left"} campaign`))
                yield put(joinCampaignCell?.onSuccess({
                    campaignId: payload?.id,
                    isActive: result?.isActive
                }));   
            }
            return result.user;
        },
        onError: "Failed to join campaign",
    },
    successCell: {
        reducer: (state, { payload: { campaignId, isActive } }) => {
            if (!campaignId) return;
            state.entities[campaignId].isMember = isActive;
        }
    }
});

export default joinCampaignCell;