import Title from "modules/Core/components/ui-kit/Title";
import Container from "modules/Core/components/ui-kit/Container";
import ObjectiveStamps from "../../ObjectiveStamps";
import styles from "./styles.module.scss";

const ObjectiveDrawerHeader = ({header, stamps}) => (
    <Container maxWidth flex alignCenter spaceBetween className={styles.container}>
        <Title>{header}</Title>
        <ObjectiveStamps stamps={stamps?.custom} />
    </Container>
)
export default ObjectiveDrawerHeader;