import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "../../middleware";
import { BUSINESS_PARTNERSHIPS, } from "../../consts";

const { createSubscription, fetchFeed, fetchSubscription } = middleware.ops;

export default new FeedModule({
    name: BUSINESS_PARTNERSHIPS,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchFeed
        },
        createEntityCell: {
            requestHandler: createSubscription
        },
        fetchEntityCell: {
            requestHandler: fetchSubscription
        }
    }
});