import CampaignFormScreen from "../CampaignFormScreen";

const back = {
    text: 'Back',
    to: `/campaigns/feed`
}

const CreateCampaign = (props) => (
    <CampaignFormScreen
        header='Create Campaign'
        back={back}
        {...props}
    />
);

export default CreateCampaign;