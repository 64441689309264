import { useCallback } from "react";
import EntityForm from "modules/Core/components/form/EntityForm";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import withFormValidation from "modules/Core/higher-order-components/withFormValidation";
import useOnSuccess from "modules/Core/sub-modules/Dialog/hooks/useOnSuccess";
import { createEntity, fetchFeed } from "modules/Objectives/state/cells";

const props = {
    titleFieldProps: {
        label: "Objective Name",
    },
    textFieldProps: {
        label: "Objective Description",
    },
    aimsFieldProps: {
        label: "Sub-Objectives",
        inputProps: {
            titleLabel: "Sub-Objective Name",
            textLabel: "Sub-Objective Description",
        },
    },
    hasAims: true,
};

const validation = {
    name: {
        required: true,
        minLength: 3,
        readableKey: "Name"
    },
    text: {
        required: false,
        validator: (value = "") => value.length <= 36 && `Minimum character length is 36 for Objective Description (${value?.length ?? 0}/36).`,
        readableKey: "Description"
    },
    aims: {
        validator: (value = []) => {
            if (!value.length > 0) return
            if (value?.some(({ aim } = {}) => aim?.length < 3)) return `Minimum character length is 3 for all Campaign Aims`
            if (value?.some(({ description } = {}) => description?.length > 360)) return `Maximum character length is 180 for all Campaign Aim Descriptions`
        },
        readableKey: "Sub-Objectives"
    }
}

const ObjectiveForm = withFormValidation(EntityForm, validation);

const useFormLogic = ({ onCreate, campaignId, onSuccess, refetchFeed }) => {
    const onSuccessDialog = useOnSuccess();
    const handleSubmit = useCallback((objective) => onCreate({
        input: {
            name: objective.name,
            text: objective.text,
            campaign: campaignId,
            subObjectives: objective?.aims?.map(({ aim, description }) => ({
                name: aim,
                text: description,
            })),
        },
        callback: ({ id }) => {
            if (!id) return null;
            onSuccessDialog("Prepared objective successfully")
            onSuccess?.()
            refetchFeed()
        }
    }), [onCreate, campaignId, refetchFeed]);

    return {
        handleSubmit,
        ...props
    }
}

export default strappedConnected(
    ObjectiveForm,
    null,
    { 
        onCreate: createEntity.action,
        refetchFeed: () => fetchFeed.action({ renewStream: true }),
    },
    useFormLogic
)