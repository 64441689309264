import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";
import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { BUDGETS } from "../consts";

const create = `
    mutation setBudget($input: BudgetInput, $id: String) {
        budget: setBudget(input: $input, id: $id) {
            id
        }
    }
`;

const fetchFeed = `
    query fetchBudgets($feedParams: FeedParams, $campaignId: String) {
        feed: budgets(feedParams: $feedParams, campaignId: $campaignId) {
            entities {
                name
                id
                amount
                amountRaised
            }
            ${FEED_INFO_FRAGMENT}
        }
    }
`;

const fetchEntity = `
    query fetchBudget($id: String!){
        entity: budget(id: $id){
            name
            amount
            amountRaised
            description
            intention
            id
            isOwner
        }
    }
`;

const fetchProspective = `
    query fetchBudgets($feedParams: FeedParams, $campaignId: String) {
        feed: prospectiveBudgets(feedParams: $feedParams, campaignId: $campaignId) {
            entities {
                name
                id
                amount
                amountRaised
            }
            ${FEED_INFO_FRAGMENT}
        }
    }
`;

const approveBudget = `
    mutation approveBudget($id: String) {
        approveBudget(id: $id)
    }
`;


const operations = {
    create,
    fetchFeed,
    fetchEntity,
    fetchProspective,
    approveBudget,
    
}

export default new MiddlewareModule({
    name: BUDGETS,
    operations,
    operationsConfig: {
        fetchEntity: {
            paramsParser: (id) => ({ id }), // to do: normalise / fix or something somehow, somewhere.. 
        }
    }
});

