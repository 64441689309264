import cx from 'classnames';
import IdentityCard from 'modules/Core/components/ui-kit/IdentityCard';
import styles from "./styles.module.scss";

const PartnerIdentificationCard = ({ name, image, className, ...props }) => (
    <IdentityCard 
        {...props}
        name={name}
        image={image}
        className={cx(className, styles.container)} 
    />
);

export default PartnerIdentificationCard;
