import { gql } from "graphql-request";
import { graphqlClient } from "modules/Core/middleware";

const POST_FRAGMENT = gql`
		id
		name
		text
		flagCount
		heartCount
		campaign
		attachments {
			url
			type
		}
		userInformation {
			photo
			lastName
			firstName
			bio
      }
`;

const query = gql`
	query post($id: String!){
		entity: post(id: $id) {
			${POST_FRAGMENT}
			isHearted
			isFlagged
			commentsCount
			isAuthor
		}
	}
`;

const fetchSharedPostQuery = gql`
	query fetchPublicPost($id: String!){
		entity: publicPost(id: $id) {
			${POST_FRAGMENT}
		}
	}
`;

const heartPostMutation = gql`
	mutation heartPost ($id: String!){
		success: heartPost(id: $id)	
	}
`;


const flagPostMutation = gql`
  mutation flagPost ($id: String!){
	success: flagPost(id: $id)
  }
`;

const removePostMutation = gql`
  mutation removePost ($id: String!, $justification: String!){
	success: removePost(id: $id, justification: $justification)
  }
`;

export const fetchPost = async (id) => {
	const q = graphqlClient.hasToken ? query : fetchSharedPostQuery;
	return graphqlClient.request(q, { id });
}

export const heartPost = async (id) => {
	return graphqlClient.request(heartPostMutation, { id });
}

export const flagPost = async (id) => {
	return graphqlClient.request(flagPostMutation, { id });
}

export const fetchSharedPost = async (id) => {
	const q = graphqlClient.hasToken ? query : fetchSharedPostQuery;
	return graphqlClient.request(q, { id });
}

export const removePost = async ({ id, justification }) => {
	return graphqlClient.request(removePostMutation, { id, justification });
}