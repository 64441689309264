import cx from "classnames";
import { Message } from "modules/Core/components/ui-kit/indicators";
import Action from "../Action";
import CreateComment from "modules/Posts/sub-modules/Comments/components/CreateComment";
import { useIsOpen } from "modules/Core/hooks/useIsOpen";
import { ExpandableCardComponent } from "modules/Core/components/ui-kit/Card/ExpandableCard";
import styles from "./styles.module.scss";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { fetchEntity } from "modules/Campaigns/state/cells";

const CommentAction = ({
  commentsCount,
  postId,
  campaignId,
  actionId,
  isOpen,
  open,
  close,
}) => {
  return (
    <>
      <ExpandableCardComponent
        isOpen={isOpen}
        openClassName={styles.openComments}
        className={styles.comments}
        contentClassName={styles.content}
      >
        <span
          onClick={close}
          className={cx("chevron-down-container", styles.minimiseComment)}
        />
        <CreateComment
          onSuccess={close}
          postId={postId}
          campaignId={campaignId}
          actionId={actionId}
        />
      </ExpandableCardComponent>
      <Action
        Indicator={Message}
        isActive={isOpen}
        count={commentsCount}
        action={open}
      />
    </>
  );
};

export default strappedConnected(
  withShouldRender(CommentAction),
  {
    shouldRender: (state, { campaignId, shouldRender = true }) =>
    {  shouldRender &&
      Boolean(fetchEntity.selectField(campaignId, "isMember")(state))},
  },
  null,
  () => useIsOpen()
);
