import { COMMON } from "modules/Common/consts";
import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "modules/Common/middleware";
const {
    fetchCategoy
} = middleware.ops;

export default new FeedModule({ 
    name: COMMON,  
    cellOptions: {
        createEntityCell: {
        },
        fetchFeedCell: {
        }, 
        fetchEntityCell: {
        }
    }
});