import cx from 'classnames';
import { Checkbox } from "@mui/material";
import Container from "modules/Core/components/ui-kit/Container";
import { InputLabel } from "modules/Core/components/ui-kit/Input";
import ConditionalContainer from "../../ui-kit/ConditionalContainer";
import PartnerCard from "modules/Partners/components/PartnerCard";
import styles from './styles.module.scss';

const PartnerField = ({ value, onChange, label, onClear, disabled, cardClassName, ...props }) => {
    return (
        <Container p1 maxHeight flex row alignCenter spaceBetween>
            <InputLabel label={label} />
            <Container className={styles.wrapper} flex col justifyCenter center>
                <PartnerCard className={cx(styles.card, cardClassName)} name={value?.name} image={value?.info?.image} >
                    <Checkbox
                        checked={Boolean(!value?.disabled)}
                        onChange={() => onChange({ ...value, disabled: !value?.disabled })}
                    />
                    <ConditionalContainer
                        className={styles.disabledScreen}
                        shouldRender={Boolean(disabled)}
                    />
                </PartnerCard>
            </Container>
        </Container>
    )
}

export default PartnerField;