import ExternalScreen from "modules/Core/components/ui-kit/Screen/ExternalScreen"
import Title from "modules/Core/components/ui-kit/Title"
import Container from "modules/Core/components/ui-kit/Container"
import P4PValuesFeed from "modules/P4P/sub-modules/Values/components/P4PValuesFeed"
import styles from "./styles.module.scss"

const OurValuesScreen = ({ }) => (
    <ExternalScreen className={styles.container}>
        <Container className={styles.content}>
            <Title>P4P's Values</Title>
            <P4PValuesFeed />
        </Container>
    </ExternalScreen>
);

export default OurValuesScreen;