import middleware from "modules/Actions/middleware";
import { put } from "redux-saga/effects";
import feed from "modules/Actions/state/feed";

const { create } = middleware.ops;

export default {
    requestHandler: create,
    successMessage: "Action created successfully",
    onSuccess: function* () {
        yield put(feed.cells.fetchFeed.action({ renewStream: true }))
    }
}