import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";    
import cells from "./state/cells";
import routes from "./routes";
import { TEAM_MEMBERS } from "./consts";

const TeamMembers = new CoreModule({
    name: TEAM_MEMBERS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default TeamMembers;

// consider making more generic to be relevant to more entities (not just partners) 
// i.e. could be merged with campaign-maintainers and future entities which may corrrelate to a team of people. 