import CreateJudgement from "../components/screens/CreateJudgement";
import JudgementFeed from "../components/screens/JudgementFeed";
import ViewJudgement from "../components/screens/ViewJudgement";

export default {
  "create-judgement-case": (props) => (
    <CreateJudgement redirectRoute={"/judgement-cases"} {...props} />
  ),
  "judgement-cases": JudgementFeed,
  "judgement-cases/:id": ViewJudgement,
};
