import PeerTransactionsFeed from "modules/Partners/submodules/peer-partner-financials/components/PeerTransactionsFeed"
import Container from "modules/Core/components/ui-kit/Container";
import styles from "./styles.module.scss";

const PartnerFinancialsFeedTab = ({partnerId}) => {
    return (
        <Container className={styles.container}>
            <PeerTransactionsFeed partnerId={partnerId} />
        </Container>
    )
};

export default PartnerFinancialsFeedTab;