import Container from "modules/Core/components/ui-kit/Container";
import { NOTARIES } from "modules/Payments/consts";
import useNotary from "modules/Payments/hooks/useNotary";

const PaymentContent = ({ amount, args, onSuccess, className }) => {
    const { jsx } = useNotary(NOTARIES.STRIPE, amount, { args, onSuccess });
    return (
        <Container className={className}>
            {jsx}
        </Container>
    )
};

export default PaymentContent;