import Container from "modules/Core/components/ui-kit/Container";
import feed from "../../state/feed";
import strapped from "modules/Core/higher-order-components/strapped";
import FeedItem from "./FeedItem";

const { FeedComponent } = feed;

const CampaignPartnershipsFeed = (props) => (
    <Container>
        <FeedComponent
            {...props}
            ItemComponent={FeedItem}
        />
    </Container>
);

export default strapped(
    CampaignPartnershipsFeed,
    ({ campaignId }) => ({
        filters: { campaignId }
    })
);