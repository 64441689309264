import FeedModule from "modules/Core/core-modules/FeedModule";
import { setJudgement, fetchJudgements, fetchJudgement } from "modules/Judgements/middleware";
import { JUDGEMENTS } from "modules/Judgements/consts";

export default new FeedModule({
  name: JUDGEMENTS,
  cellOptions: {
    createEntityCell: {
      requestHandler: setJudgement,
    },
    fetchFeedCell: {
      requestHandler: fetchJudgements,
    },
    fetchEntityCell: {
      requestHandler: fetchJudgement,
    },
  },
});
