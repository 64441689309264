import SplitScreen from 'modules/Core/components/ui-kit/SplitScreen';
import withParams from 'modules/Core/higher-order-components/withParams';
import panels from './panels';

const ProspectiveCampaignsFeedScreen = ({ parentId = null }) => (
    <SplitScreen
        panels={panels}
        commonPanelProps={{ parentId }}
    />
)

export default withParams(ProspectiveCampaignsFeedScreen);