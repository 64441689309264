import heartPost from "./heartPost";
import flagPost from "./flagPost";
import removePost from "./removePost";
import fetchSharedPost from "./fetchSharedPost";
import feed from "../feed";

export const {
    createEntity,
    fetchFeed,
    fetchEntity,
    setFilters
} = feed.cells;

export default {
    createEntity,
    fetchFeed,
    fetchEntity,
    setFilters,
    heartPost,
    flagPost,
    fetchSharedPost,
    removePost
}