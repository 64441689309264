import AuthenticationModal from "../AuthenticationModal"

const AuthenticatedComponent = ({ Component, ...props }) => {
    return (
        <>
            <AuthenticationModal />
            <Component {...props} />
        </>
    )
}

export default AuthenticatedComponent;
