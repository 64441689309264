import FeedModule from "modules/Core/core-modules/FeedModule";
import { fetchProspectiveCampaign, fetchProspectiveCampaigns } from "../../middleware";
import { PROSPECTIVE_CAMPAIGNS } from "../../consts";

export default new FeedModule({
    name: PROSPECTIVE_CAMPAIGNS,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchProspectiveCampaigns
        },
        fetchEntityCell: {
            requestHandler: fetchProspectiveCampaign
        }
    }
});