import { useParams } from "react-router-dom";
import useCampaignRoles from "../hooks/useCampaignRoles";
import React, { useMemo } from "react";
import { CAMPAIGN_ROLES } from "../consts";

export default (ComponentsMap) => (props) => {
    const params = useParams();
    const campaignId = useMemo(() => params?.id ?? props.campaignId, [params, props?.id]);
    const roles = useCampaignRoles(campaignId);
    const Component = useMemo(() => {
        if (roles?.includes(CAMPAIGN_ROLES.ROOT)) {
            return ComponentsMap[CAMPAIGN_ROLES.ROOT] ?? ComponentsMap[CAMPAIGN_ROLES.MAINTAINER];
        } 
        if (roles?.includes(CAMPAIGN_ROLES.MAINTAINER)) {
            return ComponentsMap[CAMPAIGN_ROLES.MAINTAINER];
        }
        return ComponentsMap[undefined] ?? React.Fragment;
    },[ComponentsMap, roles])
    return <Component {...props} />
};