import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import schema from "./schema.json";
import { createEntity } from "modules/Budgets/state/cells";
import SchemaForm from "modules/Core/components/form/Form/SchemaForm";

export default strappedConnected(
    SchemaForm,
    {},
    {
        onSubmit: (data, callback) => createEntity.action({
            input: {
                ...data,
                items: data?.items?.map((item) => ({ ...item, amount: parseFloat(item.amount) })),
            },
            callback    
        })
    },
    ({campaignId, onSubmit}) => ({
        onSubmit: (data, cb) => onSubmit({campaignId, ...data}, cb),
        schema, 
        success: schema.success,
        parseIsSuccess: (res) =>  Boolean(res?.budget?.id)
    })
);