import feed from "../../state/feed";
import Card from "modules/Core/components/ui-kit/Card";
import Title from "modules/Core/components/ui-kit/Title";
import Text from "modules/Core/components/ui-kit/Text";
import styles from "./styles.module.scss";

const { FeedComponent } = feed;

const FeedItem = ({ title, description, dateLabel: date }) => (
    <Card className={styles.card}>
        <Title>{title}</Title>
        <Text>{description}</Text>
        <Text flexEnd flex p1>{date}</Text>
    </Card>
)

const P4PWorklogFeed = ({ }) => (
    <FeedComponent ItemComponent={FeedItem} />
)

export default P4PWorklogFeed