import IdentityCard from "modules/Core/components/ui-kit/IdentityCard"
import { fetchEntity } from "modules/Partners/state/cells";
import Container from "modules/Core/components/ui-kit/Container";
import Text from "modules/Core/components/ui-kit/Text";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import styles from './styles.module.scss';
import { useMemo } from "react";

export const PartnerTeamMemberCard = ({ firstName, lastName, photo, bio, isOneself }) => (
    <IdentityCard
        name={Boolean(firstName || lastName) ? `${firstName ?? ''} ${lastName ?? ''}` : 'Anonymous'}
        image={photo}
        className={styles.card}
    >
        {/* <Container className={styles.dot}/> */}
        {/* <Text>{bio}</Text> */}
        <Container className={styles.subTextContainer}>
            {isOneself && <Text className={styles.oneself}>(You)</Text>}
        </Container>
    </IdentityCard>
)

const Component = ({ members, className, ...props }) => (
    <Container className={className} {...props}>
        {members.map((member, index) => (
            <PartnerTeamMemberCard key={index} {...member} />
        ))}
    </Container>
);

export default strappedConnected(
    withShouldRender(Component),
    {
        members: (state, { partnerId }) => fetchEntity.selectField(partnerId, 'members')(state)
    },
    null,
    ({ members }) => ({
        members: members ?? [],
        shouldRender: Boolean(members?.length),
    })
)