import { gql } from "graphql-request";
import { CAMPAIGN_DETAILS_FRAGMENT } from "modules/Campaigns/middleware";
import { graphqlClient } from "modules/Core/middleware";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";

const query = gql`
	query prospectiveCampaigns($parentId: String, $feedParams: FeedParams) {
		feed: prospectiveCampaigns(parentId: $parentId, feedParams: $feedParams)  {
			entities {
				id
				name
			}
			${FEED_INFO_FRAGMENT}
		}
	}
`;

const fetchCampaignQuery = gql`
    query fetchCampaign($id: String!){
        entity: prospectiveCampaign(id: $id){
            ${CAMPAIGN_DETAILS_FRAGMENT}
        }
    }
`;



export const fetchProspectiveCampaign = async (id) => {
	return graphqlClient.request(fetchCampaignQuery, { id });
}

export const fetchProspectiveCampaigns = async (params) => {
	return graphqlClient.request(query, params);
}
