export default `
    query fetchObjective($id: String!) {
        entity: objective(id: $id) {
            id
            name
            text
            subObjectives{
                name
                text
                id
                parent
                stamps {
                    custom {
                        achieved {
                            time
                        }
                        commenced {
                            time
                        }
                    } 
                }
            }
            stamps {
                custom {
                    achieved {
                        time
                    }
                    commenced {
                        time
                    }
                } 
            }
        }
    }`
;