export const SIDEBAR_LINKS = [
    { text: 'Features', header: true },
    { text: "Campaigns", to: "/campaigns" },
    { text: "Partners", to: "/partners" },
    { text: "Financials", to: "/p4p/fundraising" },
    { divider: true, },
    { text: 'About Us', header: true },
    { text: "Our Values", to: "/p4p/values" },
    { text: "Our Commitment", to: "/p4p/mission-statement" },
    { text: "Our People", to: "/p4p/team" },
]