import Form from "modules/Core/components/form/Form/index"
import strappedConnected from "modules/Core/higher-order-components/strappedConnected"
import STANDARD_MEMBERSHIP_FORM_SCHEMA from "modules/Partners/submodules/team-members/components/RegisterPartnerTeamMemberForm/schema"
import { createEntity } from "../../state/cells"

export default strappedConnected(
    Form,
    {},
    {
        onSubmit: ({ bio, roles, ...userInformation }, callback) => createEntity.action({
            input: {
                bio,
                roles,
                userInformation
            },
            callback
        })
    },
    () => ({
        schema: STANDARD_MEMBERSHIP_FORM_SCHEMA,
        success: {
            text: "P4P Team member added successfully"
        }
    })
)