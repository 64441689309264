import { useMemo, useState } from 'react';
import cx from 'classnames';
import Container from 'modules/Core/components/ui-kit/Container';
import PostsFeed from 'modules/Posts/components/PostsFeed';
import CreatePost from 'modules/Posts/components/CreatePost';
import ViewPostPanel from 'modules/Posts/components/ViewPostPanel';
import styles from './styles.module.scss';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import { fetchEntity } from 'modules/Campaigns/state/cells';
import { compactObject } from 'modules/Core/utils/obj';

const CampaignsPosts = ({ id: campaignId, createPostAdditionalParams }) => {
    const [fullEntity, setFullEntity] = useState([]);
    const filters = useMemo(() => ({ campaignId }), [campaignId])

    return (
        <Container className={styles.container} flex column span justifyCenter>
            <PostsFeed filters={filters} onClick={(post) => setFullEntity(post)} />
            <CreatePost
                className={styles.createContainer}
                additionalParams={createPostAdditionalParams}
                campaignId={campaignId}
            />
            <ViewPostPanel
                postId={fullEntity?.id}
                className={cx(styles.viewPostPanel, { [styles.open]: !!fullEntity?.id })}
                headerChildren={
                    <span
                        className={cx('chevron-down-container', styles.chevron)}
                        onClick={() => setFullEntity(null)}
                    />
                }
                onClose={() => setFullEntity(null)}
            />
        </Container>
    )
}

export default strappedConnected(CampaignsPosts,
    {
        partnershipOrgId: (state, { id }) => fetchEntity.selectNestedField(id, 'partnership.partner.id')(state)
    },
    {

    },
    ({ partnershipOrgId }) => ({
        createPostAdditionalParams: useMemo(() => compactObject({
            partnerId: partnershipOrgId
        }), [partnershipOrgId])
    }),
);