import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "../../middleware";
import { CAMPAIGN_FINANCIALS } from "../../consts";

const { fetchFeed, registerFinancials, fetchCampaignFinancialsIntegration } = middleware.ops;

export default new FeedModule({
    name: CAMPAIGN_FINANCIALS,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchFeed
        },
        createEntityCell: {
            requestHandler: registerFinancials
        },
        fetchEntityCell: {
            requestHandler: fetchCampaignFinancialsIntegration
        }
    }
});