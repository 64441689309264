import { useMemo } from "react";
import Container from "modules/Core/components/ui-kit/Container"
import Text from "modules/Core/components/ui-kit/Text";
import Button from "modules/Core/components/ui-kit/Button";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import Drawer from "modules/Core/components/ui-kit/Drawer";
import Title from "modules/Core/components/ui-kit/Title";
import InvitationCard from "../../InvitationCard";
import CampaignDetailsTab from "modules/Campaigns/components/CampaignDetails/CampaignDetailsTab";
import { fetchEntity as fetchCampaignEntity } from "modules/Campaigns/state/cells";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import { useIsOpen } from "modules/Core/hooks/useIsOpen";
import { fetchEntity } from "modules/Partners/state/cells";
import List from "modules/Core/components/ui-kit/List";
import { CAMPAIGN_ROLES } from "modules/Campaigns/consts";
import { CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS } from "modules/Partners/submodules/campaign-partnership-invitations/consts";
import PartnerInfoFeatures from "modules/Partners/components/PartnerInfoFeatures";
import microcopy from "./microcopy.json";
import styles from "./styles.module.scss";

const CampaignSentInvitation = ({ variables = {}, footer, partnerName, campaignName, open, close, isOpen, stamps }) => (
    <>
        <InvitationCard
            className={styles.container}
            microcopy={{
                title: microcopy.title,
                to: partnerName ? `To${partnerName ? ` ${partnerName}` : ''},` : "Hello",
                personalisedMessage: variables?.message,
                kindRegards: campaignName
            }}

            prePersonalisedMessage={
                <>
                    <Container flex alignCenter className={styles.description}>
                        <Text pre onClick={open}>
                            {campaignName + " "}
                        </Text>
                        <Text>
                            has invited you to partner with the campaign.
                        </Text>
                    </Container>
                </>
            }
            content={
                <Container>
                    <Text fs150>
                        Joining Campaigns can be a great way ...
                    </Text>
                    <List
                        className={styles.abc}
                        items={[
                            "To enhance your reach and advance you in your mission",
                            "To increase the campaign's capability for change.",
                            "To increase your activity which can increase your organisation's appeal for public donations.",
                            "To build your network and make new connections!"
                        ]}
                        fs120
                    />

                    <Text fs150 mb1>
                        Please check the campaign details carefully,
                    </Text>

                    <Text fs150 mb1>
                        Looking forward to collaborating.
                    </Text>

                </Container>
            }
            footer={footer}
        />
        <Drawer
            origin={"right"}
            isOpen={isOpen}
            close={close}
            size='md'
        >
            <CampaignDetailsTab shouldLoad={false} id={variables?.campaignId} />
        </Drawer>
    </>
)

const PartnerSentInvitation = ({ variables = {}, isMaintainer = false, partnerId, footer, partnerName, campaignName, open, close, isOpen, stamps }) => (
    <>
        <InvitationCard
            className={styles.container}
            microcopy={{
                title: microcopy.title,
                to: `'${campaignName}' Maintainer`,
                personalisedMessage: variables?.message,
                kindRegards: partnerName
            }}
            prePersonalisedMessage={
                <>
                    <Container flex alignCenter className={styles.description}>
                        <Text pre onClick={open}>
                            {partnerName + " "}
                        </Text>
                        <Text>
                            has invited you to partner with your campaign.
                        </Text>
                    </Container>
                </>
            }
            content={
                <Container>
                    <Text fs150>
                        Partnering with an organisation can be a great way ...
                    </Text>
                    <List
                        className={styles.abc}
                        items={[
                            "To enhance your reach and advance you in your mission",
                            "To increase the campaign's capability for change.",
                            "To help your partner's activity achieve its' goals.",
                            "To enhance your campaign's network and grow your community!"
                        ]}
                        fs120
                    />

                    <Text fs150 mb1>
                        Please check out {partnerName} to know if you're compatible,
                    </Text>

                    <Text fs150 mb1>
                        Looking forward to collaborating.
                    </Text>

                </Container>
            }
            footer={footer}
        />
        <Drawer
            origin={"right"}
            isOpen={isOpen}
            close={close}
            size='md'
            header='Partner Details'
        >
            <PartnerInfoFeatures
                partnerId={partnerId}

            />
        </Drawer>
    </>
)

const CampaignPartnershipInvitation = ({ Component, ...props }) => <Component {...props} />;


export default strappedConnected(
    CampaignPartnershipInvitation,
    {
        campaignName: (state, { variables }) => fetchCampaignEntity.selectField(variables?.campaignId, "name")(state),
        campaignMembership: (state, { variables }) => fetchCampaignEntity.selectField(variables?.campaignId, "membership")(state),
        partnerName: (state, { variables }) => fetchEntity.selectField(variables?.partnerId, 'name')(state),
        associated: (state, { variables }) => fetchEntity.selectField(variables?.partnerId, 'associated')(state),
    },
    {
        fetchCampaign: fetchCampaignEntity.action,
        fetchPartner: fetchEntity.action,
    },
    ({ variables, fetchCampaign, fetchPartner, partnerName, associated, stamps = {}, rsvp, campaignMembership }) => {
        const { isOpen, open, close } = useIsOpen();
        useOnLoad(
            () => fetchCampaign({ id: variables?.campaignId }),
            Boolean(variables?.campaignId),
            [variables?.campaignId]
        )
        useOnLoad(
            () => fetchPartner({ id: variables?.partnerId }),
            Boolean(variables?.partnerId && !partnerName),
            [variables?.partnerId]
        );

        const Component = useMemo(() => {
            if (variables?.sentBy === "PARTNER") return PartnerSentInvitation;
            return CampaignSentInvitation;
        }, [variables?.sentBy]);

        const isMaintainer = useMemo(() => Boolean(campaignMembership?.roles.includes(CAMPAIGN_ROLES.MAINTAINER)), [campaignMembership?.roles])

        const hideFooter = useMemo(() => {
            if (!variables?.sentBy) return true;
            if (variables?.sentBy == CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS.PARTNER) return !Boolean(isMaintainer);
            else return Boolean(!associated)
        }, [isMaintainer, associated, variables?.sentBy])

        return {
            open,
            close,
            isOpen,
            Component,
            partnerId: variables?.partnerId,
            footer: useMemo(() => {
                if (hideFooter) return null;
                if (stamps?.custom?.accepted) return <Title className={styles.accepted}>Accepted</Title>
                if (stamps?.custom?.declined) return <Title className={styles.declined}>Declined</Title>
                return (
                    <>
                        <Button onClick={() => rsvp(false)}>
                            Decline
                        </Button>
                        <Button onClick={() => rsvp(true)}>
                            Accept
                        </Button>
                    </>
                )
            }, [stamps?.custom?.accepted, stamps?.custom?.declined, rsvp, isMaintainer])
        }
    }
);