import Container from 'modules/Core/components/ui-kit/Container';
import Text from 'modules/Core/components/ui-kit/Text';
import Title from 'modules/Core/components/ui-kit/Title';
import FundraisingProgressionGraphic from '../../FundraisingProgressionGraphic';
import styles from './styles.module.scss';

const Header = ({ target, amountRaised, visionStatement, title }) => (
    <Container className={styles.headerContent} flex spaceBetween span>
        <Container>
            <Title>{title}</Title>
            <Text>{visionStatement}</Text>
        </Container>
        <Container>
            <FundraisingProgressionGraphic amountRaised={amountRaised} target={target} />
        </Container>
    </Container>
)

export default Header