import cx from "classnames";
import feed from "../../state/feed";
import Card from "modules/Core/components/ui-kit/Card";
import Title from "modules/Core/components/ui-kit/Title";
import Text from "modules/Core/components/ui-kit/Text";
import styles from "./styles.module.scss";

const { FeedComponent } = feed;

const P4PValuesFeed = ({ onClick, ItemComponent = P4PValuesFeed.FeedItem, itemProps, }) => (
    <FeedComponent
        ItemComponent={ItemComponent}
        onClick={onClick}
        itemProps={itemProps}
    />
);

P4PValuesFeed.FeedItem = ({ text, intent, onClick, className }) => (
    <>
        <Card className={cx(styles.card, className)} onClick={onClick}>
            <Title>{text}</Title>
            <Text>{intent}</Text>
        </Card>
    </>
)

export default P4PValuesFeed