import { initCell } from 'modules/Core/core-modules/CoreModuleState/utils/cells';
import { POSTS } from 'modules/Posts/consts';
import { flagPost as api } from 'modules/Posts/middleware/entity';
import { call, put } from 'redux-saga/effects';

const flagPost = initCell(POSTS, {
    name: 'flagPost',
    sagas: {
        latest: function* ({ payload }) {
            const res = yield call(api, payload?.id)
            if (res?.success) yield put(flagPost.onSuccess(payload))
            return res?.success
        }
    },
    successCell: {
        reducer: (state, { payload }) => {
            if (!state.entities[payload?.id].isFlagged){
                state.entities[payload?.id].flagCount += 1
            }
            state.entities[payload?.id].isFlagged = Boolean(payload);
        },
    }
})

export default flagPost