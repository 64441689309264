import { gql } from "graphql-request";

export default gql`
    query partners($kind: PartnerKind, $notCampaignId: String) {
        partners(kind: $kind, notCampaignId: $notCampaignId) {
            entities {
                id
                name
                info {
                    image
                }
            }
        }
    }
`