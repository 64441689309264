import Button from "modules/Core/components/ui-kit/Button";
import Container from "modules/Core/components/ui-kit/Container";
import Feature from "modules/Core/components/ui-kit/Feature";
import Input from "modules/Core/components/ui-kit/Input";
import Widget from "modules/Core/components/ui-kit/Widget";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { setFilters } from "modules/Partners/state/cells";
import { fetchCategoryTags } from "modules/Common/state/cells";
import styles from "./styles.module.scss";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";

const PartnerFiltersWidget = ({ filters, setFilter, tags,  ...props }) => (
    <Widget label={'Filter Partners'} {...props}>
        <Container flex col>
            <Feature
                // UNIFIED Search Filter? 
                className={styles.filterFeature}
                name={'Search Partners'}
                content={<Input value={filters?.search} onChange={val => setFilter('search', val)} text placeholder={"type here ..."} />}
                TitleElement='h4'
                flex
                col
                spaceBetween
                maxWidth
            />
            <Feature
            // TODO: Unify with campaigns one and have a unified component for this input type.
                className={styles.filterFeature}
                name={'Categories'}
                content={
                    <Container className={styles.tagsContainer} flex maxWidth>
                        {tags?.map(tag => <Button className={filters?.categoryTags?.includes(tag?.id) && styles.selected} key={tag?.id} text={tag.name} onClick={() => setFilter('categoryTags', [...(filters?.categoryTags ?? []), tag.id])} />)}
                    </ Container>
                }
                TitleElement='h4'
                col
                flex
                spaceBetween
            />
        </Container>
    </Widget>
);

export default strappedConnected(
    PartnerFiltersWidget,
    {
        filters: setFilters.selectors.getFilters,
        tags: fetchCategoryTags.select
    },
    {
        setFilters: setFilters.action,
        fetchTags: fetchCategoryTags.action
    },
    ({ setFilters, fetchTags, tags }) => {
        useOnLoad(
            () => fetchTags(),
            !Boolean(tags?.length)
        )
        return ({
            setFilter: (key, value) => setFilters({ [key]: value, shouldRefetch: true }),
        })
    }
);