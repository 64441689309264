import { useMemo } from 'react'
import DonationsWidget from 'modules/Payments/components/DonationsWidget'
import { TRANSACTION_KEYS } from 'modules/Payments/consts';
import { fetchEntity } from 'modules/Partners/state/cells';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import withShouldRender from 'modules/Core/higher-order-components/withShouldRender';

export default strappedConnected(
    withShouldRender(DonationsWidget),
    { 
        partnerName: (state, { partnerId }) => fetchEntity.selectField(partnerId, 'name')(state) ,
        financialsAccount: (state, { partnerId }) => fetchEntity.selectField(partnerId, 'financialsAccount')(state)
    },
    null,
    ({ partnerName, partnerId, financialsAccount }) => ({
        label: 'Donate to ' + partnerName,
        donationProps: useMemo(() => ({
            tithingOptions: {
                label: "Dedicate percentage of your donation to P4P?",
                default: true
            },
            partnerId,
            transactionKind: TRANSACTION_KEYS.PARTNER
        }), [partnerId]),
        shouldRender: Boolean(partnerName && financialsAccount),
    })
);