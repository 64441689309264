import CoreModule from "modules/Core/core-modules/CoreModule";
import { BUDGETS } from "./consts";
import cells from "./state/cells";
import routes from "./routes";
import feed from "./state/feed";

const Budgets = new CoreModule({
    name: BUDGETS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default Budgets;