import { useMemo } from "react";
import feed from "modules/Budgets/state/feed";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import Container from "modules/Core/components/ui-kit/Container";
import BudgetFeedItem from "../BudgetsFeed/BudgetFeedItem";
import { approveBudget, fetchProspectiveBudgets } from "modules/Budgets/state/cells";
import useFocusedId from "modules/Core/hooks/useFocusedId";
import Drawer from "modules/Core/components/ui-kit/Drawer";
import BudgetDetails from "../BudgetDetails";
import Button from "modules/Core/components/ui-kit/Button";
import styles from "./styles.module.scss";

const { FeedComponent } = feed;

const ProspectiveBudgetsFeed = ({ onItemClick, filters, focusedId, unFocus, approveBudget }) => (
    <Container className={styles.container}>
        <FeedComponent
            ItemComponent={BudgetFeedItem}
            onClick={onItemClick}
            filters={filters}
            loadFeed={fetchProspectiveBudgets.action}
            feedSelector={fetchProspectiveBudgets.selectors.getFeed}
        />
            <Drawer
                isOpen={Boolean(focusedId)}
                close={unFocus}
                backdrop={false}
                origin={'left'}
                className={styles.drawer}
                headerClassName={styles.header}
                headerChildren={"Budget Details"}
            >
                <BudgetDetails id={focusedId} />
                <Button onClick={approveBudget}>Approve Budget</Button>

            </Drawer>
    </Container>
)

export default strappedConnected(
    ProspectiveBudgetsFeed,
    {},
    {
        approveBudget: (id) => approveBudget.action({id})
    },
    ({ campaignId, approveBudget }) => {
        const {focusedId, focus, unFocus} = useFocusedId();
        return {
            filters: useMemo(() => ({ campaignId }), [campaignId]),
            onItemClick: focus,
            focusedId,
            unFocus,
            approveBudget: () => approveBudget(focusedId)
        }
    }
);