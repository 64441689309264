import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";

export default `
    query fetchActiveObjectives($feedParams: FeedParams, $campaignId: String) {
        feed: activeObjectives(feedParams: $feedParams, campaignId: $campaignId) {
            entities {
                id
                name
                stamps {
                    custom {
                        achieved {
                            time
                        }
                        commenced {
                            time
                        }
                    }
                }
            }
            ${FEED_INFO_FRAGMENT}
        }
    }
`;