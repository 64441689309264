import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";
import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { ACTIONS } from "../consts";

const create = `
    mutation setAction($input: ActionInput!, $id: String) {
        entity: setAction(input: $input, id: $id) {
            id
        }
    }
`;

const fetchFeed = `
    query fetchActions($feedParams: FeedParams, $campaignId: String) {
        feed: actions(feedParams: $feedParams, campaignId: $campaignId) {
            entities {
                id
                name
                text
                photos
            }
            ${FEED_INFO_FRAGMENT}
        }
    }
`;


const operations = {
    create,
    fetchFeed,
}

export default new MiddlewareModule({
    name: ACTIONS,
    operations,
    operationsConfig: {
        create: {
            paramsParser: ({ input }, id) => ({ input, id }),
        }
    }
});

