import Container from "modules/Core/components/ui-kit/Container";
import DescriptiveExpandableCard from "modules/Core/components/ui-kit/Card/ExpandableCard/DescriptiveExpandableCard";
import styles from "./styles.module.scss";

const ExpandableDetailsSet = ({ entities, renderChildren, ExpandableComponent = DescriptiveExpandableCard, ...props }) => Boolean(entities?.length) && (
    <Container className={styles.aims} column flex>
        {entities.map(({ aim, name = aim, text, description = text, ...item }) => (
            <ExpandableComponent
                className={styles.aim}
                openClassName={styles.open}
                key={aim}
                label={name}
                description={description}
                {...item}
                children={renderChildren?.({ name, text, description, ...item })}
                {...props}
            />
        ))}
    </Container>
);

export default ExpandableDetailsSet;