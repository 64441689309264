import { initCell } from "modules/Core/core-modules/CoreModuleState/utils/cells";
import { CAMPAIGNS } from "../../consts";
import { call } from "redux-saga/effects";
import { setCampaignMembershipInformation } from "modules/Campaigns/middleware";

export default initCell(CAMPAIGNS, {
  name: "setCampaignMembershipInformation",
  selectors: {
    isSuccess: (state) =>
      state.isSetCampaignMembershipInformationSuccess,
  },
  sagas: {
    latest: function* ({ payload }) {
      const res = yield call(
        setCampaignMembershipInformation,
        payload?.campaignId,
        payload?.input
      );
      const isSuccess = Boolean(res?.setCampaignMembershipInformation?.id);
      return isSuccess;
    },
    onCellSuccess: true
  },
  successCell: {
    reducer: (state, { payload }) => {
      state.isSetCampaignMembershipInformationSuccess = true;
    },
  }
});
