import { useCallback, useState } from "react";
import Button from "modules/Core/components/ui-kit/Button";
import Container from "modules/Core/components/ui-kit/Container";
import Input from "modules/Core/components/ui-kit/Input";
import useDispatchWithLoader from "modules/Core/hooks/useDispatchWithLoader";
import { createEntity } from "../../state/cells";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import { fetchFeed } from "../../state/cells";
import strapped from "modules/Core/higher-order-components/strapped";
import styles from "./styles.module.scss";

const CreateCommentComponent = ({
  postId,
  campaignId,
  actionId,
  onSuccess,
  comment,
  setComment,
  onComment,
  loading,
  ...props
}) => {
  return (
    <Container
      className={styles.container}
      flex
      column
      flexEnd
      alignEnd
      {...props}
    >
      <Input
        value={comment}
        onChange={setComment}
        rows={3}
        placeholder={"add your comments..."}
        textarea
      />
      <Button onClick={onComment} loading={loading}>
        Comment
      </Button>
    </Container>
  );
};

const useCreateComment = ({ postId, campaignId, actionId, onSuccess }) => {
  const [comment, setComment] = useState();

  const callback = useCallback(
    (entity) => {
      if (!entity?.id) return null;
      setComment("");
      onSuccess?.(entity);
      dispatch(fetchFeed.action({ renewStream: true }));
    },
    [setComment, onSuccess]
  );

  const [action, loading, dispatch] = useDispatchWithLoader(
    createEntity.action,
    { callback: callback }
  );
  const onComment = useCallback(() => {
    action({
      text: comment,
      campaign: campaignId,
      parentId: postId,
      actionId,
    });
  });
  return {
    onComment,
    comment,
    setComment,
    loading,
  };
};

const CreateComment = strapped(CreateCommentComponent, useCreateComment);

export default withShouldRender(CreateComment);
