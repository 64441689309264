import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "../../middleware";
import { P4P_TEAM } from "../../consts";

const { fetchFeed, setP4PTeamMember } = middleware.ops;

export default new FeedModule({
    name: P4P_TEAM,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchFeed,
        },
        createEntityCell: {
            requestHandler: setP4PTeamMember
        }
    }
});