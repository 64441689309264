import useNotary from "modules/Payments/hooks/useNotary";
import { NOTARIES } from "modules/Payments/consts";
import Container from "modules/Core/components/ui-kit/Container"

const SubscriptionPayment = ({ clientSecret }) => {
    const { jsx } = useNotary(NOTARIES.STRIPE, null, { clientSecret });
    return (
        <Container p3>
            {jsx}
        </Container>
    )
}

export default SubscriptionPayment;