import fetchNestedFeedCell from "modules/Core/state/reusableCells/fetchNestedFeedCell";
import { OBJECTIVES } from "modules/Objectives/consts";
import middleware from "modules/Objectives/middleware";

const fetchActiveObjectivesFeed = fetchNestedFeedCell(
    OBJECTIVES,
    {
        nfk: "active",
        requestHandler: middleware.ops.fetchActiveObjectivesFeed,
    },
);

export default fetchActiveObjectivesFeed;