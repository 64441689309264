import strapped from "modules/Core/higher-order-components/strapped";
import feed from "../../state/feed";
import { useMemo } from "react";
import FeedItem from "modules/Core/components/Feed/FeedItem";

const { FeedComponent } = feed;

const BudgetTransactionsFeed = ({ filters, feedItemClassName }) => (
    <FeedComponent
        filters={filters}
        feedItemClassName={feedItemClassName}
        ItemComponent={({name, price, ...props}) => <FeedItem name={`${name} - £${price}`}  {...props} />}
    />
)

export default strapped(
    BudgetTransactionsFeed,
    ({ budgetId }) => ({
        filters: useMemo(() => ({ budgetId }), [budgetId])
    })
);