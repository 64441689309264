import Container from 'modules/Core/components/ui-kit/Container';
import Feature from 'modules/Core/components/ui-kit/Feature';
import Widget from 'modules/Core/components/ui-kit/Widget';
import Button from 'modules/Core/components/ui-kit/Button';
import Input from 'modules/Core/components/ui-kit/Input';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import { setFilters } from 'modules/Campaigns/state/cells';
import { fetchCategoryTags } from 'modules/Common/state/cells';
import { useOnLoad } from 'modules/Core/hooks/useOnLoad';
import styles from './styles.module.scss'

const CampaignsFilterWidget = ({ setFilter, filters, tags, ...props }) => {
    return (
        <Widget label={"Filter Campaigns"}  {...props}>
            <Container flex col>
                <Feature
                    className={styles.filterFeature}
                    name={'Search Campaigns'}
                    content={<Input value={filters?.search} text onChange={val => setFilter('search', val)} placeholder={"type here ..."} />}
                    TitleElement='h4'
                    flex
                    col
                    spaceBetween
                    maxWidth
                />
                <Feature
                    className={styles.filterFeature}
                    name={'Hide Subcampaigns'}
                    content={(
                        <Input 
                            value={filters?.isRootCampaign} 
                            onChange={val => setFilter('isRootCampaign', val)} 
                            switch 
                        />
                    )}
                    TitleElement='h4'
                    flex
                    col
                    spaceBetween
                />
                <Feature
                    className={styles.filterFeature}
                    name={'Categories'}
                    content={
                        <Container className={styles.tagsContainer} flex maxWidth>
                            {tags?.map(tag => <Button className={filters?.categoryTags?.includes(tag?.id) && styles.selected} key={tag?.id} text={tag.name} onClick={() => setFilter('categoryTags', [...(filters?.categoryTags ?? []), tag.id])} />)}
                        </ Container>
                    }
                    TitleElement='h4'
                    col
                    flex
                    spaceBetween
                />
            </Container>
        </Widget>
    )
}

export default strappedConnected(
    CampaignsFilterWidget,
    {
        filters: setFilters.selectors.getFilters,
        tags: fetchCategoryTags.select
    },
    {
        setFilters: setFilters.action,
        fetchTags: fetchCategoryTags.action
    },
    ({ setFilters, fetchTags, tags }) => {
        useOnLoad(
            () => fetchTags(),
            !Boolean(tags?.length)
        )
        return ({
            setFilter: (key, value) => setFilters({ [key]: value, shouldRefetch: true }),
        })
    }
);