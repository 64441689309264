import withFormValidation from "modules/Core/higher-order-components/withFormValidation";
import { schema } from "./schema";
import Form from "modules/Core/components/form/Form";
import Modal from "modules/Core/components/ui-kit/Modal";
import styles from "./styles.module.scss";

const CreateAction = ({ isOpen, close, ...props }) => (
    <Modal isOpen={isOpen} onClose={close}>
        <Form
            className={styles.form}
            schema={schema}
            header={"Register a new campaign action"}
            {...props}
        />
    </Modal>
)

export default withFormValidation(CreateAction, schema);