import feed from "../feed";
import approvePerspectiveCampaign from "./approvePerspectiveCampaign";
import joinCampaign from "./joinCampaign";
import setCampaignMembershipInformation from "./setCampaignMembershipInformation";
import setFormField from "./setFormField";
import setFormState from "./setFormState";

export const {
  setFilters,
  createEntity,
  fetchFeed,
  fetchEntity,
  removeEntity,
} = feed.cells;

export default {
  createEntity,
  fetchFeed,
  fetchEntity,
  joinCampaign,
  setFilters,
  approvePerspectiveCampaign,
  setCampaignMembershipInformation,
  setFormField,
  setFormState,
  removeEntity,
};
