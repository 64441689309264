import React from "react";
import { useNavigate } from "react-router-dom";
import EntityForm from "../../../../Core/components/form/EntityForm";
import { useSuccessModal } from "modules/Core/hooks/useSuccessModal";
import { useDispatch } from "react-redux";
import {createEntity} from "modules/Posts/state/cells";

const CreatePost = ({ entity  }) => {
    const navigate = useNavigate();
    const { modal, openSuccessModal } = useSuccessModal({ onClose: () => navigate('/feed') })
    const dispatch = useDispatch();
    const handleSubmit = async (data) => {
        try {
            dispatch(createEntity.action(data));
            // const res = await upsertEntity(data, entity?.id);
            // if (res.status === 200) openSuccessModal()
        } catch (e) { }
    };

    return (
        <>
            <EntityForm entity={entity} handleSubmit={handleSubmit} />
            {modal}
        </>
    )

};

export default CreatePost;

