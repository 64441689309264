import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";    
import cells from "./state/cells";
import { COMMENTS } from "./consts";

const Comments = new CoreModule({
    name: COMMENTS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
});

export default Comments;