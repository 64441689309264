import Container from "modules/Core/components/ui-kit/Container"
import Screen from "modules/Core/components/ui-kit/Screen";
import { useParams } from "react-router-dom";
import useDispatchWithLoader from "modules/Core/hooks/useDispatchWithLoader";
import fetchSharedPost from "modules/Posts/state/cells/fetchSharedPost";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import { useSelector } from "react-redux";
import ViewPost from "../../ViewPost";
import styles from "./styles.module.scss";

const ViewSharedPost = () => {
    const { id } = useParams();
    const [action] = useDispatchWithLoader(fetchSharedPost.action);
    useOnLoad(() => action({ id }), !!id, [id]);
    const post = useSelector(fetchSharedPost.selector(id));
    return (
        <Screen center alignCenter flex>
            <Container relative flex center>
                <ViewPost 
                    className={styles.postContainer} 
                    textClassName={styles.postText}
                    post={post} 
                    comments={false}
                />
            </Container>
        </Screen>
    )
};

export default ViewSharedPost;