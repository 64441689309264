import feed from "../feed";


export const {
    fetchFeed,
    setFilters,
    fetchEntity,
    createEntity
} = feed.cells;



export default {
    fetchFeed,
    setFilters,
    fetchEntity,
    createEntity
}