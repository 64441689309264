import cx from 'classnames';
import FeedItem from "modules/Core/components/Feed/FeedItem";
import styles from "./styles.module.scss";
import CommentActions from '../CommentActions';

const CommentEntity = ({ className, ...props }) => {
    return (
        <FeedItem
            className={cx(className, styles.container)}
            header={false}
            textProps={{
                className: styles.text,
                translatable: true
            }}
            {...props}
        >
            <CommentActions id={props.id} />
        </FeedItem>
    )
}

export default CommentEntity;