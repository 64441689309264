import FeedModule from "modules/Core/core-modules/FeedModule";
import { createCampaignMaintainerApplication, fetchMaintainerApplications } from "../../middleware";
import { CAMPAIGN_MAINTAINER_APPLICATIONS } from "../../consts";

export default new FeedModule({
    name: CAMPAIGN_MAINTAINER_APPLICATIONS,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchMaintainerApplications
        },
        createEntityCell: {
            requestHandler: createCampaignMaintainerApplication,
            successMessage: "Successfully sent application."
        }
    }
});