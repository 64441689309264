import { gql } from "graphql-request";
import { graphqlClient } from "modules/Core/middleware";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";

const query = gql`
	query comments($postId: String!, $feedParams: FeedParams) {
		feed: comments(postId: $postId, feedParams: $feedParams)  {
			entities {
				id
				text
			}
			${FEED_INFO_FRAGMENT}
		}
	}
`;

const actionCommentQuery = gql`
	query posts($actionId:String) {
		feed: posts(actionId: $actionId){
		entities{
			id
			text
		}
		${FEED_INFO_FRAGMENT}
		}
	}
`;

const mutation = gql`
  mutation createComment($input: PostInput!, $parentId: String, $id: String) {
    entity: setComment(input: $input, parentId: $parentId, id: $id) {
      id
    }
  }
`;

export const createComment = async ({ parentId, ...input }) => {
  return graphqlClient.request(mutation, { input, parentId });
};
export const fetchComments = async (params) => {
  const q = params?.actionId ? actionCommentQuery : query;
  return graphqlClient.request(q, params);
};