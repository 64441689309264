import { useNavigate } from "react-router-dom";
import strapped from "modules/Core/higher-order-components/strapped";
import ExpandableCard from "modules/Core/components/ui-kit/Card/ExpandableCard";
import IdentityCard from "modules/Core/components/ui-kit/IdentityCard";
import styles from "./styles.module.scss";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";

const EC = withShouldRender(ExpandableCard);

export const TeamMembersCard = ({ firstName, lastName, photo, bio, ...props }) => (
    <IdentityCard
        name={`${firstName} ${lastName}`}
        image={photo}
        className={styles.container}
        children={
            <EC
                shouldRender={Boolean(bio)}
                label={bio?.substring(0, 100)}
                openClassName={styles.openContainer}
                className={styles.bio}
                borderless
                large
                maxWidth
            >
                {bio?.length > 100 && bio}
            </EC>
        }
        {...props}
    />
)

export default strapped(
    TeamMembersCard,
    (item) => {
        const navigate = useNavigate()
        return ({
            onClick: () => navigate(`/partner/profile/${item?.partner?.id}`),
            firstName: item?.userInformation?.firstName,
            lastName: item?.userInformation?.lastName,
            photo: item?.userInformation?.photo,
            bio: item?.bio,
            roles: item?.roles,
        })
    }
)