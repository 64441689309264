import FeedItem from "modules/Core/components/Feed/FeedItem";
import styles from "./styles.module.scss";
import ProgressBar from "modules/Core/components/ui-kit/ProgressBar";
import Features from "modules/Core/components/ui-kit/Features";

const BudgetFeedItem = ({name, amount, amountRaised, children, ...props}) => {
    return (
        <FeedItem 
            {...props}
            name={name} 
            className={styles.feedItem} 
            relative
        >
            <ProgressBar circle min={amountRaised} max={amount} flex center  />
            <Features
                features={[
                    {name: "Amount Raised:", content: `£${amountRaised ?? 0}`},
                    {name: "Total: ", content: `£${amount}`}
                ]}
                row
                absolute 
                bottom
                className={styles.features}
            />
            {children}
        </FeedItem>
    )
}


export default BudgetFeedItem;