import { useMemo } from "react";
import strapped from "modules/Core/higher-order-components/strapped";
import feed from "../../state/feed";
import BudgetItemDetailsModal from "../BudgetItemDetailsModal";
import useFocusedId from "modules/Core/hooks/useFocusedId";

const { FeedComponent } = feed;

const BudgetItemsFeed = ({ filters, focusedId, feedItemClassName, closeModal, onClick, budgetId }) => (
    <>
        <FeedComponent
            feedItemClassName={feedItemClassName}
            filters={filters}
            onClick={onClick}
        />
        <BudgetItemDetailsModal
            isOpen={Boolean(focusedId)}
            onClose={closeModal}
            id={focusedId}
            budgetId={budgetId}
        />
    </>

)

export default strapped(
    BudgetItemsFeed,
    ({ budgetId }) => {
        const { focusedId, focus, unFocus } = useFocusedId(null);
        return {
            filters: useMemo(() => ({ budgetId }), [budgetId]),
            focusedId,
            closeModal: unFocus,
            onClick: focus
        }
    }
);