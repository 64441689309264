import { useMemo } from "react";
import { fetchEntity } from "modules/Campaigns/state/cells";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import FundraisingScreen from "modules/Payments/components/FundraisingScreen"
import { useFormatCampaignFeatureDetails } from "../../CampaignDetails/CampaignDetailsTab";

export default strappedConnected(
    FundraisingScreen,
    {
        campaign: (state, { id }) => fetchEntity.selector(id)(state)
    },

    {
        fetchEntity: (id) => fetchEntity.action({ id })
    },
    ({ fetchEntity, campaign, id, }) => {
        useOnLoad(
            () => fetchEntity(id),
            !Boolean(campaign?.details),
            [id]
        )
        return ({
            details: [],
            title: 'Support ' + campaign?.name,
            header: "Donate to " + campaign?.name,
            visionStatement: campaign?.vision,
            details: useFormatCampaignFeatureDetails(campaign),
            feedParams: useMemo(() => ({
                campaignId: id,
            })),
            back: useMemo(() => ({
                text: 'To Campaign',
                to: `/campaigns/${id}`
            }), [id])
        })
    }
);