import { useMemo } from "react";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import Link from "modules/Core/components/ui-kit/Link";
import Features from "modules/Core/components/ui-kit/Features";
import { TRACKING_EVENTS } from "modules/Core/sub-modules/Tracking/consts";
import { fetchEntity } from "modules/Partners/state/cells";
import Tag from "modules/Core/components/ui-kit/Tag";
import PartnerMembers from "../PartnerMembers";
import styles from './styles.module.scss';

const websiteTracking = {
    name: TRACKING_EVENTS.PARTNER_PROFILE_WEBSITE_CLICK,
    useParseParams: ({ partnerId, }) => ({ partnerId })
};

export const useFormatPartnerInfoFeatures = ({ partnerInfo, id }) => useMemo(() => ([
    { name: "Bio", content: partnerInfo?.bio },
    { name: "Background", content: partnerInfo?.background },
    { name: "Mission Statement", content: partnerInfo?.mission },
    {
        name: "Website", content: (
            <Link
                tracking={websiteTracking}
                to={partnerInfo?.website}
                partnerId={id}
                external
            >
                {partnerInfo?.website}
            </Link>
        )
    },
    Boolean(partnerInfo?.links?.length) && {
        name: "Links",
        content: partnerInfo?.links,
        renderContent: (links) => links?.map((link, i) => <Link card key={i} link={link} />),
    },
    { 
        name: 'Members', content: <PartnerMembers className={styles.members} partnerId={id} /> 
    },
    {
        name: 'Location/s',
        content: partnerInfo?.location?.length && partnerInfo?.location?.map((location) => <Tag key={location?.countryCode}>{location?.city}</Tag>),
    },
    {
        name: 'Tags',
        content: partnerInfo?.tags?.length && partnerInfo?.tags?.map((tag) => <Tag key={tag?.key}>{tag?.name}</Tag>),
    },
]), [partnerInfo])

export default strappedConnected(
    Features,
    {
        partnerInfo: (state, { partnerId }) => fetchEntity.selectField(partnerId, 'info')(state),
    },
    null,
    ({ partnerInfo, partnerId }) => ({
        features: useFormatPartnerInfoFeatures({
            partnerInfo,
            id: partnerId,
        }),
        card: true
    }));