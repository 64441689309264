import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "../../middleware";
import { CAMPAIGN_PARTNERSHIPS } from "../../consts";

const { fetchFeed, registerTeamMember } = middleware.ops;

export default new FeedModule({
    name: CAMPAIGN_PARTNERSHIPS,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchFeed
        },
        createEntityCell: {
            requestHandler: registerTeamMember
        }
    }
});