import ExternalScreen from "modules/Core/components/ui-kit/Screen/ExternalScreen"
import Card from "modules/Core/components/ui-kit/Card";
import Title from "modules/Core/components/ui-kit/Title";
import Text from "modules/Core/components/ui-kit/Text";
import Container from "modules/Core/components/ui-kit/Container";
import copy from "./microcopy.json";
import styles from "./styles.module.scss"

const OurCommitmentScreen = ({ }) => {
    return (
        <ExternalScreen className={styles.container}>
            <Card className={styles.card} contentClassName={styles.content}>
                <Container>
                    <Title>Mission Statement</Title>
                    <Text italic>{copy?.text}</Text>
                    <Title>Commitment</Title>
                    <Text italic>{copy?.commitment}</Text>
                </Container>
                <Container flex flexEnd>
                    <Text>{copy?.period}</Text>
                </Container>
            </Card>
        </ExternalScreen>
    )
}

export default OurCommitmentScreen;