import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { gql } from "graphql-request";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";
import { P4P_VALUES } from "../consts";

const fetchFeed = gql`
    query fetchP4PValues($feedParams: FeedParams) {
        feed: P4PValues(feedParams: $feedParams) {
            entities {
                text
                intent
                id
            }
            ${FEED_INFO_FRAGMENT}
        }
    }
`;
export const FETCH_P4P_VALUES_QUERY = fetchFeed;


const operations = {
    fetchFeed,
}

export default new MiddlewareModule({
    name: P4P_VALUES,
    operations,
    operationsConfig: {
        fetchEntity: {
            paramsParser: (id) => ({ id }),
        }
    }
});

