import React, { useMemo } from 'react';
import strapped from 'modules/Core/higher-order-components/strapped';
import { useDispatch } from 'react-redux';
import { useOnLoad } from 'modules/Core/hooks/useOnLoad';
import { fetchEntity } from 'modules/Campaigns/state/cells';
import Container from 'modules/Core/components/ui-kit/Container'
import RegisterCampaignFinancialsAccountButton from 'modules/Campaigns/sub-modules/campaign-financials/components/RegisterCampaignFinancialsAccountButton';
import MaintainerApplicationsFeed from 'modules/Campaigns/sub-modules/campaign-maintainers/submodules/applications/components/MaintainerApplicationsFeed';
import _CreateCampaignButton from '../../CreateCampaignButton';
import ProspectiveCampaignsCard from '../../CampaignCommand/ProspectiveCampaignsCard';
import CurrentObjectivesCard from '../../CampaignCommand/CurrentObjectivesCard';
import withMaintainerOnly from 'modules/Campaigns/higher-order-components/withMaintainerOnly';
import CampaignScreen from '../../CampaignScreen';
import Widget from 'modules/Core/components/ui-kit/Widget';
import CampaignMembershipWidget from '../../widgets/CampaignMembershipWidget';
import ObjectivesTab from './ObjectivesTab';
import CampaignFoundingPartnerWidget from '../../widgets/CampaignFoundingPartnerWidget';
import CampaignPartnershipInvitationsFeed from 'modules/Partners/submodules/campaign-partnership-invitations/components/CampaignPartnershipInvitationsFeed';
import { CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS } from 'modules/Partners/submodules/campaign-partnership-invitations/consts';
import SendPartnershipInvitation from '../../widgets/SendPartnershipInvite';

const tabs = [
    { title: 'Prospective Sub-Campaigns', Component: ({ id }) => <ProspectiveCampaignsCard Container={Container} campaignId={id} /> },
    { title: 'Maintainer Applications', Component: ({ id }) => <MaintainerApplicationsFeed campaignId={id} /> },
    // TO BE IMPLEMENTED: 
    // { title: 'Maintainer Posts', Component: CampaignsPosts },
    { title: 'Prospective Objectives', Component: ObjectivesTab },
    {
        title: 'Partnership Invitations', Component: ({ id }) => (
            <CampaignPartnershipInvitationsFeed campaignId={id} itemProps={{ viewingAs: CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS.CAMPAIGN }} />
        )
    }
];

const leftWidgets = [
    {
        Component: CampaignFoundingPartnerWidget, gridRow: '2',
    },
    {
        Component: CampaignMembershipWidget,
        gridRow: '7'
    },
    {
        Component: ({ campaignId, ...props }) => <CurrentObjectivesCard label={'Current Objectives'} Container={Widget} campaignId={campaignId} {...props} />,
        gridRow: '4/5'
    }
]

const rightWidgets = [
    {
        Component: ({ campaignId, ...props }) =>
            <RegisterCampaignFinancialsAccountButton {...props} campaignId={campaignId} panel widget />, gridRow: '2'
    },
    // {
        // Component: ({ campaignId, ...props }) => (
        //     <EditCampaignButton
        //         widget
        //         panel
        //         id={campaignId}
        //         {...props}
        //     />
        // ),
        // gridRow: 1
    // },
    {
        Component: SendPartnershipInvitation,
        gridRow: 1,
        tabIndexFilter: 3
    }
]

const Component = ({ id }) => {
    const back = useMemo(() => ({
        to: `/campaigns/${id}/campsite`,
        text: "To Campsite"
    }), [id]);

    return (
        <CampaignScreen
            header={'Campaign Command Center'}
            id={id}
            tabs={tabs}
            rightWidgets={rightWidgets}
            leftWidgets={leftWidgets}
            back={back}
            tripanel
        />
    )
}

export default strapped(
    withMaintainerOnly(Component),
    ({ id }) => {
        const dispatch = useDispatch();
        useOnLoad(() => dispatch(fetchEntity.action({ id })), Boolean(id), [id]);
    }
);
