import { useSelector } from "react-redux";
import c from 'classnames'
import {fetchEntity} from "modules/Campaigns/state/cells";
import styles from "./styles.module.scss";

const CampaignPhoto = ({ id, ...props }) => {
    const photo = useSelector(fetchEntity?.selectField(id, "photo"));
    return photo && <CampaignPhoto.Component photo={photo} {...props} />
}

CampaignPhoto.Component = ({ photo, className }) => (
    <img src={photo} className={c(styles.photo, className)} />
)


export default CampaignPhoto;
