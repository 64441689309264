import ClickableCard from "modules/Core/components/ui-kit/Button/ClickableCard";
import Container from "modules/Core/components/ui-kit/Container";
import { BUSINESS_PARTNERSHIP_OPTIONS } from "modules/Partners/submodules/business-partnerships/consts";
import Title from "modules/Core/components/ui-kit/Title";
import styles from "./styles.module.scss";

const BusinessPartnershipTiers = ({ setFocusedTier, focusedTier, ...props }) => (
    <Container flex row center spaceEvenly {...props}>
        {options.map(({ title, key, amount }) => (
            <ClickableCard
                col
                header={
                    <Container col>
                        <Title>{title}</Title>
                    </Container>
                }
                onClick={() => setFocusedTier(key)}
                className={styles.card}
                contentContainerProps={{ col: true, flex: true }}
                headerProps={{ center: true }}
            >
                <p>{(focusedTier === key && "*")}{amount} GBP{(focusedTier === key && "*")}</p>
            </ClickableCard>
        ))}
    </Container>
);

const options = [
    {
        title: "Basic Tier",
        key: BUSINESS_PARTNERSHIP_OPTIONS.BASIC,
        amount: 50
    },
    {
        title: "Advanced Tier",
        key: BUSINESS_PARTNERSHIP_OPTIONS.ADVANCED,
        amount: 100
    },
    {
        title: "Premium Tier",
        key: BUSINESS_PARTNERSHIP_OPTIONS.PREMIUM,
        amount: 250
    }
];

export default BusinessPartnershipTiers;