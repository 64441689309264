import { gql } from "graphql-request"
import { graphqlClient } from "modules/Core/middleware";

const mutation = gql`
	mutation createPost($input: PostInput!, $id: String){
		entity: setPost(input: $input, id: $id) {
			id
		}
	}
`

export const createPost = async (input, id) => {
	return graphqlClient.request(mutation, { input, id });
}