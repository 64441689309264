import { call, put } from 'redux-saga/effects'

import { initCell } from '../../../Core/core-modules/CoreModuleState/utils/cells'
import { AUTH } from '../../consts'
import { validateLoginCode } from 'modules/Auth/middleware';
import { saveToken } from 'modules/Core/utils/auth';
import { graphqlClient } from 'modules/Core/middleware';
import authorizeToken from './authorizeToken';

const loginValidation = initCell(AUTH, {
    name: "loginValidation",
    reducer: (state) => {
        state.isLoading = true;
    },
    sagas: {
        latest: function* ({ payload }) {
            const res = yield call(validateLoginCode, payload?.phoneNumber, payload?.code)
            const token = res?.validateCode?.token
            if (Boolean(token)) {
                saveToken(token)
                graphqlClient.setAuthToken(token)
                yield put(loginValidation?.onSuccess(token))
                yield put(authorizeToken?.action())
                
            }
            return Boolean(token)
        },
        onError: "Error validating code"
    },
    successCell: {
        reducer: (state, { payload }) => {
            state.isLoading = false;
            state.isAuthorized = Boolean(payload);
        },
    }
});

export default loginValidation;
