import Form from "modules/Core/components/form/Form/index.jsx";
import schema from "./schema.js";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { createEntity } from "../../state/cells/index.js";


const RegisterPartnerTeamMemberForm = ({ onSubmit, }) => {
    return (
        <>
            <Form
                schema={schema}
                onSubmit={onSubmit}
                success={{
                    text: "Team Member Registered Successfully!"
                }}
                parseIsSuccess={(result) => Boolean(result?.registerPartnerTeamMember?.bio)}
            />
        </>
    )
};

export default strappedConnected(
    RegisterPartnerTeamMemberForm,
    {},
    {
        onSubmit: ({ bio, partnerId, ...userInformation }, callback) => createEntity.action({
            input: {
                userInformation,
                bio,
                partnerId
            },
            callback
        },)
    },
    ({ partnerId, onSubmit }) => ({
        onSubmit: (input, callback) => onSubmit({ ...input, partnerId }, callback)
    })
);