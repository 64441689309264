import { useMemo } from 'react';
import Screen from 'modules/Core/components/ui-kit/Screen';
import PartnersFeed from '../../PartnersFeed';
import PartnerFiltersWidget from '../../widgets/PartnerFiltersWidget';
import DonationsWidget from 'modules/Payments/components/DonationsWidget';

const leftWidgets = [
    {
        Component: PartnerFiltersWidget,
        gridRow: '3/4'
    }
];

const rightWidgets = [
    {
        Component: DonationsWidget,
        gridRow: '3/4'
    }
];

const PartnersFeedScreen = ({ }) => {

    const lProps = useMemo(() => ({
        widgets: leftWidgets
    }), []);

    const rProps = useMemo(() => ({
        widgets: rightWidgets
    }), []);

    return (
        <Screen
            header={'Partner Organisations'}
            MainContentComponent={PartnersFeed}
            leftProps={lProps}
            rightProps={rProps}
            tripanel
        />
    )
}

export default PartnersFeedScreen;