import React from 'react';
import { useParams } from 'react-router-dom';
import Screen from 'modules/Core/components/ui-kit/Screen';
import JudgementDetails from '../../JudgementDetails';
import styles from "./styles.module.scss";

const ViewJudgement = () => {
    let { id } = useParams();
    return <ViewJudgement.Component  id={id} />
}

ViewJudgement.Component = ({id }) => (
    <Screen header={"Judgement Details"}>
        <JudgementDetails className={styles.container} id={id} />
    </Screen>
);

export default ViewJudgement;