import Button from "modules/Core/components/ui-kit/Button";
import Input from "modules/Core/components/ui-kit/Input";
import Container from "modules/Core/components/ui-kit/Container";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import styles from "./styles.module.scss";
import NextButton from "modules/Core/components/ui-kit/Button/NextButton";

const TitheSwitch = withShouldRender(({ label, ...props }) => <Input {...props} switch label={label} />);

const PaymentCardAmountInput = ({ amount, setAmount, next, tithingOptions, tithe, setTithe, className, label = 'Enter Amount', nextButtonText = 'Continue', placeholder }) => {
    return (
        <Container className={className} center col flex alignCenter p1>
            <Input
                number
                value={amount}
                onChange={setAmount}
                label={label}
                placeholder={placeholder}
            />
            <Container maxWidth flexEnd flex alignCenter >
                <NextButton text={nextButtonText} onClick={next} />
                <TitheSwitch
                    label={tithingOptions?.label}
                    shouldRender={Boolean(tithingOptions)}
                    onChange={(e) => setTithe(e?.target?.checked)}
                    checked={tithe}
                    containerClassName={styles.titheSwitch}
                />
            </Container>
        </ Container>
    )
};


export default PaymentCardAmountInput;