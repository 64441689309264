import cx from "classnames";
import Container from "modules/Core/components/ui-kit/Container";
import Text from "modules/Core/components/ui-kit/Text";
import styles from "./styles.module.scss";

const Action = ({ Indicator, isActive, count, action, className }) => (
    <Container relative flex className={cx(styles.container, className)}>
        <Indicator
            className={isActive && styles.active}
            onClick={action}
        />
        {Boolean(count) && <Text text={count} />}
    </Container>
)

export default Action;