import { useMemo } from "react";
import feed from "modules/Budgets/state/feed";
import BudgetFeedItem from "./BudgetFeedItem";
import strapped from "modules/Core/higher-order-components/strapped";
import styles from "./styles.module.scss";
import Container from "modules/Core/components/ui-kit/Container";
import Button from "modules/Core/components/ui-kit/Button";

const { FeedComponent } = feed;

const LoadMoreComponent = ({loadMore, shouldRender}) => shouldRender && (
    <Button 
        onClick={loadMore} 
        className={styles.loadMore}
        card
        >
        Load More
    </Button>
)

const BudgetsFeed = ({ onItemClick, filters }) => (
    <Container className={styles.container}>
        <FeedComponent
            ItemComponent={BudgetFeedItem}
            onClick={onItemClick}
            filters={filters}
            LoadMoreComponent={LoadMoreComponent}
        />
    </Container>
)

export default strapped(
    BudgetsFeed,
    ({ campaignId }) => ({
        filters: useMemo(() => ({ campaignId }), [campaignId])
    })
);