import { fetchEntity } from 'modules/Partners/state/cells';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import withShouldRender from 'modules/Core/higher-order-components/withShouldRender';
import Button from 'modules/Core/components/ui-kit/Button';


export default strappedConnected(
    withShouldRender(Button),
    {
        partnerName: (state, { partnerId }) => fetchEntity.selectField(partnerId, 'name')(state)
    },
    null,
    ({ associated, partnerName }) => ({
        text: `Launch Campaigns as '${partnerName}'`,
        to: `/campaigns/create?asPartner=true`,
        shouldRender: Boolean(associated),
        widget: true
    })
);