import FeedModule from "modules/Core/core-modules/FeedModule";
import { fetchMaintainers } from "../../middleware";
import { CAMPAIGN_MAINTAINERS } from "../../consts";

export default new FeedModule({
    name: CAMPAIGN_MAINTAINERS,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchMaintainers
        },
    }
});