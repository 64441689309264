import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import Container from "modules/Core/components/ui-kit/Container";
import BusinessPartnershipsFeed from "../BusinessPartnershipsFeed";
import FeedItem from 'modules/Core/components/Feed/FeedItem';
import strapped from 'modules/Core/higher-order-components/strapped';
import styles from "./styles.module.scss";
import PartnerIdentificationCard from 'modules/Partners/components/PartnerIdentificationCard';
import Title from 'modules/Core/components/ui-kit/Title';


const ItemComponent = ({ name, info, onClick, id }) => (
    <FeedItem
        className={styles.bannerItem}
        header={false}
        onClick={() => onClick(id)}
    >
        <PartnerIdentificationCard name={name} image={info.image} className={styles.identificationCard}/>
    </FeedItem>
);


const BusinessPartnershipsBanner = ({ className, onClick }) => (
    <Container className={cx(className, styles.container)} flex row alignCenter>
        <Title>P4P's Business Sponsors - Support Those Who Support Us!</Title>
        <BusinessPartnershipsFeed ItemComponent={ItemComponent} onClick={onClick} />
    </Container>
);

export default strapped(BusinessPartnershipsBanner,
    () => {
        const navigate = useNavigate();
        const onClick = useCallback(({id}) => navigate(`/partner/profile/${id}`), [navigate]);
        return {
            onClick,
        }
    }
);
