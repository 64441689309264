import Form from "modules/Core/components/form/Form";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import SubscriptionPayment from "./SubscriptionPayment";
import { createEntity } from "../../state/cells";
import { useCallback, useMemo, useState } from "react";
import { set } from "modules/Core/utils/obj";
import authorizeToken from "modules/Auth/state/cells/authorizeToken";
import ConditionalContainer from "modules/Core/components/ui-kit/ConditionalContainer";
import schema from "./schema";
import styles from "./styles.module.scss";

const BusinessPartnershipForm = ({ onSubmit, schema, clientSecret, jsx }) => (
    <>
        <ConditionalContainer shouldRender={Boolean(!clientSecret)}>
            <Form
                schema={schema}
                onSubmit={onSubmit}
                className={styles.form}
            />
        </ConditionalContainer>
        <ConditionalContainer shouldRender={Boolean(clientSecret)}>
            <SubscriptionPayment clientSecret={clientSecret} />
        </ConditionalContainer>
    </>
);

export default strappedConnected(
    BusinessPartnershipForm,
    {
        partner: authorizeToken.selectors.getPartner
    },
    {
        onSubmit: (val) => createEntity.action({ ...val })
    },
    ({ partner, onSubmit, tier, campaignId, ...props }) => {
        const [clientSecret, setClientSecret] = useState(null);

        const s = useMemo(() => {
            const s = { ...schema };
            set(s, "fields.name.value", partner?.name);
            return s;
        }, [partner?.name]);

        const callback = useCallback(async (result) => {
            const cs = await result?.createBusinessPartnership?.paymentIntent?.clientSecret;
            setClientSecret(cs);
        }, [setClientSecret]);

        return {
            schema: s,
            onSubmit: (val) => onSubmit({
                input: {
                    ...val,
                    partnerId: partner?.id,
                    tierKey: tier,
                    campaignId
                },
                callback
            }),
            clientSecret
        }
    }
);