import Container from "modules/Core/components/ui-kit/Container";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import Features from "modules/Core/components/ui-kit/Features";
import { fetchAnalyticsCell } from "modules/Partners/state/cells";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import styles from "./styles.module.scss";

const BusinessPartnerAnalyticsTab = ({ totalProfileViews, uniqueProfileVisitsCount, uniqueWebsiteVisitsCount, totalVisitsCount }) => (
    <Container className={styles.container}>
        <Features
            grid
            card
            features={[
                {
                    name: " Total Profile Views",
                    content: totalProfileViews
                },
                {
                    name: "Unique Profile Visits",
                    content: uniqueProfileVisitsCount
                },
                {
                    name: "Total Visits",
                    content: totalVisitsCount
                },
                {
                    name: "Unique Website Visits",
                    content: uniqueWebsiteVisitsCount
                }
            ]}
        />
    </Container>
);

export default strappedConnected(
    BusinessPartnerAnalyticsTab,
    { stats: (state) => fetchAnalyticsCell.selector(state) },
    { onLoad: (partnerId) => fetchAnalyticsCell.action({ partnerId }) },
    ({ stats, onLoad, partnerId }) => {
        useOnLoad(() => onLoad(partnerId), Boolean(partnerId), [partnerId]);
        return {
            totalProfileViews: stats?.profileVisitsCount ?? 100,
            uniqueProfileVisitsCount: stats?.uniqueProfileVisitsCount ?? 70,
            totalVisitsCount: stats?.websiteVisitsCount ?? 50,
            uniqueWebsiteVisitsCount: stats?.uniqueWebsiteVisitsCount ?? 30,
        }

    }
);

