import { useCallback, useMemo, useState } from 'react';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import CenteredCardScreen from 'modules/Core/components/ui-kit/Screen/CenteredCardScreen';
import Input from 'modules/Core/components/ui-kit/Input';
import Container from 'modules/Core/components/ui-kit/Container';
import NextButton from 'modules/Core/components/ui-kit/Button/NextButton';
import { createEntity } from 'modules/Posts/state/cells';
import { Download } from 'modules/Core/components/ui-kit/indicators';
import { DownloadPaymentReceiptButton } from '../../PaymentCard/PaymentSuccess';
import useOnSuccess from 'modules/Core/sub-modules/Dialog/hooks/useOnSuccess';
import ConditionalContainer from 'modules/Core/components/ui-kit/ConditionalContainer';
import Text from 'modules/Core/components/ui-kit/Text';
import Button from 'modules/Core/components/ui-kit/Button';
import { useIsAuthenticated } from 'modules/Auth/hooks/useConnectedDispatch';
import { useIsOpen } from 'modules/Core/hooks/useIsOpen';
import styles from './styles.module.scss';
import { useQueryParams } from 'modules/Core/hooks/useStripQueryParams';


const back = {
    to: '/',
    text: 'Back to Home'
};

const Component = ({
    isSuccess,
    text,
    setText,
    send,
    transactionId,
    isAuthenticated,
    displaySuccess,
    redirectUrl,
    ...props
}) => (
    <CenteredCardScreen {...props} >
        <Container className={styles.container} flex span spaceBetween col>
            <ConditionalContainer
                flex col spaceBetween span maxHeight
                shouldRender={isSuccess} className={styles.success}>
                <Container>
                    <Text>Thank you for your support!</Text>
                    <Text>
                        To go back to see the fundraising progress, click
                        <Button to={redirectUrl}>
                            here
                        </Button>
                        .
                    </Text>
                </Container>
                <Container flex alignCenter flexEnd maxWidth mt3>
                    <NextButton text={'View Campaigns'} to={'/campaigns/feed'} onClick={send} />
                </Container>
            </ConditionalContainer>
            <ConditionalContainer shouldRender={!isSuccess} className={styles.successMessage}>
                <Input
                    className={styles.input}
                    label={'Leave a Custom Message'}
                    onChange={setText}
                    value={text}
                    disabled={!isAuthenticated}
                    multiline
                    placeholder={!isAuthenticated && "you must be logged in to leave a message."}
                    rows={7}
                />
                <Container flex alignCenter spaceBetween mt3 >
                    <Button onClick={displaySuccess}>Skip</Button>
                    <NextButton disabled={!isAuthenticated} text={'Submit'} onClick={send} />
                </Container>
            </ConditionalContainer>
            <Container className={styles.receiptButtonContainer}>
                <DownloadPaymentReceiptButton
                    transactionId={transactionId}
                    rightIndicator={<Download />}
                    widget
                    noChevron
                />
            </Container>
        </Container>
    </CenteredCardScreen>
)

export default strappedConnected(
    Component,
    {},
    {
        createPost: ({ text, transactionId }, callback) => createEntity.action({ text, transactionId, callback })
    },
    ({
        transactionId,
        createPost,
    }) => {
        const [text, setText] = useState();
        const { open, isOpen: isSuccess, close } = useIsOpen();
        const isAuthenticated = useIsAuthenticated();
        const params = useQueryParams();

        const success = useOnSuccess();
        const callback = useCallback((res) => {
            success('Message added successfully');
            open();
        }, [, success]);


        const redirectUrl = useMemo(() => {
            let base = '/p4p';
            if (params?.campaignId) base = `/campaigns/${params.campaignId}`;
            if (params?.partnerId) base = `/partner/${params.partnerId}`;
            return base + '/fundraising';
        })

        const send = useCallback(() => Boolean(text?.length) && createPost({ text, transactionId }, callback), [text, transactionId, createPost, callback]);
        return {
            header: 'Donate',
            cardHeader: 'Donation Successful!',
            back,
            cardClassName: styles.card,
            isSuccess,
            text,
            setText,
            send,
            transactionId,
            isAuthenticated,
            displaySuccess: open,
            redirectUrl
        }
    }
);