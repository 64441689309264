import withPlatformMaintainerOnly from 'modules/Auth/higher-order-components/withPlatformMaintainerOnly';
import Button from 'modules/Core/components/ui-kit/Button';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import useOnSuccess from 'modules/Core/sub-modules/Dialog/hooks/useOnSuccess';
import { removeEntity } from 'modules/Partners/state/cells';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const copy = {
    title: 'Are you sure you want to delete this partner?',

};

const StrappedComponent = strappedConnected(Button,
    {},
    {
        removePartner: (id, callback) => removeEntity.action({
            id, callback
        })
    },
    ({ partnerId, removePartner }) => {
        const onSuccess = useOnSuccess();
        const navigate = useNavigate();
        const callback = useCallback(() => {
            onSuccess('Partner has been removed!');
            navigate('/partners');
        }, [onSuccess, navigate])
        return {
            text: 'Remove Partner',
            widget: true,
            modal: true,
            onConfirm: () => removePartner(partnerId, callback),
            copy
        }
})

export default withPlatformMaintainerOnly(StrappedComponent)