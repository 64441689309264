import feed from "../feed";
import stampApplicationCell from "../stampApplication";
import setCampaignMaintainerApplicationStatus from "./setCampaignMaintainerApplicationStatus";

export const {
    setFilters,
    fetchFeed,
    fetchEntity,
    createEntity,
} = feed.cells;

export default {
    fetchFeed,
    setFilters,
    fetchEntity,
    createEntity,
    setCampaignMaintainerApplicationStatus,
    stampEntity: stampApplicationCell
}