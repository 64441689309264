import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";
import { POSTS } from "./consts";
import routes from "./routes";
import cells from "./state/cells";

const Posts = new CoreModule({
    name: POSTS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default Posts;