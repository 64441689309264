import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { ALLIES } from "../consts";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";

const onboardPartnerMutation = `
    mutation onboardPartner($input: PartnerInput) {
        onboardPartner(input: $input) {
            name
            id
        }
    }
`;

const fetchEntity = `
    query fetchPartner($id: String) {
        entity: partner(id: $id) {
            name
            id
            info {
                image
                bio
                website
                background
                mission
                location {
                    city
                    countryCode
                }
                contactInformation {
                    fullName
                    email
                    phoneNumber
                }
                tags {
                    name
                    id
                    key
                }
                links {
                    name
                    url
                }
            }
            associated
            kind
            members {
                bio
                firstName
                lastName
                photo
            }
            financialsAccount {
                stamps {
                  created
                  approved
                }
            }
        }
    }
`;

const fetchFeed = `
query fetchPartners(
    $feedParams: FeedParams, 
    $kind: PartnerKind, 
    $search: String, 
    $categoryTags: [String] 
) {
    feed: partners(
        feedParams: $feedParams, 
        kind: $kind,
        search: $search,
        categoryTags: $categoryTags
    ) {
        entities {
            id
            name
            kind
            info {
                image
                bio
                location {
                    city
                    countryCode
                }

                tags {
                    name
                    id
                    key
                }
            }
        }
        ${FEED_INFO_FRAGMENT}
    }
}
`;

const fetchBalance = `
    query fetchPartnerBalance ($id: String) {
        partner(id: $id){
            financialsAccount{
                balance{
                    amount
                    currency
                    pendingAmount
                }
            }
        }
    } 
`;

const fetchAnalytics = `
    query analytics ($partnerId: String){
        entity: businessPartnerMetrics(businessPartnerId: $partnerId){
            profileVisitsCount
            uniqueProfileVisitsCount
            websiteVisitsCount
            uniqueWebsiteVisitsCount
        }
    }
`;

const removeEntity = `
    mutation removePartner($id: String!) {
        removePartner(id: $id)
    }
`;

const middleware = new MiddlewareModule({
  name: ALLIES,
  operations: {
    onboardPartner: onboardPartnerMutation,
    fetchEntity,
    fetchAnalytics,
    fetchBalance,
    fetchFeed,
    removeEntity,
  },
  operationsConfig: {
    fetchEntity: {
      paramsParser: (id) => ({ id }),
    },
    fetchAnalytics: {
      paramsParser: (partnerId) => ({ partnerId }),
    },
    removeEntity: {
        paramsParser: (id) => ({ id }),
    }
  },
});

export const { onboardPartner } = middleware.ops;

export default middleware;
