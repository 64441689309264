import React from 'react'
import Linkify from 'linkify-react'
import Text from '..';

const defaultOptions = {
    target: '_blank',
};

const LinkedText = ({ options = defaultOptions, target = "_blank", linked, ...props }) => (
    <Linkify target={target} options={options} as={Text} {...props} />
)

export default LinkedText