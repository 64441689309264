import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import { fetchEntity } from 'modules/Partners/state/cells';
import ContactInformationWidget from 'modules/Core/components/ui-kit/Widget/ContactInformationWidget';
import { withLoader } from 'modules/Core/higher-order-components/withLoader';
import withShouldRender from 'modules/Core/higher-order-components/withShouldRender';
import styles from './styles.module.scss';

const Component = withShouldRender(ContactInformationWidget)
export default strappedConnected(
    withLoader(Component, fetchEntity.selectors.getIsLoading),
    { contactInformation: (state, { partnerId }) => fetchEntity.selectNestedField(partnerId, 'info.contactInformation')(state) },
    {},
    ({ contactInformation }) => {
        return ({
            fullName: contactInformation?.fullName,
            email: contactInformation?.email,
            phoneNumber: contactInformation?.phoneNumber,
            className: styles.container,
            defaultOpen: true,
            shouldRender: Boolean(contactInformation?.fullName || contactInformation?.email || contactInformation?.phoneNumber)
        })
    }
)