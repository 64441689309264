
const STANDARD_MEMBERSHIP_FORM_SCHEMA = {
    fields: {
        firstName: {
            label: "First Name",
            required: true
        },
        lastName: {
            label: "Last Name",
            required: true,
        },
        photo: {
            type: "image",
            label: "Profile photo"
        },
        bio: {
            type: "textarea",
            label: "Bio"
        },
    },
};

export default STANDARD_MEMBERSHIP_FORM_SCHEMA;