import IdentityCard from 'modules/Core/components/ui-kit/IdentityCard';
import ExpandableCard from 'modules/Core/components/ui-kit/Card/ExpandableCard';
import Features from 'modules/Core/components/ui-kit/Features';
import RedirectButton from 'modules/Core/components/ui-kit/RedirectButton';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import authorizeToken from 'modules/Auth/state/cells/authorizeToken';
import styles from './styles.module.scss';

const AuthenticatedOptions = ({ className, openClassName, contentClassName, featuresClassName, name, photo }) => (
    <ExpandableCard
        className={className}
        size={'lg'}
        openClassName={openClassName}
        label={<IdentityCard className={styles.id} name={name} image={photo} />}
        contentClassName={contentClassName}
    >
        <Features
            className={featuresClassName}
            features={features}
        />
    </ExpandableCard>
)

const features = [
    { content: <RedirectButton text={"Settings"} to={'/my-settings'} />, },
    { content: <RedirectButton text='Become a Member' to='/p4p/membership' /> },
    { content: <RedirectButton text='Logout' to={'/logout'} /> },
]

export default strappedConnected(
    AuthenticatedOptions,
    { userInformation: authorizeToken?.selectors.getUserInformation },
    null,
    ({ userInformation }) => ({
        name: userInformation?.firstName ? `${userInformation?.firstName} ${userInformation?.lastName}` : "Anonymous User",
        photo: userInformation?.photo
    })
);