import cx from "classnames"
import Container from "modules/Core/components/ui-kit/Container";
import useFocusedId from "modules/Core/hooks/useFocusedId";
import ObjectivesFeed from "modules/Objectives/components/ObjectivesFeed"
import ViewObjectiveDrawer from "modules/Objectives/components/ViewObjectiveDrawer";
import FeedItem from "modules/Core/components/Feed/FeedItem";
import ObjectiveStamps from "modules/Objectives/components/ObjectiveStamps";
import styles from "./styles.module.scss";

const Item = ({ stamps, className, ...props }) => (
    <FeedItem
        className={cx(
            styles.feedItem,
            className,
            {
                [styles.achieved]: Boolean(stamps?.custom?.commenced && !stamps?.custom?.achieved)
            }
        )}
        {...props}
    >
        <ObjectiveStamps stamps={{ achieved: stamps?.custom?.achieved }} />
    </FeedItem>
)


const CampaignObjectivesPanel = (props) => {
    const { focusedId, focus, unFocus } = useFocusedId();
    return (
        <>
            <Container>
                <ObjectivesFeed
                    {...props}
                    campaignId={props?.id}
                    onClick={focus}
                    ItemComponent={Item}
                    itemProps={{
                        shouldRenderText: false,
                        className: styles.item
                    }}
                />
                <ViewObjectiveDrawer
                    objectiveId={focusedId}
                    unFocus={unFocus}
                    origin={"right"}
                />
            </Container>
        </>
    )
};


export default CampaignObjectivesPanel;