import { fetchEntity } from "modules/Budgets/state/cells";
import BudgetItemsFeed from "modules/Budgets/sub-modules/BudgetItems/components/BudgetItemsFeed";
import BudgetTransactionsFeed from "modules/Budgets/sub-modules/BudgetTransactions/components/BudgetTransactionsFeed";
import Text from "modules/Core/components/ui-kit/Text";
import Container from "modules/Core/components/ui-kit/Container";
import Features from "modules/Core/components/ui-kit/Features";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import styles from "./styles.module.scss";

const BudgetDetails = ({ budget }) => <Features
    title={budget?.name} 
    features={[
        {
            name: "Budget Description",
            content: (
                <Container>
                    <Text>{budget?.description}</Text>
                    <Container b1grey mb1 mt1 />
                    <Features row features={[
                        { name: "Amount Raised", content: `£${budget?.amountRaised ?? 0}` },
                        { name: "Budget Total", content: `£${budget?.amount ?? 0}` },
                    ]} />
                </Container>
            )
        },
        {
            name: "Budget Items",
            content: <BudgetItemsFeed budgetId={budget?.id} feedItemClassName={styles.feedItem} />,
            className: styles.card
        },
        {
            name: "Budget Donations",
            content: <BudgetTransactionsFeed budgetId={budget?.id} feedItemClassName={styles.feedItem} />,
            className: styles.card
        },
    ]}
    card
    scroll
    mb3
/>


export default strappedConnected(
    BudgetDetails,
    {
        budget: (state, { id }) => fetchEntity.selector(id)(state)
    },
    {
        onLoad: (id) => fetchEntity.action({ id })
    },
    ({ id, onLoad }) => {
        useOnLoad(() => onLoad(id), Boolean(id), [id])
    }
);