import { initCell } from "modules/Core/core-modules/CoreModuleState/utils/cells";
import feed from "../feed";
import middleware from "../../middleware/index"
import { BUDGET_ITEMS } from "../../consts";
import { call } from "redux-saga/effects";

const { uploadAttachment } = middleware.ops;

export const {
    fetchFeed,
    setFilters,
    fetchEntity
} = feed.cells;

export const uploadBudgetItemAttachment = initCell(
    BUDGET_ITEMS,
    {
        name: "uploadAttachment",
        sagas: {
            latest: function* ({ payload }) {
                const result = yield call(uploadAttachment, payload);
                return result;
            }
        }
    }

)


export default {
    fetchFeed,
    setFilters,
    fetchEntity,
    uploadAttachment: uploadBudgetItemAttachment
}