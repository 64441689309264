import { useMemo } from "react"
import Modal from "modules/Core/components/ui-kit/Modal";
import Header from "modules/Core/components/ui-kit/layout/Header";
import Footer from "modules/Core/components/ui-kit/layout/Footer";
import Button from "modules/Core/components/ui-kit/Button";
import Container from "modules/Core/components/ui-kit/Container";
import UploadBudgetItemAttachment from "../UploadBudgetItemAttachment";
import BudgetItemDetails from "../BudgetItemDetails";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import useTabs from "modules/Core/hooks/useTabs";
import { fetchEntity } from "modules/Budgets/state/cells";
import styles from "./styles.module.scss";

const BudgetItemDetailsModal = ({ content, next, buttonText, ...props }) => (
    <Modal {...props}>
        <Container relative>
            <Header>
                Budget Item Details
            </Header>
            {content}
            <Footer flex flexEnd maxWidth relative className={styles.footer}>
                <Button onClick={next}>
                    {buttonText}
                </Button>
            </Footer>
        </Container>
    </Modal>
)

const tabs = [
    {
        label: "Details",
        Component: BudgetItemDetails
    },
    {
        label: "Attachments",
        Component: UploadBudgetItemAttachment,
        shouldRenderPropsKey: "isBudgetOwner"
    }
];

export default strappedConnected(
    BudgetItemDetailsModal,
    {
        isBudgetOwner: (state, { budgetId }) => fetchEntity.selectField(budgetId, "isOwner")(state)
    },
    {},
    ({ id, isBudgetOwner }) => {
        const props = {
            id,
            className: styles.content,
            onSuccess: () => next(),
            isBudgetOwner
        }
        const { content, next, focusedTabIndex } = useTabs(tabs, props)
        return {
            content,
            next,
            buttonText: isBudgetOwner && (focusedTabIndex === 0 ? "Add Attachment" : "Back"),
        }
    }
)