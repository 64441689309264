import { initCell } from 'modules/Core/core-modules/CoreModuleState/utils/cells';
import { onSuccess } from 'modules/Core/sub-modules/Dialog/state/cells';
import { POSTS } from 'modules/Posts/consts';
import { removePost as api } from 'modules/Posts/middleware/entity';
import { call, put } from 'redux-saga/effects';
import { fetchFeed } from './index.js';

const removePost = initCell(POSTS, {
    name: 'removePost',
    sagas: {
        latest: function* ({payload}) {
            const res  = yield call(api, {id: payload?.id, justification: payload?.justification})
            return res?.success
        },
        onSuccess: function* ({payload}) {
            yield put(onSuccess("Post successfully removed"));
            yield put(fetchFeed.action({renewStream: true, renewEntities: true}))
        }
    },
})

export default removePost;