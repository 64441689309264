import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import auth from "modules/Auth/state/cells/authorizeToken"
import { CAMPAIGN_ROLES } from "modules/Campaigns/consts";


const usePlatformRoles = () => {
    return useSelector(auth.selectors.getRoles);
}

export default (MaintainerComponent, DefaultComponent = React.Fragment) => (props) => {
    const roles = usePlatformRoles();
    const Component = useMemo(() => {
        if (roles?.includes(CAMPAIGN_ROLES.MAINTAINER)) return MaintainerComponent
        return DefaultComponent
    }, [MaintainerComponent, DefaultComponent, roles])
    return <Component {...props} />
}