import PartnerField from "modules/Core/components/form/PartnerField";
import {
  LOCATIONS_QUERY,
  parseLocationsResult,
} from "modules/Core/components/form/schema/locations";
import {
  parseTagsResult,
  TAGS_QUERY,
} from "modules/Core/components/form/schema/tags";
import ValuesField from "modules/Core/components/form/ValuesField";
import Widget from "modules/Core/components/ui-kit/Widget";
import styles from "./styles.module.scss";

export const PartnerFieldWidget = ({
  value,
  onChange,
  className,
  label,
  header,
  ...props
}) => (
  <Widget card className={className} size="lg">
    <PartnerField
      disabled={value?.disabled}
      {...props}
      label={label}
      value={value}
      onChange={onChange}
      cardClassName={styles.partnerCard}
      onClear={() =>
        onChange({
          ...value,
          disabled: true,
        })
      }
    />
  </Widget>
);

export const partnerField = {
  type: "partner",
  label: "Launch as partner",
  cardless: true,
  className: styles.partnerField,
  Component: (props) => <PartnerFieldWidget {...props} />,
};

const schema = {
  fields: {
    name: {
      required: true,
      type: "string",
      label: "Campaign Name",
      userGuideKey: "CAMPAIGNS.CAMPAIGN_NAME",
    },
    visionStatement: {
      required: true,
      type: "textarea",
      label: "Vision Statement",
      rows: 7,
      userGuideKey: "CAMPAIGNS.VISION_STATEMENT",
    },
    profileImage: {
      type: "image",
      label: "Campaign Profile Photo",
      userGuideKey: "CAMPAIGNS.PROFILE_IMAGE",
    },
    coverImage: {
      type: "image",
      label: "Campaign Cover Photo",
      userGuideKey: "CAMPAIGNS.COVER_IMAGE",
    },
    values: {
      type: "custom",
      label: "Campaign Values",
      Component: ValuesField,
      userGuideKey: "CAMPAIGNS.VALUES",
    },
    aims: {
      type: "array",
      label: "Campaign Aims",
      submitButtonText: "Add Aim",
      userGuideKey: "CAMPAIGNS.AIMS",
      expandable: true, 
      textKey: 'description',
      fields: {
        name: {
          type: "string",
          label: "Aim",
        },
        description: {
          type: "textarea",
          label: "Aim Description",
        },
      },
    },
    background: {
      type: "textarea",
      label: "Campaign Background",
      userGuideKey: "CAMPAIGNS.BACKGROUND",
      rows: 18,
    },
    mission: {
      type: "textarea",
      label: "Mission Statement",
      rows: 7,
      userGuideKey: "CAMPAIGNS.MISSION",
    },
    commitment: {
      type: "string",
      label: "Commitment",
      userGuideKey: "CAMPAIGNS.COMMITMENT",
    },
    location: {
      type: "async-select",
      label: "Location",
      searchable: true,
      query: LOCATIONS_QUERY,
      parseResult: parseLocationsResult,
      userGuideKey: "CAMPAIGNS.LOCATION",
    },
    tags: {
      type: "async-select",
      label: "Tags",
      searchable: true,
      query: TAGS_QUERY,
      parseResult: parseTagsResult,
      userGuideKey: "CAMPAIGNS.TAGS",
    },
    contactInformation: {
      type: "object",
      label: "Contact Information",
      userGuideKey: "CAMPAIGNS.CONTACT_INFORMATION",
      fields: {
        fullName: {
          type: "string",
          label: "Contact Name",
        },
        email: {
          type: "string",
          label: "Email",
        },
        phoneNumber: {
          type: "phone-number",
          label: "Phone",
        },
      },
    },
    links: {
      type: "array",
      label: "External Links",
      submitButtonText: "Add Link",
      userGuideKey: "CAMPAIGNS.LINKS",
      fields: {
        name: {
          type: "string",
          label: "Link Name",
        },
        url: {
          type: "string",
          label: "Link URL",
        },
      },
    },
    creators: {
      label: "Campaign Creators",
      type: "users",
      buttonLabel: "Add Creator",
      userGuideKey: "CAMPAIGNS.CREATORS",
    },
    maintainers: {
      label: "Campaign Maintainers",
      buttonLabel: "Add Maintainer",
      type: "users",
      userGuideKey: "CAMPAIGNS.MAINTAINERS",
    },
  },
};

export default [
  {
    title: "Basic Campaign Information",
    fields: {
      name: schema.fields.name,
      visionStatement: schema.fields.visionStatement,
      profileImage: schema.fields.profileImage,
      coverImage: schema.fields.coverImage,
    },
  },
  {
    title: "Campaign Details",
    fields: {
      values: schema.fields.values,
      aims: schema.fields.aims,
      background: schema.fields.background,
      mission: schema.fields.mission,
      commitment: schema.fields.commitment,
    },
  },
  {
    title: "Additional Campaign Details",
    fields: {
      location: schema.fields.location,
      tags: schema.fields.tags,
      contactInformation: schema.fields.contactInformation,
      links: schema.fields.links,
    },
  },
  {
    title: "Campaign Team",
    fields: {
      creators: schema.fields.creators,
      maintainers: schema.fields.maintainers,
    },
  },
];
