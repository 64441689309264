import FeedItem from "modules/Core/components/Feed/FeedItem";
import feed from "../../state/feed/index"

const { FeedComponent } = feed;

const PeerTransactionsFeed = ({ partnerId }) => (
    <FeedComponent 
        filters={{ partnerId }}
        ItemComponent={(item) => <FeedItem {...item} name={`Donation (${item?.price} GBP) `} />}
    />
);

export default PeerTransactionsFeed;