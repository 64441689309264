import { useMemo } from 'react';
import cx from 'classnames';
import Container from "modules/Core/components/ui-kit/Container"
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import { fetchEntity } from "modules/Invitations/state/cells";
import styles from "./styles.module.scss";

const Invitation = ({ TemplateComponent, invitation, className, ...props },) => (
    <Container shadow  className={cx(styles.container, className)}>
        <TemplateComponent
            {...props}
        />
    </Container>
);

export default strappedConnected(
    Invitation,
    { invitation: (state, { id }) => fetchEntity.selector(id)(state) },
    { onLoad: (id) => fetchEntity.action({ id }) },
    ({ onLoad, id, invitation }) => {
        useOnLoad(() => onLoad(id), Boolean(id));   
        return {
            variables: useMemo(() => invitation?.variables, [invitation?.variables]),
            stamps: useMemo(() => invitation?.stamps, [invitation?.stamps])
        }
    }
);

