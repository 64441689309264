import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";
import { CAMPAIGN_FINANCIALS } from "../consts";

const fetchFeed = `
	query transactedItems($campaignId: String, $feedParams: FeedParams) {
		feed: transactions(campaignId: $campaignId, feedParams: $feedParams) {
			entities {
				id
				price
				key
				name
			}
	  		${FEED_INFO_FRAGMENT}
		}
  	}
`;

const registerFinancials = `
	mutation registerCampaignFinancialsAccount($campaignId: String,) {
		registerCampaignFinancialsAccount(campaignId: $campaignId) {
			url
		}
	}
`;


const fetchCampaignFinancialsIntegration = `
	query campaignFinancialsAccount($campaignId: String,) {
		campaignFinancialsAccount(campaignId: $campaignId) {
			url
		}
	}
`;

const syncVerification = `
	mutation syncVerification($campaignId: String) {
		syncCampaignAccountVerification(campaignId: $campaignId)
	}
`;

export default new MiddlewareModule({
	name: CAMPAIGN_FINANCIALS,
	operations: {
		fetchFeed,
		fetchCampaignFinancialsIntegration,
		registerFinancials,
		syncVerification,
	},
})
