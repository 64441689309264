import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";
import { BUSINESS_PARTNERSHIPS } from "../consts";

const fetchFeed = `
	query BusinessPartners($feedParams: FeedParams) {
		feed: businessPartners(feedParams: $feedParams) {
			entities {
				id
				name,
				info {
					image
				}
			}
	  		${FEED_INFO_FRAGMENT}
		}
  	}
`;

const createSubscription = `
	mutation createBusinessPartnership($input: BusinessPartnershipInput,) {
		createBusinessPartnership(input: $input) {
			paymentIntent {
				clientSecret
			}
		}
	}
`;


const fetchSubscription = `
	query fetchSubscription($id: String) {
		entity: businessPartnerSubscription(partnerId: $id) {
			tier
			id: partner
			stamps {
				created
				activated
			}
		}
	}
`;

export default new MiddlewareModule({
	name: BUSINESS_PARTNERSHIPS,
	operations: {
		createSubscription,
		fetchFeed,
		fetchSubscription,
	},
	operationsConfig: {
        fetchSubscription: {
            paramsParser: (id) => ({ id }),
        }
    }
})
