import { useMemo } from 'react';
import FeaturesWidget from 'modules/Core/components/ui-kit/Widget/FeaturesWidget';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import DateComponent from 'modules/Core/components/ui-kit/Date';
import { fetchEntity } from 'modules/Campaigns/state/cells';
import styles from './styles.module.scss';

export default strappedConnected(
    FeaturesWidget,
    {
        creators: (state, { campaignId }) => fetchEntity.selectField(campaignId, 'creators')(state),
        approvedDate: (state, { campaignId }) => fetchEntity.selectNestedField(campaignId, 'approved.time')(state),
    },
    {},
    ({ approvedDate, creators }) => ({
        features: useMemo(() => ([

            {
                name: 'Campaign Creators:',
                content: creators?.length && `${creators?.length} anonymous user${creators?.length > 1 ? 's' : ''}`
            },
            {
                name: 'Launched',
                content: <DateComponent date={approvedDate} />
            },
        ]), [approvedDate, creators]),
        label: 'Additional Information',
        card: true,
        row: true,
        className: styles.container
    })
)