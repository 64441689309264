import withParams from "modules/Core/higher-order-components/withParams";
import CampaignCampsite from "../components/screens/CampaignCampsite";
import CampaignCommand from "../components/screens/CampaignCommand";
import CampaignsFeed from "../components/screens/CampaignsFeed";
import CreateSubcampaign from "../components/screens/CreateSubcampaign";
import EditCampaign from "../components/screens/EditCampaign";
import ViewCampaign from "../components/screens/ViewCampaign";
import CreateBudgetScreen from "modules/Budgets/components/screens/CreateBudgetScreen";
import FinancialDonationPage from "modules/Payments/components/screens/FinancialDonationPage";
import CreateCampaign from "../components/screens/CreateCampaign";
import CampaignFundraising from "../components/screens/CampaignFundraising";
import CampaignDonation from "../components/screens/CampaignDonation";

export default {
    'campaigns/create': (props) => <CreateCampaign redirectRoute={"/campaigns/feed"} {...props} />,
    'campaigns/': CampaignsFeed,
    'campaigns/feed': CampaignsFeed,
    'campaigns/:id': withParams(ViewCampaign),
    'campaigns/:id/gateway': withParams(ViewCampaign),
    'campaigns/:id/camp': withParams(CampaignCampsite),
    'campaigns/:id/campsite': withParams(CampaignCampsite),
    'campaigns/:id/edit': EditCampaign,
    'campaigns/:id/create-subcampaign': CreateSubcampaign,
    'campaigns/:id/camp/command': withParams(CampaignCommand),
    'campaigns/:id/campsite/command-center': withParams(CampaignCommand),
    'campaigns/:campaignId/camp/create-budget': withParams(CreateBudgetScreen),
    "campaigns/:campaignId/camp/donations": withParams((props) => <FinancialDonationPage header={"Campaign Fundraising"} {...props} />),
    "campaigns/:id/fundraising": withParams(CampaignFundraising),
    "campaigns/:id/fundraising/donate": withParams(CampaignDonation),
};