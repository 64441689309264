import Container from "modules/Core/components/ui-kit/Container"
import { OBJECTIVE_STAMPS } from "modules/Objectives/consts";
import DescriptiveExpandableCard from "modules/Core/components/ui-kit/Card/ExpandableCard/DescriptiveExpandableCard";
import ObjectiveStamps from "../../ObjectiveStamps";
import StampObjectivesButton from "../../StampObjectivesButton";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { fetchEntity } from "modules/Objectives/state/cells";

const ExpandableSubObjective = ({ id, stamps, callback, ...props }) => (
    <DescriptiveExpandableCard {...props}>
        <Container flex spaceBetween alignCenter maxWidth>
            <ObjectiveStamps stamps={stamps?.custom} />
            <StampObjectivesButton
                id={id}
                stamp={OBJECTIVE_STAMPS.ACHIEVED}
                text={"Stamp achieved"}
                shouldRender={!Boolean(stamps.custom?.achieved )}
                callback={callback}
            />
        </Container>
    </DescriptiveExpandableCard>
)

export default strappedConnected(
    ExpandableSubObjective,     
    null, 
    {
        fetchEntity: fetchEntity.action,
    },
    ({parent, fetchEntity}) => ({
        callback: () => fetchEntity({id: parent})
    })  
);