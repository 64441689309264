import CoreModule from "modules/Core/core-modules/CoreModule";
import { JUDGEMENTS } from "./consts";
import cells from "./state/cells";
import routes from "./routes";
import feed from "./state/feed";

const Judgements = new CoreModule({
    name: JUDGEMENTS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default Judgements;