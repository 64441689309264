import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "modules/Objectives/middleware";
import { OBJECTIVES } from "modules/Objectives/consts";

const { create, fetchFeed, fetchEntity } = middleware.ops;

export default new FeedModule({
    name: OBJECTIVES,
    cellOptions: {
        createEntityCell: {
            requestHandler: create
        },
        fetchFeedCell: {
            requestHandler: fetchFeed
        },
        fetchEntityCell: {
            requestHandler: fetchEntity
        },
        setNestedFeedCell: {
            nestedFeedKey: "active",
        }
    }
});