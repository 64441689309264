import { useMemo } from 'react';
import { compact } from 'lodash';
import WidgetButton from 'modules/Core/components/ui-kit/Widget/WidgetButton';
import DonationsFeed from '../DonationsFeed';
import Container from 'modules/Core/components/ui-kit/Container';
import FeaturesWidget from 'modules/Core/components/ui-kit/Widget/FeaturesWidget';
import Features from 'modules/Core/components/ui-kit/Features';
import strapped from 'modules/Core/higher-order-components/strapped';

const DonatationButton = () => (
    <WidgetButton
        text="Donate Now"
        to='./donate'
    />
);
const DonationsFeedTab = ({ feedParams }) => (
    <Container>
        <DonationsFeed {...feedParams} />
    </Container>
);


const FundraisingProgressWidget = strapped(
    FeaturesWidget,
    ({ amountRaised, target, amountRemaining }) => {
        const features = useMemo(() => compact([
            { name: 'Amount Raised', content: '£' + (amountRaised ?? 0) },
            target && { name: 'Fundraising Target', content: ('£' + target) },
            amountRemaining && { name: 'Amount Remaining', content: ('£' + amountRemaining) }
        ]), [amountRaised, target, amountRemaining]);

        return {
            features,
            row: true,
            label: 'Fundraising',
            size: features?.length > 2 ? 'lg' : 'md'
        }
    }
)

export default {
    tabs: [
        {
            title: 'Donations',
            Component: DonationsFeedTab
        },
        {
            title: 'Details',
            Component: ({ details }) => <Features card features={details} />
        }
    ],
    back: {
        text: 'Back',
        navigate: -1
    },
    rightWidgets: [{
        Component: () => <DonatationButton />,
        gridRow: 2,
    }],
    leftWidgets: [
        { Component: (props) => <FundraisingProgressWidget {...props} />, gridRow: 4 },
    ]

}