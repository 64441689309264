import { useCallback, useMemo } from 'react';
import Container from 'modules/Core/components/ui-kit/Container';
import Form from 'modules/Core/components/form/Form';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import { set } from 'modules/Core/utils/obj';
import _schema from "./schema"
import useLogic from '../useLogic';
import actions from '../actions';
import { CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS } from '../../../consts';
import styles from "./styles.module.scss";

const _SendOrganisationInvitationForm = ({ onSubmit: _onSubmit, campaignId, onSuccess, ...props }) => {
    // COULDDO/SHOULDDO: Generic Send Invitation Form which kind of just works good throughout
    const schema = useMemo(() => {
        if (!campaignId) return false;
        set(_schema, 'fields.partner.variables.notCampaignId', campaignId)
        return _schema
    }, [campaignId]);

    const onSubmit = useCallback(({ partner, message }) => _onSubmit({
        campaignId,
        partnerId: partner,
        message,
        sentBy: CAMPAIGN_PARTNERSHIP_SENT_BY_OPTIONS.CAMPAIGN,
    }), [campaignId, _onSubmit]);


    return schema && (
        <Container {...props}>
            <Form
                schema={schema}
                onSubmit={onSubmit}
                className={styles.form}
            />
        </Container>

    )
};

export default strappedConnected(
    _SendOrganisationInvitationForm,
    null,
    actions,
    useLogic,
);