import { useCallback, useState } from "react";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import Container from "modules/Core/components/ui-kit/Container";
import useOnError from "modules/Core/sub-modules/Dialog/hooks/useOnError";
import useOnSuccess from "modules/Core/sub-modules/Dialog/hooks/useOnSuccess";
import NextButton from "modules/Core/components/ui-kit/Button/NextButton";

const StripePayment = ({ clientSecret, options }) => {
    const [isLoading, setIsLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    const onError = useOnError();
    const onSuccess = useOnSuccess();

    const onSubmit = useCallback(async () => {
        setIsLoading(true);
        try {
            const {
                paymentIntent, error,
            } = await stripe.confirmPayment({ elements, redirect: 'if_required' });
            if (error) return onError("Failed to process payment. Please try again.")
            if (paymentIntent.status === "succeeded") {
                onSuccess("Payment successful!");
                options?.onSuccess?.(paymentIntent);
            }
        } catch (e) {
            return onError("Internal error; please contact P4P support .");
        } finally {
            setIsLoading(false);
        }
    }, [stripe, elements, options, clientSecret]);

    return (
        <Container>
            <PaymentElement />
            <Container flex flexEnd maxWidth alignCenter mt1>
                <NextButton
                    onClick={onSubmit}
                    disabled={!clientSecret}
                    loading={isLoading}
                >
                    Make Payment
                </NextButton>
            </Container>
        </Container>
    )

}

export default StripePayment;