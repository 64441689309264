import { ACTIONS } from "modules/Actions/consts";
import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "modules/Actions/middleware";
import createEntity from "../cells/createEntity";

const { fetchFeed } = middleware.ops;

const module = new FeedModule({
    name: ACTIONS,
    cellOptions: {
        createEntityCell: createEntity,
        fetchFeedCell: {
            requestHandler: fetchFeed
        }
    }
});

export default module;