import Modal from 'modules/Core/components/ui-kit/Modal';
import Form from 'modules/Core/components/form/Form';
import userInformationSchema from 'modules/Auth/components/UserInformationForm/schema';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import setCampaignMembershipInformation from 'modules/Campaigns/state/cells/setCampaignMembershipInformation';
import { useCallback, useEffect, useMemo } from 'react';
import { useOnLoad } from 'modules/Core/hooks/useOnLoad';
import { fetchEntity } from 'modules/Campaigns/state/cells';
import authorizeToken from 'modules/Auth/state/cells/authorizeToken';
import { compactObject } from 'modules/Core/utils/obj';
import styles from './styles.module.scss';

export const campaignMembersipInformationFormFields = {
    name: {
        type: 'object',
        className: styles.nameField,
        fields: {
            firstName: {
                label: 'First Name',
                type: 'string',
                disabled: true
            },
            lastName: {
                label: 'Last Name',
                type: 'string',
                disabled: true
            },
            anonymous: {
                label: "Anonymously participate in this campaign",
                switch: true,
                type: 'switch'
            },
        }
    },

    photo: {
        ...userInformationSchema.fields.photo,
    },
    bio: {
        ...userInformationSchema.fields.bio,
    }
}

const SetCampaignMembershipInfoModal = ({ open, close, isOpen, onSubmit, onSuccess, initialState, ...props }) => (
    <Modal
        classNamr={styles.modal}

        {...props}
        close={close}
        isOpen={isOpen}
    >
        <Form
            schema={{
                fields: campaignMembersipInformationFormFields,
                header: 'Set Membership Information',
                subheader: "Set your membership profile information for this campaign."
            }}
            className={styles.form}
            onSubmit={onSubmit}
            fullScreen={false}
            submitButtonText={'Confirm'}
            initialState={initialState}
            onSuccess={onSuccess}   
        />
    </Modal>
)


export default strappedConnected(
    SetCampaignMembershipInfoModal,
    {
        membershipInfo: (state, { campaignId }) => fetchEntity.selectNestedField(campaignId, 'membership.information')(state),
        userInformation: (state) => authorizeToken.selectors.getUserInformation(state),
        isSuccess: (state) => setCampaignMembershipInformation.selectors.isSuccess(state)
    },
    {
        onSubmit: (campaignId, input, callback) => setCampaignMembershipInformation.action({ campaignId, input, callback }),
        onSuccess: (id) => fetchEntity.action({ id })

    },
    ({ close, campaignId, open, autoOpen, onSubmit, membershipInfo, userInformation, onSuccess, isSuccess }) => {
        useOnLoad(() => open(), Boolean(autoOpen), [autoOpen]);
        useEffect(() => {
            if (isSuccess) close();
        }, [isSuccess, close]);
        return ({
            onSubmit: useCallback((form) => onSubmit(campaignId, parseInput(form), close), [campaignId, onSubmit, close]),
            initialState: useMemo(() => ({
                name: {
                    firstName: membershipInfo?.firstName ?? userInformation?.firstName,
                    lastName: membershipInfo?.lastName ?? userInformation?.lastName,
                    anonymous: Boolean(!membershipInfo?.firstName && !membershipInfo?.lastName)
                },
                photo: membershipInfo?.photo ?? userInformation?.photo,
                bio: membershipInfo?.bio,
            }), [membershipInfo?.firstName, userInformation?.firstName]),
            onSuccess: useCallback(() => onSuccess(campaignId), [campaignId, onSuccess])
        })
    }
)

export const parseInput = ({ name, ...input }) => compactObject({
    firstName: !name?.anonymous && name?.firstName,
    lastName: !name?.anonymous && name?.lastName,
    ...input
})