import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "../../middleware";
import { P4P_WORK } from "../../consts";

const { fetchFeed } = middleware.ops;

export default new FeedModule({
    name: P4P_WORK,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchFeed,
        }
    }
});