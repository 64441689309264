import { initCell } from 'modules/Core/core-modules/CoreModuleState/utils/cells';
import { POSTS } from 'modules/Posts/consts';
import { heartPost as api } from 'modules/Posts/middleware/entity';
import { call, put } from 'redux-saga/effects';

const heartPost = initCell(POSTS, {
    name: 'heartPost',
    sagas: {
        latest: function* ({payload}) {
            const res  = yield call(api, payload?.id)
            if(res?.success) yield put(heartPost.onSuccess(payload))
            return res?.success
        }
    },
    successCell: {
        reducer: (state, { payload }) => {
            if(!state.entities[payload?.id].isHearted) {
                state.entities[payload?.id].heartCount += 1
            }
            state.entities[payload?.id].isHearted = Boolean(payload);
        },
    }
})

export default heartPost;