import feed from "../../state/feed";
import Card from "modules/Core/components/ui-kit/Card";
import Text from "modules/Core/components/ui-kit/Text";
import IdentityCard from "modules/Core/components/ui-kit/IdentityCard";
import Container from "modules/Core/components/ui-kit/Container";
import styles from "./styles.module.scss";

const { FeedComponent } = feed;

const FeedItem = ({userInformation, bio, roles }) => (
    <Card className={styles.card}>
        <IdentityCard
            className={styles.id}
            name={userInformation?.firstName + " " + userInformation?.lastName}
            image={userInformation?.photo}
        >
            <Container className={styles.info}>
                <Text bold>{roles?.join(", ")}</Text>
                <Text>{bio}</Text>
            </Container>
        </IdentityCard>
    </Card>
)

const P4PMembersFeed = ({ }) => (
    <FeedComponent ItemComponent={FeedItem} />
)

export default P4PMembersFeed