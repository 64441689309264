import Container from 'modules/Core/components/ui-kit/Container';
import AuthenticatedOptions from './AuthenticatedOptions';
import UnauthenticatedOptions from './UnauthenticatedOptions';
import { useIsAuthenticated } from 'modules/Auth/hooks/useConnectedDispatch';
import styles from './styles.module.scss';

const AuthOptions = () => {
    const isConnected = useIsAuthenticated();

    return (
        <Container relative className={styles.container}>
            {isConnected ? (
                <AuthenticatedOptions 
                    className={styles.authenticatedOptions} 
                    openClassName={styles.open} 
                    contentClassName={styles.authenticatedOptionsWrapper}
                    featuresClassName={styles.authenticatedOptionItems}
                />
            ) : <UnauthenticatedOptions />}
        </Container>
    )
}

export default AuthOptions;