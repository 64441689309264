import PlatformBudgetsFeed from "../../PlatformsBudgetFeed"
import DonationPanel from "../../DonationPanel"

const budgetsFeedWidth = 72;
export default [
    {
        id: "budgets-feed",
        Component: PlatformBudgetsFeed,
        width: `${budgetsFeedWidth}%`,
    },
    {
        id: "donation-panel",
        Component: DonationPanel,
        width: `${100 - budgetsFeedWidth}%`,
    }
]