import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";    
import cells from "./state/cells";
import routes from "./routes";
import { PEER_PARTNER_FINANCIALS } from "./consts";

const PeerPartnerFinancials = new CoreModule({
    name: PEER_PARTNER_FINANCIALS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default PeerPartnerFinancials;
