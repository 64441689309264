import feed from "../feed";
import { initCell } from 'modules/Core/core-modules/CoreModuleState/utils/cells';
import { BUDGETS } from '../../consts';
import { call, put } from 'redux-saga/effects';
import middleware from '../../middleware';
import { onSuccess } from 'modules/Core/sub-modules/Dialog/state/cells';
import fetchFeedCell from "modules/Core/state/reusableCells/fetchFeedCell";
import { FETCH_PROSPECTIVE_FEED } from "modules/Core/state/consts";


export const {
    createEntity,
    fetchFeed,
    fetchEntity,
    setFilters
} = feed.cells;


export const approveBudget = initCell(BUDGETS,
    {
        name: "approveBudget",
        reducer: (state, payload) => {
            state.isLoading = true
        },
        sagas: {
            latest: function* ({ payload }) {
                const res = yield call(middleware.ops.approveBudget, payload, true);
                const isSuccess = Boolean(res?.approveBudget)
                if (!isSuccess) return false;
                yield put(approveBudget.onSuccess(true))
                return res?.approveBudget
            },
            onSuccess: function* (payload) {
                yield put(onSuccess("Budget approved successfully"))
                yield put(fetchProspectiveBudgets.action({ renewStream: true }))
            }
        },
        successCell: {
            reducer: (state, payload) => {
                state.isLoading = false;
            },
        }
    }
);

export const fetchProspectiveBudgets = fetchFeedCell(BUDGETS, {
    requestHandler: middleware.ops.fetchProspective,
    name: FETCH_PROSPECTIVE_FEED

})

export default {
    createEntity,
    fetchFeed,
    fetchEntity,
    setFilters,
    approveBudget,
    [FETCH_PROSPECTIVE_FEED]: fetchProspectiveBudgets
}