import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { gql } from "graphql-request";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";
import { P4P_WORK } from "../consts";

const fetchFeed = gql`
    query fetchP4PWorklog($feedParams: FeedParams) {
        feed: P4PWorklog(feedParams: $feedParams) {
            entities {
                title
                description
                dateLabel
                id
            }
            ${FEED_INFO_FRAGMENT}
        }
    }
`


const operations = {
    fetchFeed,
}

export default new MiddlewareModule({
    name: P4P_WORK,
    operations,
    operationsConfig: {
        fetchEntity: {
            paramsParser: (id) => ({ id }),
        }
    }
});

