import { call, put } from "redux-saga/effects";
import { initCell } from "modules/Core/core-modules/CoreModuleState/utils/cells";
import { CAMPAIGN_MAINTAINER_APPLICATIONS } from "../../consts";
import { setMaintainerApplicationStatus } from "../../middleware";
import { fetchFeed } from "./index.js";

const setCampaignMaintainerApplicationStatusCell = initCell(
  CAMPAIGN_MAINTAINER_APPLICATIONS,
  {
    name: "setCampaignMaintainerApplicationStatus",
    sagas: {
      latest: function* ({ payload }) {
        const res = yield call(
          setMaintainerApplicationStatus,
          payload?.applicationId,
          payload?.status
        );
        if (res?.setCampaignMaintainerApplicationStatus?.id) return { payload };
      },
      onSuccess: function* ({ payload }) {
        yield put(fetchFeed.action({ renewStream: true }));
      },
    },
  }
);

export default setCampaignMaintainerApplicationStatusCell;
