import Container from 'modules/Core/components/ui-kit/Container';
import ProspectiveCampaignsFeed from '../../ProspectiveCampaignsFeed';
import ApproveProspectiveCampaign from '../../../../../components/ApproveProspectiveCampaign';
import Drawer from 'modules/Core/components/ui-kit/Drawer';
import CampaignDetails from 'modules/Campaigns/components/CampaignDetails';
import RejectProspectiveCampaign from '../../../../../components/RejectProspectiveCampaign';
import useFocusedId from 'modules/Core/hooks/useFocusedId';
import CampaignDetailsTab from 'modules/Campaigns/components/CampaignDetails/CampaignDetailsTab';
import styles from "./styles.module.scss";

const FeedPanelComponent = ({ setPanelState, parentId }) => {
    const { focusedId, focus, unFocus } = useFocusedId();

    return (
        <Container>
            <ProspectiveCampaignsFeed
                parentId={parentId}
                onClick={focus}
            />
            <Drawer
                origin={"left"}
                isOpen={Boolean(focusedId)}
                close={unFocus}
                header={"Prospective sub-campaign details"}
            >
                <Container scroll maxHeight>
                    <CampaignDetails className={styles.campaignDetails} id={focusedId} >
                        <ApproveProspectiveCampaign id={focusedId} />
                        <RejectProspectiveCampaign id={focusedId} />
                        <CampaignDetailsTab id={focusedId} />
                    </CampaignDetails>
                </Container>
            </Drawer>
        </Container>
    )
};


const feedPanel = {
    title: "Prospective Campaigns",
    Component: FeedPanelComponent
};


export default [feedPanel]
