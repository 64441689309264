import Text from "modules/Core/components/ui-kit/Text";
import { PARTNER_KINDS } from "modules/Partners/consts";
import Screen from "modules/Core/components/ui-kit/Screen";
import Invitation from "../../Invitation";
import InvitationCard from "../../InvitationCard";
import Container from "modules/Core/components/ui-kit/Container";
import RedirectButton from "modules/Core/components/ui-kit/RedirectButton";
import List from "modules/Core/components/ui-kit/List";
import microcopy from "./microcopy.json"
import styles from "./styles.module.scss";

const BusinessPartnershipInvitation = ({ variables }) => (
    <Screen center flex alignCenter>
        <Invitation
            TemplateComponent={InvitationCard}
            microcopy={{
                title: microcopy.title,
                kindRegards: "The P4P Team",
                to: "To: " + (variables?.partner?.name ?? "Our Potential Partner"),
            }}
            prePersonalisedMessage={
                (
                    <Container ml1 >
                        <Text fs150>
                            Let's change the world together, it's a pleasure to invite you to partner with is and to collaborate for mutual gain.
                            <br />
                            <br />
                            Whilst we ask you to financially support us, we seek to inspire our community to support you in return, and to create a network of financial support to help us do work which otherwise we wouldn't be able to do.
                        </Text>
                    </Container>
                )
            }
            content={
                <Container ml1 mb3>
                    <Text fs150 flex alignCenter>
                        Here are
                        <Text pre m1 fs150 italic bold> abc's </Text>
                        of why you should
                        <RedirectButton
                            nature={'none'}
                            to={`/partner-onboarding/${PARTNER_KINDS.BUSINESS.toLowerCase()}`}
                        >
                            <Text pre fs150 bold italic> join a community for change! </Text>
                        </RedirectButton>
                    </Text>
                    <Container flex>
                        <List
                            className={styles.abc}
                            items={microcopy.abcs}
                            fs120
                        />
                    </Container>
                </Container>
            }
            footer={
                <RedirectButton
                    className={styles.link}
                    to={`/partner-onboarding/${PARTNER_KINDS.BUSINESS.toLowerCase()}`}
                    text={"Sign up "}
                />
            }
        />
    </Screen>
)

export default BusinessPartnershipInvitation;