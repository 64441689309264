import { useCallback, useState } from 'react';
import Action from '../Action';
import { Share } from "modules/Core/components/ui-kit/indicators";
import { BASE_SHARED_POST_URL } from 'modules/Posts/consts';
import useOnSuccess from 'modules/Core/sub-modules/Dialog/hooks/useOnSuccess';
import styles from "./styles.module.scss";

const rootUrl = `${window.location.protocol}//${window.location.host}${BASE_SHARED_POST_URL}`;
const SharePostAction = ({ postId }) => {
    const [isClicked, setIsClicked] = useState(false);
    const onSuccess = useOnSuccess();
    const handleClick = useCallback(async () => {
        setIsClicked(true);
        await navigator.clipboard.writeText(rootUrl + postId);
        onSuccess("Link Copied: " + rootUrl + postId);
    })
    return (
        <Action
            className={styles.container}
            Indicator={Share}
            isActive={isClicked}
            action={handleClick}
        />
    )
}

export default SharePostAction;