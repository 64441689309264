import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Screen from 'modules/Core/components/ui-kit/Screen';
import CampaignScreenMainContentHeader from './CampaignScreenMainContentHeader';
import { useOnLoad } from 'modules/Core/hooks/useOnLoad';
import { fetchEntity } from 'modules/Campaigns/state/cells';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import { useIsOpen } from 'modules/Core/hooks/useIsOpen';
import SetCampaignMembershipInfoModal from '../SetCampaignMembershipInfoModal';
import { CAMPAIGN_ROLES } from 'modules/Campaigns/consts';

const CampaignScreen = ({ tabs, header, membership, back, forward, id, leftWidgets, widgetProps: _widgetProps, rightWidgets, commonPanelProps, shouldFetchOnLoad = true }) => {
    const dispatch = useDispatch();
    const { isOpen, open, close } = useIsOpen();
    useOnLoad(() => dispatch(fetchEntity.action({ id })), shouldFetchOnLoad && !!id, [id])

    const tabProps = useMemo(() => ({ id }), [id]);
    const widgetProps = useMemo(() => ({ campaignId: id, ..._widgetProps }), [id, _widgetProps]);

    const commonProps = useMemo(() => ({
        id,
        ...commonPanelProps
    }), [id, commonPanelProps]);

    const autoOpenModal = useMemo(() => membership?.roles?.includes(CAMPAIGN_ROLES.MAINTAINER) && !Boolean(membership?.information?.id), [membership, membership?.info?.id]);

    return (
        <>
            <Screen
                tripanel
                tabs={tabs}
                back={back}
                forward={forward}
                tabProps={tabProps}
                widgetProps={widgetProps}
                header={header}
                contentHeader={
                    <CampaignScreenMainContentHeader id={id} />
                }
                commonPanelProps={commonProps}
                leftWidgets={leftWidgets}
                rightWidgets={rightWidgets}
            />
            <SetCampaignMembershipInfoModal
                isOpen={isOpen}
                close={close}
                open={open}
                autoOpen={autoOpenModal}
                campaignId={id}
            />
        </>

    )
};

export default strappedConnected(
    CampaignScreen,
    {
        membership: (state, { id }) => fetchEntity.selectField(id, 'membership')(state),
    },
    {},
    ({ forward, membership }) => {
        const _forward = useMemo(() => {
            if (forward?.membershipRequired && !Boolean(membership)) return ({
                ...forward,
                disabled: true,
                restrictionMessage: forward.restrictionMessage
            });
            if (forward?.requiredRoles && !forward?.requiredRoles.some(role => membership?.roles?.includes(role))) return ({
                ...forward,
                disabled: true,
                restrictionMessage: forward.restrictionMessage
            });
            return forward;
        }, [membership, forward]);

        return {
            forward: _forward,
        }
    }
);