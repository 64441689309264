import FeedModule from "modules/Core/core-modules/FeedModule";
import middleware from "../../middleware";
import { PEER_PARTNER_FINANCIALS } from "../../consts";

const { fetchFeed, registerFinancials, fetchPeerFinancialsIntegration } = middleware.ops;

export default new FeedModule({
    name: PEER_PARTNER_FINANCIALS,
    cellOptions: {
        fetchFeedCell: {
            requestHandler: fetchFeed
        },
        createEntityCell: {
            requestHandler: registerFinancials
        },
        fetchEntityCell: {
            requestHandler: fetchPeerFinancialsIntegration
        }
    }
});