import Container from "modules/Core/components/ui-kit/Container"
import styles from "./styles.module.scss"
import DeleteAction from "./DeleteAction";

const CommentActions = ({id}) => (
    <Container flex alignCenter className={styles.container}>
        <DeleteAction id={id}/>
    </Container>
);

export default CommentActions;