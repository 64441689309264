import { initCell } from "modules/Core/core-modules/CoreModuleState/utils/cells";
import { COMMON } from "modules/Common/consts";
import { call } from "redux-saga/effects";
import middleware from "modules/Common/middleware";

export const fetchCategoryTags = initCell(COMMON, {
  name: "fetchCategoryTags",
  select: (state) => state.tags,
  sagas: {
    latest: function* ({ payload }) {
      const result = yield call(middleware.ops.fetchCategoryTags);
      return result?.tags?.entities;
    },
    onCellSuccess: true,
  },
  successCell: {
    reducer: (state, {payload}) => {
      state.tags = payload;
    },
  },
});

export default {
  fetchCategoryTags,
};
