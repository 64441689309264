import { useCallback } from "react";
import useStampObjectives from "modules/Objectives/hooks/useStampObjectives";
import Button from "modules/Core/components/ui-kit/Button";
import withMaintainerOnly from "modules/Campaigns/higher-order-components/withMaintainerOnly";
import strapped from "modules/Core/higher-order-components/strapped";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";

const StampObjectivesButton = strapped(
    Button,
    ({ id, stamp, callback }) => {
        const onStamp = useStampObjectives();
        const onClick = useCallback(() => onStamp([id], stamp, callback), [id, stamp, onStamp, callback]);
        return {
            onClick
        }
    },
    {
        fso72: true
    }
);

export default withMaintainerOnly(
    withShouldRender(
        StampObjectivesButton
    )   
)
