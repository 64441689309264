import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { init } from "./modules/Core/core-modules";
import LandingPage from "./modules/Core/components/screens/LandingPage";
import Auth from "./modules/Auth";
import Posts from "./modules/Posts";
import Campaigns from "./modules/Campaigns";
import Dialog from "./modules/Core/sub-modules/Dialog";
import Comments from "./modules/Posts/sub-modules/Comments";
import ProspectiveCampaigns from "modules/Campaigns/sub-modules/prospective-campaigns";
import CampaignMaintainers from "modules/Campaigns/sub-modules/campaign-maintainers";
import CampaignMaintainerApplications from "modules/Campaigns/sub-modules/campaign-maintainers/submodules/applications";
import Actions from "modules/Actions";
import Objectives from "modules/Objectives";
import PrivacyPolicy from "modules/Core/components/screens/PrivacyPolicy";
import Partners from "modules/Partners";
import Invitations from "modules/Invitations";
import Payments from "modules/Payments";
import Budgets from "modules/Budgets";
import BudgetItems from "modules/Budgets/sub-modules/BudgetItems";
import BudgetTransactions from "modules/Budgets/sub-modules/BudgetTransactions";
import CampaignPartnershipInvitations from "modules/Partners/submodules/campaign-partnership-invitations";
import CampaignPartnerships from "modules/Partners/submodules/campaign-partnerships";
import TeamMembers from "modules/Partners/submodules/team-members";
import PeerPartnerFinancials from "modules/Partners/submodules/peer-partner-financials";
import BusinessPartnerships from "modules/Partners/submodules/business-partnerships";
import P4P from "modules/P4P";
import P4PWork from "modules/P4P/sub-modules/Work";
import Values from "modules/P4P/sub-modules/Values";
import P4PTeam from "modules/P4P/sub-modules/Team";
import Judgements from "modules/Judgements";
import Tracking from "modules/Core/sub-modules/Tracking";
import CampaignFinancials from "modules/Campaigns/sub-modules/campaign-financials";
import "./App.css";
import "./modules/Core/styles/ui-kit.css";
import Common from "modules/Common";

const { store, rootRender, routes } = init([
  Auth,
  Posts,
  CampaignMaintainers,
  CampaignMaintainerApplications,
  ProspectiveCampaigns,
  Campaigns,
  Dialog,
  Comments,
  Actions,
  Objectives,
  Partners,
  Invitations,
  Payments,
  Budgets,
  BudgetItems,
  BudgetTransactions,
  CampaignPartnershipInvitations,
  CampaignPartnerships,
  TeamMembers,
  PeerPartnerFinancials,
  BusinessPartnerships,
  P4P,
  P4PWork,
  Values,
  P4PTeam,
  Tracking,
  Judgements,
  CampaignFinancials,
  Common,
]);

const Root = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {routes}
          <Route path={"/"} element={<LandingPage />} />
          <Route path={"/privacy"} element={<PrivacyPolicy />} />
        </Routes>
        {rootRender()}
      </BrowserRouter>
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <Root />
    </Provider>
  );
}

export default App;
