import useConnectedDispatch from "modules/Auth/hooks/useConnectedDispatch";
import Container from "modules/Core/components/ui-kit/Container";
import { Flag, HollowHeart } from "modules/Core/components/ui-kit/indicators";
import SharePostAction from "./SharePostAction";
import Action from "./Action";
import heartPost from "modules/Posts/state/cells/heartPost";
import flagPost from "modules/Posts/state/cells/flagPost";
import CommentAction from "./CommentAction";
import EditPostAction from "./EditPostAction";
import DeleteAction from "./DeleteAction";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import styles from "./styles.module.scss";

const PostActions = ({ postId, post , comments=true}) => {
    const dispatch = useConnectedDispatch();
    return (
        <>
            <Container className={styles.container} flex alignCenter>
                <Container flex flexStart>
                    <SharePostAction postId={postId} />
                    <CommentAction
                        className={styles.comments}
                        commentsCount={post?.commentsCount}
                        postId={postId}
                        campaignId={post?.campaign}
                        shouldRender={comments}
                    />
                    <EditAction 
                        postId={postId} 
                        campaignId={post?.campaign} 
                        shouldRender={Boolean(post?.isAuthor)} 
                    />
                </Container>
                <Container flex alignCenter spaceAround>
                    <DeleteAction 
                        postId={postId} 
                        campaignId={post?.campaign} 
                    />
                    <Action
                        Indicator={HollowHeart}
                        isActive={post?.isHearted}
                        count={post?.heartCount}
                        action={() => dispatch(heartPost.action({ id: postId }))}
                    />
                    <Action
                        Indicator={Flag}
                        isActive={post?.isFlagged}
                        count={post?.flagCount}
                        action={() => dispatch(flagPost.action({ id: postId }))}
                    />
                </Container>
            </Container>
        </>
    )
};
const EditAction = withShouldRender(EditPostAction)

export default PostActions;