import { useCallback } from "react";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import useOnError from "modules/Core/sub-modules/Dialog/hooks/useOnError";
import LoadingScreen from "modules/Core/components/ui-kit/Screen/LoadingScreen";
import { createEntity } from "../../../state/cells";


export default strappedConnected(
    LoadingScreen,
    {},
    { onLoad: (campaignId, callback) => createEntity.action({ campaignId, callback }) },
    ({ campaignId, onLoad, }) => {
        const onError = useOnError();
        const callback = useCallback((result) => {
            const url = result?.registerCampaignFinancialsAccount?.url;
            if(!url) return onError("Failed to create partner financials account");
            window.location.href = url;
        }, [])
        useOnLoad(() => onLoad(campaignId, callback), Boolean(campaignId), [campaignId])
    }
);