import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import Container from 'modules/Core/components/ui-kit/Container';
import Header from 'modules/Core/components/ui-kit/layout/Header';
import Title from 'modules/Core/components/ui-kit/Title';
import useSidebar from 'modules/Core/components/Sidebar/useSidebar';
import { SIDEBAR_LINKS } from './const';
import P4PEmblem from '../P4PEmblem';
import AuthOptions from './HeaderAuthOptions';
import styles from './styles.module.scss';
import { isString } from 'lodash';

const P4PHeader = ({ children, className, title, ...props }) => {
    const { open, drawer, } = useSidebar({
        links: SIDEBAR_LINKS
    });
    const navigate = useNavigate();
    const onClick = useCallback(() => isString(title) ? navigate('/') : open(), [navigate, title, open])

    return (
        <Header className={cx(styles.header, className)} {...props}>
            <Container flex span alignCenter>
                <P4PEmblem
                    onClick={onClick}
                    containerClassName={styles.emblemContainer}
                    nature={"decoratedName"}
                />
                <Container onClick={open}>
                    <Title className={styles.title} text={title} />
                </Container>
            </Container>
            {children}
            <Container maxHeight>
                <AuthOptions />
            </Container>
            {drawer}
        </Header>
    )
}

export default P4PHeader;