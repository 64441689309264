import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { compact } from "lodash";
import Form from "modules/Core/components/form/Form";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import authorizeToken from "modules/Auth/state/cells/authorizeToken";
import { useQueryParams } from "modules/Core/hooks/useStripQueryParams";
import { compactObject } from "modules/Core/utils/obj";
import useTabIndex from "modules/Core/hooks/useTabIndex";
import CenteredCardScreen from "modules/Core/components/ui-kit/Screen/CenteredCardScreen";
import Container from "modules/Core/components/ui-kit/Container";
import NextButton from "modules/Core/components/ui-kit/Button/NextButton";
import schema, { PartnerFieldWidget } from "./schema";
import { Navigation } from "modules/Core/components/ui-kit/Screen/ScreenSidePanel/ScreenNavigation";
import { CAMPAIGNS } from "modules/Campaigns/consts";
import setFormField from "modules/Campaigns/state/cells/setFormField";
import styles from './styles.module.scss';
import Loader from "modules/Core/components/ui-kit/Loader";

const parseForm = (data) => {
    return compactObject({
        partnerId: !data?.partner?.disabled && data?.partner?.id,
        name: data.name,
        vision: data.visionStatement,
        photo: data.profileImage,
        coverPhoto: data.coverImage,
        creators: compact(data?.creators?.map(creator => creator?.id)),
        maintainers: compact(data?.maintainers?.map(maintainer => maintainer?.id)),
        details: {
            contactInformation: data.contactInformation,
            values: data?.values?.map(val => ({
                text: val?.text,
                P4PValue: val?.id
            })),
            aims: data?.aims?.map(aim => ({
                aim: aim?.name,
                description: aim?.description,
            })),
            background: data.background,
            mission: data.mission,
            commitment: data.commitment,
            location: data?.location?.map(loc => loc?.key),
            tags: data?.tags?.map(tag => tag?.key?.id),
            links: data?.links
        }
    })
}

const Component = ({ back, header, partnerValue, setPartner, subheader, activeTabIndex, loading, formSettings, cardHeader, onSubmit, onNext, cardHeaderProps, partnerField, launchAsPartner, setLaunchAsPartner, ...props }) => {
    return (
        <CenteredCardScreen
            header={header}
            cardHeader={cardHeader}
            subheading={subheader}
            cardHeaderProps={cardHeaderProps}
            className={styles.screen}
            cardClassName={styles.card}
            back={back}
        >
            <Container className={styles.container} fullHeight maxHeight fullWidth flex col spaceBetween>
                <Loader overlay loading={loading} />
                <Container>
                    <Form activeTabIndex={activeTabIndex} options={formSettings} {...props} />
                </Container>
                <Container flex alignCenter flexEnd mt1>
                    {onNext && <NextButton onClick={onNext} text={'Next'} />}
                    {!onNext && <NextButton text={'Create Campaign'} onClick={onSubmit} />}
                </Container>
            </Container>
            {partnerValue && <PartnerFieldWidget
                label={'Launch as partner?'}
                className={styles.partnerField}
                value={partnerValue}
                onChange={setPartner}
            />}
        </CenteredCardScreen>
    )
}


export default strappedConnected(
    Component,
    {
        partner: authorizeToken.selectors.getPartner,
        form: setFormField.selectors.getForm,
        loading: setFormField.selectors.isLoading
    },
    {
        setField: setFormField.action,
    },
    ({
        partner,
        onSubmit,
        parseSchema = schema => schema,
        setField,
        form,
        ...props
    }) => {
        const dispatch = useDispatch();
        const params = useQueryParams();
        useEffect(() => {
            if (!partner?.id) return
            // TODO: remove dispatch if not needed here please.
            dispatch(setField({
                key: 'partner', 
                value: {
                    ...partner,
                    disabled: params?.asPartner != 'true'

                }
            }));
        }, [partner?.id, params?.asPartner])

        const { next, activeTabIndex, back } = useTabIndex(0);

        const nextOptions = useMemo(() => ({
            NEXT: next,
        }), [next, ``]);

        const [subheading, cardHeader] = useMemo(() => ([
            schema[activeTabIndex].subheading,
            schema[activeTabIndex].title,
        ]), [activeTabIndex, nextOptions]);

        const cardHeaderProps = useMemo(() => ({
            children: (activeTabIndex > 0) && <Navigation dir='left' onClick={back} />,
            className: styles.cardHeader
        }), [back, activeTabIndex]);

        const formSettings = useMemo(() => ({
            moduleName: CAMPAIGNS,
            useUnifiedState: true,
        }), []);

        return ({
            tabs: schema,
            onSubmit: useCallback(() => onSubmit(parseForm(form)), [form]),
            asyncInitialState: true,
            activeTabIndex,
            subheading,
            cardHeader,
            onNext: activeTabIndex < schema.length - 1 ? next : false,
            cardHeaderProps,
            activeTabIndex,
            formSettings,
            setPartner: partner => dispatch(setField({ key: 'partner', value: partner })),
            partnerValue: form?.partner,
            ...props,
        })
    }
)
