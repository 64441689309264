import CampaignName from '../../CampaignName';
import { useMemo } from 'react';
import Widget from 'modules/Core/components/ui-kit/Widget';
import { SubcampaignsFeed } from '../../screens/ViewCampaign/ViewCampaign.panels';


const SubcampaignsWidget = ({ campaignId, campaignName = 'Women Rights', ...props }) => {
    const filters = useMemo(() => ({ campaignId }), [campaignId]);
    const title = useMemo(() => (
        <>
            <CampaignName id={campaignId} />
            {` Sub-campaigns`}
        </>
    ), [campaignName]);
    return null;
    return (
        <Widget title={title} defaultOpen={false} {...props} >
            <SubcampaignsFeed filters={filters} parentId={campaignId} />
        </Widget>
    )
}

export default SubcampaignsWidget;