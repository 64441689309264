import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";
import { OBJECTIVES } from "./consts";
import cells from "./state/cells";

const Objectives = new CoreModule({
    name: OBJECTIVES,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
});

export default Objectives;