
import feed from "../feed";


export const {
    fetchFeed,
    setFilters,
    fetchEntity
} = feed.cells;


export default {
    fetchFeed,
    setFilters,
    fetchEntity
}