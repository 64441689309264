import { useMemo } from "react";
import { useSelector } from "react-redux";
import c from 'classnames'
import Text from "modules/Core/components/ui-kit/Text";
import {fetchEntity} from "modules/Campaigns/state/cells";
import styles from "./styles.module.scss";

const CampaignName = ({ id, adapter, ...props }) => {
    const name = useSelector(fetchEntity?.selectField(id, "name"));
    const jsx = useMemo(() => adapter?.(name) ?? name, [name, adapter]);
    return name && <CampaignName.Component name={jsx} {...props} />
}

CampaignName.Component = ({ Wrapper = Text, name, className }) => (
    <Wrapper className={c(styles.name, className)}>
        {name}
    </Wrapper>
)


export default CampaignName;
