
import Button from 'modules/Core/components/ui-kit/Button';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import withShouldRender from 'modules/Core/higher-order-components/withShouldRender';
import { CAMPAIGN_ROLES } from 'modules/Campaigns/consts';
import { fetchEntity } from 'modules/Campaigns/state/cells';

const MaintainerApplicationButton = strappedConnected(
    withShouldRender(Button),
    { roles: (state, { campaignId }) => fetchEntity.selectNestedField(campaignId, 'membership.roles')(state) },
    {},
    ({ roles, campaignId }) => ({
        shouldRender: Boolean(!roles?.includes(CAMPAIGN_ROLES.MAINTAINER)),
        to: `/campaigns/${campaignId}/maintainer-gateway/apply`,
        widget: true,
        text: 'Maintainer Application'
    })
)

export default MaintainerApplicationButton