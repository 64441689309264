import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import { fetchEntity } from 'modules/Campaigns/state/cells';
import { useOnLoad } from 'modules/Core/hooks/useOnLoad';
import { withLoader } from 'modules/Core/higher-order-components/withLoader';
import CampaignDetailsComponent from './component';

export const selectors = {
    campaign: (state, { id }) => fetchEntity.selector(id)(state),
};

export const actions = {
    fetchEntity: fetchEntity.action,
};

export const useLogic = ({ campaign, fetchEntity, id, shouldLoad=true }) => {
    useOnLoad(() => {
        fetchEntity({ id })
    }, !campaign?.details && id && shouldLoad, [id]);
    return {
        ...campaign,
        name: campaign?.name,
        description: campaign?.description,
        aims: campaign?.aims,
        parent: campaign?.parent,

    }
};

export const withFetchCampaignOnLoad = Component => strappedConnected(
    withLoader(Component, fetchEntity.selectors.getIsLoading),
    selectors,
    actions,
    useLogic,
);

export default strappedConnected(
    withLoader(CampaignDetailsComponent, fetchEntity.selectors.getIsLoading),
    selectors,
    actions,
    useLogic,
)