

import { fetchEntity } from "modules/Partners/state/cells";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import FundraisingScreen from "modules/Payments/components/FundraisingScreen"
import { useMemo } from "react";
import { useFormatPartnerInfoFeatures } from "../../PartnerInfoFeatures";

export default strappedConnected(
    FundraisingScreen,
    {
        partner: (state, { id }) => fetchEntity.selector(id)(state)
    },
    {
        fetchEntity: (id) => fetchEntity.action({ id })
    },
    ({ fetchEntity, partner, id, }) => {
        useOnLoad(
            () => fetchEntity(id),
            !Boolean(partner?.profile),
            [id]
        )
        return ({
            title: 'Support ' + partner?.name,
            header: "Donate to " + partner?.name,
            back: useMemo(() => ({
                to: `/partner/profile/${id}`,
                text: 'View Profile'
            }), [id]),
            visionStatement: partner?.info?.bio,
            feedParams: useMemo(() => ({
                partnerId: id,
            })),
            details: useFormatPartnerInfoFeatures({
                partnerInfo: partner?.info,
                id
            })
        })
    }
);