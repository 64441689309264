import { useMemo } from 'react';
import feed from '../../state/feed';
import strapped from 'modules/Core/higher-order-components/strapped';
import MaintainerApplicationsFeedItem from '../MaintainerApplicationsFeedItem';
import { CAMPAIGN_MAINTAINER_APPLICATION_STATUSES } from '../../consts';

export default strapped(
    feed.FeedComponent,
    ({ campaignId, status = CAMPAIGN_MAINTAINER_APPLICATION_STATUSES.PENDING }) => ({
        filters: useMemo(() => ({ campaignId, status }), [campaignId, status]),
        ItemComponent: MaintainerApplicationsFeedItem
    })
);