import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { COMMON } from "../consts";
import { TAGS_QUERY } from "modules/Core/components/form/schema/tags";


const operations = {
  fetchCategoryTags: TAGS_QUERY,
};

export default new MiddlewareModule({
  name: COMMON,
  operations,
  operationsConfig: {
    fetchEntity: {
      paramsParser: (id) => ({ id }), // to do: normalise / fix or something somehow, somewhere..
    },
  },
});
