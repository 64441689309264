import { fetchEntity } from 'modules/Partners/state/cells';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import withShouldRender from 'modules/Core/higher-order-components/withShouldRender';
import Button from 'modules/Core/components/ui-kit/Button';


export default strappedConnected(
    withShouldRender(Button),
    { 
        financialsAccount: (state, { partnerId }) => fetchEntity.selectField(partnerId, 'financialsAccount')(state)
    },
    null,
    ({ associated, partnerId, financialsAccount }) => ({
        text: "Activate Donations",
        to: `/partner/${partnerId}/register-financials`,
        shouldRender: Boolean(associated && !financialsAccount),
        widget: true
    })
);