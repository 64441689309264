import CoreModule from "modules/Core/core-modules/CoreModule";
import feed from "./state/feed";    
import cells from "./state/cells";
import routes from "./routes";
import { CAMPAIGN_PARTNERSHIPS } from "./consts";

const CampaignPartnerships = new CoreModule({
    name: CAMPAIGN_PARTNERSHIPS,
    initialState: {
        ...feed.getInitialFeedState(),
    },
    cells,
    routes
});

export default CampaignPartnerships;