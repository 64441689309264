import React from 'react';
import Container from 'modules/Core/components/ui-kit/Container';
import ObjectivesActions from 'modules/Campaigns/components/CampaignCommand/ObjectiveActions';
import ProspectiveObjectivesFeed from 'modules/Campaigns/components/CampaignCommand/ProspectiveObjectivesFeed';

const ObjectivesTab = ({ id }) => (
    <Container flex spaceBetween col maxHeight>
        <ProspectiveObjectivesFeed campaignId={id} />
        <Container flex center maxWidth>
            <ObjectivesActions campaignId={id} />
        </Container>
    </Container>
);

export default ObjectivesTab