import MiddlewareModule from "modules/Core/core-modules/MiddlewareModule";
import { FEED_INFO_FRAGMENT } from "modules/Core/middleware/const";
import { TEAM_MEMBERS } from "../consts";

const fetchFeed = `
	query teamMembers($partnerId: String, $feedParams: FeedParams) {
		feed: teamMembers(partnerId: $partnerId, feedParams: $feedParams) {
			entities {
				id
				bio
				roles
				userInformation {
					firstName
					lastName
					photo
				}
			}
			${FEED_INFO_FRAGMENT}
		}
	}
`;

const registerTeamMember = `
	mutation registerTeamMember($input: PartnerTeamMemberInput) {
		registerPartnerTeamMember(input: $input) {
		bio
		}
	}
`

export default new MiddlewareModule({
	name: TEAM_MEMBERS,
	operations: {
		fetchFeed,
		registerTeamMember
	},
})
