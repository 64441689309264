import feed from "../feed";
import votePolicy from "./votePolicy";

export const { createEntity, fetchFeed, fetchEntity } = feed.cells;

export default {
  createEntity,
  fetchFeed,
  fetchEntity,
  votePolicy,
};
