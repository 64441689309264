import TextAreaModal from "modules/Core/components/ui-kit/TextAreaModal";
import { RemoveCircle } from "modules/Core/components/ui-kit/indicators";
import Action from "../Action";

const DeleteAction = ({ isOpen, open, close, onSubmit, entityName, id }) => (
    <>
        <TextAreaModal
            isOpen={isOpen}
            close={close}
            title={`Please add justification for removing this ${entityName} & confirm ${entityName} removal.`}
            onSubmit={(justification) => onSubmit({ id, justification, callback: (isSuccess) => isSuccess && close() })}
        />
        <Action
            Indicator={RemoveCircle}
            isActive={isOpen}
            action={open}
        />
    </>
);


export default DeleteAction;