export const PAYMENTS = "payments";

export const NOTARIES = {
    STRIPE: "STRIPE"
};

export const TRANSACTION_KEYS = {
    PLATFORM_DONATION: 'PLATFORM_DONATION',
    CAMPAIGN_DONATION: 'CAMPAIGN_DONATION',
    PARTNER_DONATION: 'PARTNER_DONATION',
}