import withParams from "modules/Core/higher-order-components/withParams";
import PartnerOnboarding from "../components/screens/PartnerOnboarding";
import PartnerProfile from "../components/screens/PartnerProfile";
import PartnersFeedScreen from "../components/screens/PartnersFeedScreen";
import PartnerFundraising from "../components/screens/PartnerFundraising";
import PartnerDonation from "../components/screens/PartnerDonation";

export default {
    'partner/profile/:id': withParams(PartnerProfile),
    'partner-onboarding/:kind': withParams(PartnerOnboarding),
    'partners': PartnersFeedScreen,
    'partner/:id/fundraising': withParams(PartnerFundraising),
    'partner/:id/fundraising/donate': withParams(PartnerDonation),
};