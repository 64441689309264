import query from "./middleware";
import IdentityCard from "modules/Core/components/ui-kit/IdentityCard";
import styles from "./styles.module.scss";

export default {
  fields: {
    campaign: {
      label: "Send to:",
      type: "async-select",
      placeholder: "Select Campaign",
      query,
      variables: { feedParams: { segment: [0, 7] } },
      MenuProps: {
        className: styles.menu,
      },
      parseResult: (r) =>
        r?.campaigns?.entities?.map((campaign) => ({
          readable: (
            <IdentityCard
              name={campaign?.name}
              image={campaign?.photo}
              className={styles.selectItem}
            >
              {campaign?.vision}
            </IdentityCard>
          ),
          key: campaign.id,
        })),
      required: true,
    },
    message: {
      label: "Custom Message:",
      type: "textarea",
      rows: 12,
    },
  },
};
