import ExternalScreen from "modules/Core/components/ui-kit/Screen/ExternalScreen"
import Title from "modules/Core/components/ui-kit/Title"
import Container from "modules/Core/components/ui-kit/Container"
import P4PWorklogFeed from "modules/P4P/sub-modules/Work/components/WorklogFeed"
import styles from "./styles.module.scss"

const OurWorkScreen = ({ }) => (
    <ExternalScreen className={styles.container}>
        <Container className={styles.content}>
            <Title>P4P's Work</Title>
            <P4PWorklogFeed />
        </Container>
    </ExternalScreen>
);

export default OurWorkScreen