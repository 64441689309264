import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import Container from 'modules/Core/components/ui-kit/Container';
import Modal from 'modules/Core/components/ui-kit/Modal';
import authorizeToken from 'modules/Auth/state/cells/authorizeToken';
import AuthForm from 'modules/Auth/components/forms/AuthForm';
import Title from 'modules/Core/components/ui-kit/Title';
import styles from './styles.module.scss';


const AuthenticationModal = () => {
    const isLoading = useSelector(authorizeToken.selectors.getIsLoading);
    const isAuthorized = useSelector(authorizeToken.selectors.isAuthorized);
    const isOpen = useMemo(() => Boolean(!isAuthorized), [isAuthorized]);

    return (
        <Modal
            isOpen={isOpen}
            bodyClassName={styles.body}
            className={styles.modal}
        >
            <Container className={styles.container} flex col relative>
                <Container >
                    <Title>Login</Title>
                </Container>
                <AuthForm redirect={false} />
            </Container>
        </Modal>
    )
};

export default AuthenticationModal