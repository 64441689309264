import withParams from 'modules/Core/higher-order-components/withParams';
import Container from 'modules/Core/components/ui-kit/Container';
import MaintainerApplicationsFeed from '../../MaintainerApplicationsFeed';
import Screen from 'modules/Core/components/ui-kit/Screen';
import styles from "./styles.module.scss";

const MaintainerApplicationsFeedScreen = ({campaignId, ...props }) => {
    return (
        <Screen header="Maintainer Applications">
            <Container className={styles.container} >
                <MaintainerApplicationsFeed campaignId={campaignId} {...props} />
            </Container>
        </Screen>
    )
}

export default withParams(MaintainerApplicationsFeedScreen);