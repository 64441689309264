import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { createEntity as create, fetchEntity } from "modules/Campaigns/state/cells"
import CampaignForm from '../../forms/CampaignForm'
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected'
import useOnSuccess from 'modules/Core/sub-modules/Dialog/hooks/useOnSuccess'
import Screen from 'modules/Core/components/ui-kit/Screen'
import Card from 'modules/Core/components/ui-kit/Card'
import styles from './styles.module.scss'

const useCreateCampaign = ({ createCampaign, fetchCampaign, title = "Create Campaign", additionalInput, redirectRoute, successMessage = "Successfully submitted campaign for review", ...params }) => {
    const navigate = useNavigate();
    const onSuccess = useOnSuccess();
    const onSubmit = useCallback((entity) => createCampaign({
        ...entity,
        ...additionalInput,
        callback: ({ id }) => {
            if (!id) return null;
            onSuccess(successMessage)
            navigate(redirectRoute ?? `/campaigns/${id}`)
            fetchCampaign(id);
        }
    }), [createCampaign, fetchCampaign, navigate, additionalInput]);

    return {
        onSubmit,
        title,
        formContainerClassName: styles.formContainer
    }
}

const Component = ({ header, ...props }) => {
    return (
        <Screen className={styles.screen} header={header} >
            <Card className={styles.card}>
                <CampaignForm className={styles.form} fullScreen={false} {...props} />
            </Card>
        </Screen>
    )
}

export default strappedConnected(
    CampaignForm,
    null,
    {
        createCampaign: (campaign, callback) => create.action({ callback, ...campaign }),
        fetchCampaign: (id) => fetchEntity.action({ id })
    },
    useCreateCampaign
)