import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import Button from 'modules/Core/components/ui-kit/Button';
import Container from 'modules/Core/components/ui-kit/Container';
import { createEntity, fetchFeed } from "modules/Posts/state/cells";
import { fetchEntity } from 'modules/Campaigns/state/cells';
import { useIsOpen } from 'modules/Core/hooks/useIsOpen';
import PostForm from '../forms/PostForm';
import Title from 'modules/Core/components/ui-kit/Title';
import styles from './styles.module.scss';

const CreatePost = ({ campaignId, className, additionalParams }) => {
    const { isOpen, close, open } = useIsOpen();
    const dispatch = useDispatch();
    const isMemberSelector = useMemo(() => fetchEntity.selectField(campaignId, 'isMember'), [campaignId]);


    const handleSubmit = (data) => dispatch(createEntity.action({
        campaign: campaignId,
        ...additionalParams,
        ...data,
        callback: () => {
            close();
            dispatch(fetchFeed.action({ campaignId }))
        }
    }));

    return (
        <Container className={cx(styles.container, className, { [styles.open]: isOpen })} flex column bottom flexEnd>
            {!isOpen && <Container>
                <Button
                    restrictionSelector={isMemberSelector}
                    onRestrictionMessage="You must be a member to create a post"
                    onClick={open}
                >Create Post</Button>
            </Container>}
            <PostForm
                header={
                    <Container className={styles.formHeader} >
                        <Title Element='h4'>
                            Create Post
                        </Title>
                        <span className={cx('chevron-down-container', styles.chevron)} onClick={close} />
                    </Container>
                }
                className={styles.formContainer}
                handleSubmit={handleSubmit}
            />
        </Container>
    )
}

export default CreatePost;