import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Form from "modules/Core/components/form/Form";
import schema from "./schema";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { createEntity } from "../../state/cells";
import useOnSuccess from "modules/Core/sub-modules/Dialog/hooks/useOnSuccess";

export default strappedConnected(
  Form,
  {},
  {
    onSubmit: ({ ...info }, callback) =>
      createEntity.action({
        input: {
          name: info.name,
          background: info.description,
          openingTime: info.openingTime.$d,
          closingTime: info.closingTime.$d,
          policies: info.policies,
        },
        callback,
      }),
  },
  ({ onSubmit }) => {
    const onSuccess = useOnSuccess();
    const navigate = useNavigate();
    const callback = useCallback(
      (result) => {
        Boolean(result?.id) &&
          onSuccess("You've successsfully create the judgement policy!");
          return navigate(`/judgement-cases/${result.id}`);
      },
      [onSuccess]
    );
    return {
      onSubmit: (input) => onSubmit({ ...input }, (data) => callback(data)),
      schema,
      success: schema.success,
    };
  }
);
