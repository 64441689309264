import cx from "classnames"
import Container from "modules/Core/components/ui-kit/Container";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { fetchEntity } from "../../state/cells";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import Text from "modules/Core/components/ui-kit/Text";
import ConditionalContainer from "modules/Core/components/ui-kit/ConditionalContainer";
import Title from "modules/Core/components/ui-kit/Title";
import styles from "./styles.module.scss";
import Attachment from "modules/Core/components/ui-kit/Attachment";


const BudgetItemDetails = ({ id, name, className, amount, description, attachments, ...props }) => {
    return (
        <Container className={cx(className, styles.container)} {...props}>
            <Container>
                <Title>
                    Id:
                </Title>
                <Text>
                    {id}
                </Text>
            </Container>
            <Container>
                <Title>
                    Name:
                </Title>
                <Text>
                    {name}
                </Text>
            </Container>
            <Container>
                <Title>
                    Price:
                </Title>
                <Text>
                    {amount}
                </Text>
            </Container>
            <Container>
                <Title>
                    Description:
                </Title>
                <Text>
                    {description ?? "No Item Description"}
                </Text>
            </Container>
            <ConditionalContainer shouldRender={Boolean(attachments?.length)} col>
                <Title>{Boolean(attachments?.length) && "Attachments:"}</Title>
                <Container flex>
                    {attachments?.map(attachment => (
                        <Attachment name={attachment.name} url={attachment.attachment} />
                    ))}
                </Container>
            </ConditionalContainer>
        </Container>
    )
};

export default strappedConnected(
    BudgetItemDetails,
    { entity: (state, { id }) => fetchEntity.selector(id)(state) },
    {
        onLoad: (id) => fetchEntity.action({ id })
    },
    ({ id, onLoad, entity = {} }) => {
        useOnLoad(() => onLoad(id), Boolean(id), [id]);
        return {
            amount: entity.amount,
            name: entity.name,
            description: entity.description,
            attachments: entity.attachments
        }
    }
);