import useIsCampaignMember from 'modules/Campaigns/hooks/useIsCampaignMember';
import RedirectButton from 'modules/Core/components/ui-kit/RedirectButton';
import withShouldRender from 'modules/Core/higher-order-components/withShouldRender';
import useTurnaryMemo from 'modules/Core/hooks/useTurnaryMemo';

const CreateCampaignButton = ({ parentId, ...props }) => {
    const isMember = useIsCampaignMember(parentId);
    const [to, text, shouldRender = true] = useTurnaryMemo({
        condition: parentId,
        onTrue: [`/campaigns/${parentId}/create-subcampaign`, "Create Subcampaign", Boolean(isMember)],
        onElse: ["/campaigns/create", "Create Campaign"],
        deps: [isMember, parentId]
    });
    return (
        <CreateCampaignButton.Component 
            to={to} 
            text={text} 
            shouldRender={shouldRender}  
            {...props} 
        />
    )
}

CreateCampaignButton.Component = withShouldRender(({ to, text, className, ...props }) => (
    <RedirectButton
        className={className}
        to={to}
        text={text}
        {...props}
    />
));

export default CreateCampaignButton;